import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppLayout, HelpPanel } from '@cloudscape-design/components';
import TickerTabs from '../analyst/tickers/TickerTabs';
import FuturesTabs from '../analyst/futures/FuturesTabs';
import IndicesTabs from '../analyst/world_indices/IndicesTabs';
import BondsTabs from '../analyst/bonds/BondsTabs';
import OptionsTabs from '../analyst/options/OptionsTabs';
import FundsTabs from '../analyst/funds/FundsTabs';

import TickerDetails from './tickers/TickerDetails'; 
import AnalystPortal from './overview/AnalystPortal'; 

import AnalystLeftMenu from '../analyst/menu/AnalystLeftMenu';
import AnalystChat from './chat/AnalystChat';
import ETFTabs from './etf/ETFTabs';
import ForexTabs from './forex/ForexTabs';
import CryptoTabs from './crypto/CryptoTabs';
import SectorList from './sectors/SectorsList';
import SectorDetail from './sectors/SectorDetail';
import IndustryDetail from './industry/IndustryDetail';
import EquityDetail from './tickers/EquityDetail';
import ChatWidget from '../chat_bot/ChatWidget';





function AnalystRouter() {
    // useMatch can be used to derive path information if needed
    //let match = useMatch('/clients/*');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <AppLayout
        navigation={<AnalystLeftMenu isOpen={isMenuOpen} toggleDrawer={() => setIsMenuOpen(false)} />}
        tools={<HelpPanel header={<h2>Ask Bedrock</h2>}>
        <ChatWidget app_name="pm_bot" entity_type="ClientList" Panel="Tools"
       /></HelpPanel>}

        content={
        <div>
            <h2>Markets</h2>
            <Routes>
                <Route path="/" element={<AnalystPortal />} />
                <Route path="tickers" element={<TickerTabs />} />
                <Route path="futures" element={<FuturesTabs />} />
                <Route path="world_indices" element={<IndicesTabs />} />
                <Route path="bonds" element={<BondsTabs />} />
                <Route path="options" element={<OptionsTabs />} />
                <Route path="funds" element={<FundsTabs />} />
                <Route path="etfs" element={<ETFTabs />} />
                <Route path="forex" element={<ForexTabs />} />
                <Route path="crypto" element={<CryptoTabs />} />
                <Route path="sectors" element={<SectorList />} />
                <Route path="sector/:sector" element={<SectorDetail />} />
                <Route path="industry/:industry" element={<IndustryDetail />} />
                <Route path="tickers/details/:ticker" element={<TickerDetails />} />
                <Route path="tickers/equity/:ticker" element={<EquityDetail />} />

                <Route path="/chat" element={<AnalystChat/>} />


            </Routes>
        </div>
        }
        />

    );
}

export default AnalystRouter;
