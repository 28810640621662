import React, { useState } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';

function CreateToolSpecModal({ visible, onClose, onToolAdded, appId }) {
    const [toolData, setToolData] = useState({
        name: '',
        description: '',
        inputSchema: ''
    });
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setToolData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newTool = {
                toolSpec: {
                    name: toolData.name,
                    description: toolData.description,
                    inputSchema: JSON.parse(toolData.inputSchema)
                }
            };
            await axiosInstance.post(`/genai_apps/${appId}/tools`, newTool);
            onToolAdded(newTool); 
            setFlashMessages([{ type: 'success', header: 'Success', content: 'GenAI App deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);

            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Add Tool"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Name">
                    <Input value={toolData.name} onChange={e => handleInputChange(e, 'name')} />
                </FormField>
                <FormField label="Description">
                    <Input value={toolData.description} onChange={e => handleInputChange(e, 'description')} />
                </FormField>
                <FormField label="Input Schema (JSON)">
                    <Input value={toolData.inputSchema} onChange={e => handleInputChange(e, 'inputSchema')} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default CreateToolSpecModal;
