import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box, SpaceBetween, Container, Header, Table, TextFilter, Pagination, ColumnLayout,
    BarChart, Link, Button, Flashbar, ExpandableSection, Tabs, PieChart, Input as CloudscapeInput, Badge, Popover, Icon
} from '@cloudscape-design/components';
import axiosInstance from '../services/axiosConfig';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useBreadcrumbs } from './breadcrumb/BreadcrumbProvider';
import ChatWidget from './chat_widget/ChatWidget';
import { useNavigate } from 'react-router-dom';


function InvestmentDetail() {
    const { investmentId } = useParams();
    const [investment, setInvestment] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const { setBreadcrumbs } = useBreadcrumbs();
    const [currentHoldings, setCurrentHoldings] = useState([]);
    const [tickers, setTickers] = useState([]);
    const [selectedTickers, setSelectedTickers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortingColumn, setSortingColumn] = useState({ sortingField: 'ticker', isDescending: false });
    const navigate = useNavigate();


    const numericComparator = (a, b) => {
        const numA = Number(a);
        const numB = Number(b);
        return numA - numB;
    };

    const handleSelectChange = async ({ detail }, field) => {
        try {
            const updatedValue = detail.selectedOption.value;
            console.log(`Updating ${field} to ${updatedValue}`);

            const updateData = { [field]: updatedValue };
            console.log('Payload sent to API:', updateData);

            const response = await axiosInstance.put(`/investments/${investmentId}`, updateData);

            console.log('API Response:', response.data);
            setInvestment(prevData => ({
                ...prevData,
                [field]: updatedValue
            }));

            setFlashMessages([{ type: 'success', header: 'Success', content: 'Optimization technique updated successfully.', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } catch (error) {
            let errorMessage = 'Failed to update optimization technique';
            if (error.response?.data?.detail) {
                if (Array.isArray(error.response.data.detail)) {
                    errorMessage = error.response.data.detail.map(err => err.msg).join(', ');
                } else {
                    errorMessage = error.response.data.detail;
                }
            }
            console.error('Error updating optimization technique:', error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        }
    };

    const ValueWithLabel = ({ label, children }) => (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const fetchData = async () => {
        try {
            const investmentRes = await axiosInstance.get(`/investments/${investmentId}`);
            const investmentData = investmentRes.data;
            setInvestment(investmentData);

            setTransactions(investmentData.transactions.map(tx => ({
                ...tx,
                amount: Number(tx.amount),
                price_per_unit: Number(tx.price_per_unit)
            })));

            setCurrentHoldings(investmentData.current_holdings.map(cv => ({
                ...cv,
                quantity: Number(cv.quantity),
                current_price: Number(cv.current_price),
                market_value: Number(cv.market_value)
            })));

            setLoading(false);
            const clientId = localStorage.getItem('clientId');
            setBreadcrumbs([{ text: "Client", href: `/clients/edit/${clientId}` }, { text: `Investment` }]);

            // Set selected tickers from investment data
            setSelectedTickers(investmentData.tickers.map(ticker => ({ ticker })));

            // Fetch tickers based on investment type
            const fetchTickers = async () => {
                try {
                    if (investmentData.investment_type === 'Major Purchase') {
                        const response = await axiosInstance.get('/popular/tickers/Investment');
                        setTickers(response.data);
                    } else {
                        const response = await axiosInstance.get(`/popular/tickers/${investmentData.investment_type}`);
                        setTickers(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching tickers:', error);
                }
            };

            await fetchTickers();

        } catch (err) {
            const errorMessage = err.response?.data?.detail?.message || 'Failed to fetch data';
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [investmentId, setBreadcrumbs]);

    const transactionCollection = useCollection(transactions || [], {
        filtering: {
            empty: <Box textAlign="center">No transactions found.</Box>,
            noMatch: <Box textAlign="center">No matching transactions found.</Box>
        },
        pagination: { pageSize: 10 },
        sorting: {
            sortableFields: ['ticker', 'type', 'amount', 'price_per_unit'],
            comparators: {
                amount: numericComparator,
                price_per_unit: numericComparator
            }
        }
    });

    const transformBuySeries = (transactions, transaction_type) => {
        const filteredTransactions = transactions.filter(transaction => transaction.transaction_type.toLowerCase() === transaction_type);

        const groupedData = filteredTransactions.reduce((acc, { ticker, amount, date_created }) => {
            const month = new Date(date_created).toISOString().slice(0, 7);
            if (!acc[ticker]) {
                acc[ticker] = {};
            }
            if (!acc[ticker][month]) {
                acc[ticker][month] = 0;
            }
            acc[ticker][month] += amount;
            return acc;
        }, {});

        const series = Object.entries(groupedData).map(([ticker, monthsData]) => {
            const sortedMonths = Object.entries(monthsData).sort((a, b) => a[0].localeCompare(b[0]));
            return {
                title: ticker,
                type: 'bar',
                data: sortedMonths.map(([x, y]) => ({ x, y }))
            };
        });

        return series;
    };

    const buySeries = transformBuySeries(transactions, "buy");
    const xBuyDomain = buySeries.flatMap(s => s.data.map(d => d.x)).filter((value, index, self) => self.indexOf(value) === index).sort();
    const sellSeries = transformBuySeries(transactions, "sell");
    const xSellDomain = sellSeries.flatMap(s => s.data.map(d => d.x)).filter((value, index, self) => self.indexOf(value) === index).sort();

    const handleRebalance = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(`/investments/${investmentId}/rebalance`);
            console.log('Rebalance successful', response.data);
            setFlashMessages([{ type: 'success', header: 'Rebalance Successful', content: 'Rebalance was successful.', onDismiss: () => setFlashMessages([]), dismissible: true }]);
            await fetchData();

        } catch (error) {
            const errorMessage = error.response?.data?.detail?.message || 'Rebalance failed';
            console.error('Rebalance failed', error);
            setFlashMessages([{ type: 'error', header: 'Rebalance Failed', content: errorMessage, onDismiss: () => setFlashMessages([]), dismissible: true }]);

        }
        setLoading(false);
    };

    const handlePostMonthly = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(`/investments/${investmentId}/post_monthly`);
            console.log('Post Monthly successful', response.data);
            setFlashMessages([{ type: 'success', header: 'Post Monthly  Successful', content: 'Post Monthly  was successful.', onDismiss: () => setFlashMessages([]), dismissible: true }]);
            await fetchData();
        } catch (error) {
            const errorMessage = error.response?.data?.detail?.message || 'Post Monthly failed';
            console.error('Post Monthly  failed', error);
            setFlashMessages([{ type: 'error', header: 'Post Monthly  Failed', content: errorMessage, onDismiss: () => setFlashMessages([]), dismissible: true }]);
        }
        setLoading(false);
    };

    const handleTickerSelection = ({ detail }) => {
        setSelectedTickers(detail.selectedItems);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.detail.value);
    };

    const handleSortingChange = ({ detail: { sortingColumn, isDescending } }) => {
        setSortingColumn({ sortingField: sortingColumn.sortingField, isDescending });
    };

    const handlePaginationChange = ({ detail }) => {
        setCurrentPage(detail.currentPageIndex);
    };

    const filteredTickers = tickers.filter(ticker =>
        Object.values(ticker).some(value =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const sortedTickers = filteredTickers.sort((a, b) => {
        const aValue = a[sortingColumn.sortingField];
        const bValue = b[sortingColumn.sortingField];
        if (aValue < bValue) return sortingColumn.isDescending ? 1 : -1;
        if (aValue > bValue) return sortingColumn.isDescending ? -1 : 1;
        return 0;
    });

    const paginatedTickers = sortedTickers.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handleSaveTickers = async () => {
        setLoading(true);
        try {
            const dataToSubmit = { tickers: selectedTickers.map(item => item.ticker) };
            await axiosInstance.put(`/investments/${investmentId}/tickers`, dataToSubmit);
            setFlashMessages([{
                header: "Success",
                content: "Tickers updated successfully!",
                type: "success",
                dismissible: true,
                onDismiss: () => setFlashMessages([])
            }]);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message || 'Failed to update tickers.';
            setFlashMessages([{
                header: "Error",
                content: `Failed to update tickers: ${errorMessage}`,
                type: "error",
                dismissible: true,
                onDismiss: () => setFlashMessages([])
            }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container header={<Header variant="h3"
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={handlePostMonthly}>Post Monthly</Button>
                    <Button onClick={handleRebalance}>Rebalance</Button>
                </SpaceBetween>
            }>
            Investment Details</Header>}>
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}

            {loading ? (
                <div>Loading...</div>
            ) : (


                <SpaceBetween size="xxl" direction="vertical">
                    {investment && (
                        <Box>
                            <ColumnLayout columns={3} variant="text-grid">
                                <ValueWithLabel label="Type">{investment.investment_type}</ValueWithLabel>
                                <ValueWithLabel label="Value">{formatCurrency(investment.investment_value)}</ValueWithLabel>
                                <ValueWithLabel label="Expected Annual Return">{investment.expected_annual_return}%</ValueWithLabel>
                                <ValueWithLabel label="Annual volatility">{investment.annual_volatility}%</ValueWithLabel>
                                <ValueWithLabel label="Sharpe Ratio">{investment.sharpe_ratio}</ValueWithLabel>
                                <ValueWithLabel label="Cash">{formatCurrency(investment.cash)}</ValueWithLabel>

                            </ColumnLayout>
                        </Box>
                    )}
                                <Box margin={{ top: 'l' }}>
                                <ExpandableSection headerText='Ask'  variant="container"  headerInfo={<Link iconName="gen-ai" variant="info"><Icon name="gen-ai" /></Link>}>
               <ChatWidget app_name="pm_portal" entity_type="investment"
                   context={JSON.stringify(investment)}
                   entity_id="Investment"
               />
               </ExpandableSection>
           </Box>


                    <Tabs
                        tabs={[
                            {
                                label: 'Current Holdings',
                                id: 'current-holdings',
                                content: (
                                    <ColumnLayout columns={2} variant="text-grid">
                                            <PieChart
                                                data={currentHoldings.map(holding => ({
                                                    title: holding.ticker,
                                                    value: holding.market_value,
                                                    quantity: holding.quantity,
                                                    description: `${holding.ticker}: ${formatCurrency(holding.market_value)}`
                                                }))}
                                                detailPopoverContent={(datum, sum) => [
                                                    { key: "Market Value", value: formatCurrency(datum.value) },
                                                    { key: "Percentage", value: `${((datum.value / sum) * 100).toFixed(0)}%` }
                                                ]}
                                                size="large"
                                                height={400}
                                                legendPosition="right"
                                                hideFilter
                                                hideLegend />
                                            <Table
                                                columnDefinitions={[
                                                    { header: 'Ticker', cell: item => item.ticker },
                                                    { header: 'Quantity', cell: item => item.quantity },
                                                    { header: 'Current Price', cell: item => formatCurrency(item.current_price) },
                                                    { header: 'Market Value', cell: item => formatCurrency(item.market_value) }
                                                ]}
                                                items={currentHoldings}
                                                empty={<Box>No current values found.</Box>}
                                            />


                                    </ColumnLayout>


                                )
                            },
                            {
                                label: 'Transactions',
                                id: 'buy_transactions',
                                content: (
                                    <div>
                                        <Box>
                                            {buySeries.length > 0 && (
                                                <Box>
                                                    <h3>Buy Transactions</h3>
                                                    <BarChart series={buySeries} xDomain={xBuyDomain} size="small" stackedBars height={150} hideFilter fitHeigh={true} />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box>
                                            {sellSeries.length > 0 && (
                                                <Box>
                                                    <h3>Sell Transactions</h3>
                                                    <BarChart series={sellSeries} xDomain={xSellDomain} size="small" stackedBars height={150} hideFilter fitHeight={true} />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box><br></br>
                                            <br></br>
                                        </Box>
                                        <Box>
                                            <h3>Transactions</h3>
                                            <TextFilter {...transactionCollection.filterProps} placeholder="Search transactions" />
                                            <Pagination {...transactionCollection.paginationProps} />
                                            <Table
                                                {...transactionCollection.collectionProps}
                                                columnDefinitions={[
                                                    { header: 'Transaction ID', cell: item => item.transaction_id },
                                                    { header: 'Date', cell: item => new Date(item.date_created).toLocaleDateString() },
                                                    { header: 'Ticker', cell: item => item.ticker, sortingField: 'ticker' },
                                                    { header: 'Type', cell: item => item.transaction_type, sortingField: 'transaction_type' },
                                                    { header: 'Quantity', cell: item => item.amount, sortingField: 'amount' },
                                                    { header: 'Price per Unit', cell: item => formatCurrency(item.price_per_unit), sortingField: 'price_per_unit' }
                                                ]}
                                                items={transactionCollection.items}
                                                empty={<Box>No transactions found.</Box>}
                                            />
                                            <Pagination {...transactionCollection.paginationProps} />
                                        </Box>

                                    </div>

                                )
                            },
                            {
                                label: 'Select Tickers',
                                id: 'select_tickers',
                                content: (
                                    <>
                                        <SpaceBetween size="l" direction="vertical">
                                            <ColumnLayout columns={6} variant="text-grid">
                                                <strong>Selected Tickers</strong>
                                                {selectedTickers.map((ticker, index) => (
                                                    <Badge key={index} color="blue">{ticker.ticker}</Badge>
                                                ))}
                                            </ColumnLayout>
                                            <SpaceBetween size="l" direction="horizontal">
                                                <CloudscapeInput
                                                    value={searchQuery}
                                                    placeholder="Search tickers"
                                                    onChange={handleSearchChange}
                                                />
                                                <Pagination
                                                    currentPageIndex={currentPage}
                                                    onChange={handlePaginationChange}
                                                    pagesCount={Math.ceil(tickers.length / pageSize)}
                                                />
                                            </SpaceBetween>
                                            <Table
                                                columnDefinitions={[
                                                    { id: 'ticker', header: 'Ticker', cell: item => item.ticker, sortingField: 'ticker' },
                                                    {
                                                        id: 'company', header: 'Company', cell: item => (
                                                            <>
                                                                {item.company.substring(0, 30)}{item.company.length > 30 ? '...' : ''}
                                                                <Popover
                                                                    size="small"
                                                                    position="top"
                                                                    triggerType="custom"
                                                                    header="Company Details"
                                                                    content={
                                                                        <>
                                                                            <p>One Year Return: {item.one_yr_return}%</p>
                                                                            <p>Five Year Return: {item.five_yr_return}%</p>
                                                                            <p>{item.description}</p>
                                                                        </>
                                                                    }
                                                                >
                                                                    <Button variant="icon">
                                                                        <Icon name="status-info" />
                                                                    </Button>
                                                                </Popover>
                                                            </>
                                                        ), sortingField: 'company'
                                                    },
                                                    { id: 'asset_class', header: 'Asset Class', cell: item => item.asset_class, sortingField: 'asset_class' },
                                                    { id: 'industry', header: 'Industry', cell: item => item.industry, sortingField: 'industry' },
                                                    { id: 'ytd_return', header: 'YTD Return%', cell: item => item.ytd_return, sortingField: 'ytd_return' },
                                                    { id: 'three_yr_return', header: 'Three Year Return%', cell: item => item.three_yr_return, sortingField: 'three_yr_return' }
                                                ]}
                                                items={paginatedTickers}
                                                sortingColumn={sortingColumn}
                                                sortingDescending={sortingColumn.isDescending}
                                                onSortingChange={handleSortingChange}
                                                selectionType="multi"
                                                selectedItems={selectedTickers}
                                                onSelectionChange={handleTickerSelection}
                                                header={<Header>Tickers</Header>}
                                                trackBy="ticker"
                                            />
                                            <Button onClick={handleSaveTickers}>Save Tickers</Button>
                                        </SpaceBetween>

                                    </>
                                )
                            },


                        ]}
                    />
                </SpaceBetween>


            )}


        </Container>
    );
}

export default InvestmentDetail;


