import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';
import {
    Button, Select, FormField, Textarea, Input, Container, Header, SpaceBetween,
    Form, DatePicker, Flashbar, Box, Spinner, ColumnLayout, Table, Pagination, Input as CloudscapeInput, Badge, Popover, Icon
} from '@cloudscape-design/components';
import { useBreadcrumbs } from '../breadcrumb/BreadcrumbProvider';

function InvestmentInsert() {
    const { clientId, goalId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const { setBreadcrumbs } = useBreadcrumbs();
    const [investmentData, setInvestmentData] = useState(null);
    const [tickers, setTickers] = useState([]);
    const [selectedTickers, setSelectedTickers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortingColumn, setSortingColumn] = useState({ sortingField: 'ticker', isDescending: false });

    useEffect(() => {
        const fetchInvestmentData = async () => {
            try {
                const response = await axiosInstance.post(`/investments/${clientId}/goal/${goalId}`);
                setInvestmentData(response.data);
            } catch (error) {
                const errorMessage = error.response?.data?.detail || 'Failed to fetch investment details.';
                setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            } finally {
                setLoading(false);
            }
        };

        fetchInvestmentData();
    }, [clientId, goalId]);

    useEffect(() => {
        if (investmentData && investmentData.investment_type) {
            const fetchTickers = async () => {
                try {
                    if (investmentData.investment_type === 'Major Purchase'){
                        const response = await axiosInstance.get('/popular/tickers/Investment');
                        setTickers(response.data);
                    }else{
                        const response = await axiosInstance.get(`/popular/tickers/${investmentData.investment_type}`);
                        setTickers(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching tickers:', error);
                }
            };

            fetchTickers();
        }
    }, [investmentData]);

    const handleInputChange = ({ detail }, field) => {
        const value = detail.value;
        setInvestmentData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleDateChange = (field, value) => {
        setInvestmentData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleSelectChange = ({ detail }, field) => {
        setInvestmentData(prevData => ({
            ...prevData,
            [field]: detail.selectedOption.value
        }));
    };

    const handleTickerSelection = ({ detail }) => {
        setSelectedTickers(detail.selectedItems);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.detail.value);
    };

    const handleSortingChange = ({ detail: { sortingColumn, isDescending } }) => {
        setSortingColumn({ sortingField: sortingColumn.sortingField, isDescending });
    };

    const handlePaginationChange = ({ detail }) => {
        setCurrentPage(detail.currentPageIndex);
    };

    const filteredTickers = tickers.filter(ticker =>
        Object.values(ticker).some(value =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const sortedTickers = filteredTickers.sort((a, b) => {
        const aValue = a[sortingColumn.sortingField];
        const bValue = b[sortingColumn.sortingField];
        if (aValue < bValue) return sortingColumn.isDescending ? 1 : -1;
        if (aValue > bValue) return sortingColumn.isDescending ? -1 : 1;
        return 0;
    });

    const paginatedTickers = sortedTickers.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const dataToSubmit = { ...investmentData, tickers: selectedTickers.map(item => item.ticker) };
            await axiosInstance.post(`/investments/goals/${goalId}`, dataToSubmit);
            setFlashMessages([{
                header: "Success",
                content: "Investment added successfully!",
                type: "success",
                dismissible: true,
                onDismiss: () => setFlashMessages([])
            }]);
            navigate(`/clients/edit/${clientId}`);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message || 'Failed to add investment.';
            setFlashMessages([{
                header: "Error",
                content: `Failed to add investment: ${errorMessage}`,
                type: "error",
                dismissible: true,
                onDismiss: () => setFlashMessages([])
            }]);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Box textAlign="center"><Spinner /></Box>;

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                        <Button variant="primary">Submit</Button>
                    </SpaceBetween>
                }
            >
                <Container header={<Header variant="h1">Add New Investment</Header>}>
                    <Flashbar items={flashMessages} />
                    <SpaceBetween size="l" direction="vertical">
                        <ColumnLayout columns={2} variant="text-grid">

                            <FormField label="Investment Type" stretch={true}>
                                <Select
                                    selectedOption={{ label: investmentData.investment_type, value: investmentData.investment_type }}
                                    onChange={(event) => handleSelectChange(event, 'investment_type')}
                                    options={[
                                        { label: "Retirement", value: "Retirement" },
                                        { label: "Investment", value: "Investment" },
                                        { label: "Major Purchase", value: "Major Purchase" },
                                    ]}
                                />
                            </FormField>

                            <FormField label="Investment Value" stretch={true}>
                                <Input
                                    value={investmentData.investment_value}
                                    readOnly
                                />
                            </FormField>

                            <FormField label="Optimization Technique" stretch={true}>
                                <Select
                                    selectedOption={{ label: investmentData.optimization_technique, value: investmentData.optimization_technique }}
                                    onChange={(event) => handleSelectChange(event, 'optimization_technique')}
                                    options={[
                                        { label: "Maximum Sharpe Ratio", value: "Maximum Sharpe Ratio" },
                                        { label: "Minimum Volatility", value: "Minimum Volatility" },
                                        { label: "Efficient Risk", value: "Efficient Risk" },
                                        { label: "Efficient Return", value: "Efficient Return" },
                                    ]}
                                />
                            </FormField>

                            </ColumnLayout>
                            <FormField label="Description" stretch={true}>
                                <Textarea
                                    value={investmentData.description}
                                    onChange={(event) => handleInputChange(event, 'description')}
                                />
                            </FormField>


                        <FormField label="Selected Tickers" stretch={true}>
                            <ColumnLayout columns={6} variant="text-grid">
                                {selectedTickers.map((ticker, index) => (
                                    <Badge key={index} color="blue">{ticker.ticker} - {ticker.company}</Badge>
                                ))}
                            </ColumnLayout>
                        </FormField>

                        <FormField label="Tickers" stretch={true}>
                            <SpaceBetween size="s" direction="horizontal">
                                <CloudscapeInput
                                    value={searchQuery}
                                    placeholder="Search tickers"
                                    onChange={handleSearchChange}
                                />
                                <Pagination
                                    currentPageIndex={currentPage}
                                    onChange={handlePaginationChange}
                                    pagesCount={Math.ceil(tickers.length / pageSize)}
                                />
                            </SpaceBetween>
                            <Table
                                columnDefinitions={[
                                    { id: 'ticker', header: 'Ticker', cell: item => item.ticker, sortingField: 'ticker' },
                                    {
                                        id: 'company', header: 'Company', cell: item => (
                                            <>
                                                {item.company.substring(0, 30)}{item.company.length > 30 ? '...' : ''}
                                                <Popover
                                                    size="small"
                                                    position="top"
                                                    triggerType="custom"
                                                    header="Company Details"
                                                    content={
                                                        <>
                                                            <p>One Year Return: {item.one_yr_return}%</p>
                                                            <p>Five Year Return: {item.five_yr_return}%</p>
                                                            <p>{item.description}</p>
                                                        </>
                                                    }
                                                >
                                                    <Button variant="icon">
                                                        <Icon name="status-info" />
                                                    </Button>
                                                </Popover>
                                            </>
                                        ), sortingField: 'company'
                                    },
                                    { id: 'asset_class', header: 'Asset Class', cell: item => item.asset_class, sortingField: 'asset_class' },
                                    { id: 'industry', header: 'Industry', cell: item => item.industry, sortingField: 'industry' },
                                    { id: 'ytd_return', header: 'YTD Return', cell: item => item.ytd_return, sortingField: 'ytd_return' },
                                    { id: 'three_yr_return', header: 'Three Year Return', cell: item => item.three_yr_return, sortingField: 'three_yr_return' }
                                ]}
                                items={paginatedTickers}
                                sortingColumn={sortingColumn}
                                sortingDescending={sortingColumn.isDescending}
                                onSortingChange={handleSortingChange}
                                selectionType="multi"
                                selectedItems={selectedTickers}
                                onSelectionChange={handleTickerSelection}
                                header={<Header>Tickers</Header>}
                                trackBy="ticker"
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
                <Input type="hidden" value={investmentData.portfolio_id} />
                            <Input type="hidden" value={clientId} />
                            <Input type="hidden" value={investmentData.investment_id} />
                            <Input type="hidden" value={investmentData.cash} />
                            <Input type="hidden" name='start_date' value={investmentData.start_date} readOnly />
                            <Input type="hidden" name='end_date' value={investmentData.end_date} readOnly />

            </Form>
        </form>
    );
}

export default InvestmentInsert;


