// src/components/chatbot/Chatbot.js
import React, { useEffect, useState } from 'react';
//import { Widget, addResponseMessage, toggleWidget } from 'react-chat-widget';
//import 'react-chat-widget/lib/styles.css';
import axiosInstance from '../services/axiosConfig'; // Adjust the import based on your project structure


const Chatbot = ({ title = 'Chatbot', subtitle = 'More info on this!', contextData = '{}', entityKey = '' }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
  }, []);

  const handleNewUserMessage = async (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);

    try {
      const response = await axiosInstance.post('/chatbot/message', {
        message: newMessage,
        context: contextData,
        title: title,
        entity_key: entityKey
      });
      const botMessage = response.data.reply; 
      //addResponseMessage(botMessage);
    } catch (error) {
      console.error('Error sending message to backend:', error);
     // addResponseMessage('Sorry, I could not understand that.');
    }
  };

  const handleToggle = () => {
   // toggleWidget();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
  }, [isOpen]);

  return (
    <div className="Chatbot">
   </div>
  );
};

export default Chatbot;
