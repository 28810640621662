import React, { useState } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

function BeneficiaryModal({ clientId, visible, onClose, onBeneficiaryAdded }) {
    const [name, setName] = useState('');
    const [relationship, setRelationship] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [allocationPercentage, setAllocationPercentage] = useState('');
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    const handleSelectChange = (event, field) => {
        const value = event.detail.selectedOption.value;
        if (field === 'gender') setGender(value);
        if (field === 'relationship') setRelationship(value);
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newBeneficiary = { 
                client_id: clientId,
                name, 
                relationship, 
                date_of_birth: dateOfBirth, 
                gender, 
                allocation_percentage: allocationPercentage 
            };
            const response = await axiosInstance.post(`/beneficiaries`, newBeneficiary);
            onBeneficiaryAdded(response.data); // Call the onBeneficiaryAdded prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Beneficiary added successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Add Beneficiary"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Name">
                    <Input value={name} onChange={e => setName(e.detail.value)} />
                </FormField>
                <FormField label="Relationship">
                    <Select
                        selectedOption={{ label: relationship, value: relationship }}
                        onChange={(event) => handleSelectChange(event, 'relationship')}
                        options={[
                            { label: "Son", value: "Son" },
                            { label: "Daughter", value: "Daughter" },
                            { label: "Spouse", value: "Spouse" },
                        ]}
                    />
                </FormField>
                <FormField label="Date of Birth">
                    <Input type="date" value={dateOfBirth} onChange={e => setDateOfBirth(e.detail.value)} />
                </FormField>
                <FormField label="Gender" stretch={true}>
                    <Select
                        selectedOption={{ label: gender, value: gender }}
                        onChange={(event) => handleSelectChange(event, 'gender')}
                        options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                            { label: "Non-Binary", value: "Non-Binary" },
                            { label: "Prefer not to say", value: "Prefer not to say" },
                            { label: "Other", value: "Other" },
                        ]}
                    />
                </FormField>
                <FormField label="Allocation Percentage">
                    <Input type="number" value={allocationPercentage} onChange={e => setAllocationPercentage(e.detail.value)} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default BeneficiaryModal;
