import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ClientsList from './ClientsList';
import ClientInsert from './ClientInsert';
import ClientPortfolio from './ClientPortfolio';
import LeftMenu from './menu/LeftMenu';
import { AppLayout, HelpPanel } from '@cloudscape-design/components';
import InvestmentInsert from './investment/InvestmentInsert';
import LiveMeetingAssistant from './portfolio_mgmt/live_meeting/LiveMeetingAssistant';
import Overview from './portfolio_mgmt/overview/Overview';
import ChatPortfolio from './portfolio_mgmt/chat/ChatPortfolio';
import ChartWidget from './chart_widget/ChartWidget';
import ChatWidget from './chat_bot/ChatWidget';





function ClientRouter() {
    // useMatch can be used to derive path information if needed
    //let match = useMatch('/clients/*');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <AppLayout
        navigation={<LeftMenu isOpen={isMenuOpen} toggleDrawer={() => setIsMenuOpen(false)} />}
        tools={<HelpPanel header={<h2>Ask Bedrock</h2>}>
                <ChatWidget app_name="pm_bot_portal" entity_type="ClientList" Panel="Tools"
               /></HelpPanel>}
        content={
        <div>
            <h2>Client Portfolio</h2>
            <Routes>
                <Route path="/" element={<ClientsList />} />
                <Route path="new" element={<ClientInsert />} />
                <Route path="edit/:clientId" element={<ClientPortfolio />} />
                <Route path=":clientId/investments/insert/:goalId" element={<InvestmentInsert />} /> 
                <Route path="/lma" element={<LiveMeetingAssistant />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/chat" element={<ChatPortfolio/>} />
                <Route path="/chart" element={<ChartWidget/>} />






            </Routes>
        </div>
        }
        />

    );
}

export default ClientRouter;
