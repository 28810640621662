import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import axiosInstance from '../../../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container } from '@cloudscape-design/components';
import ToolSpecCreateModal from './ToolSpecCreateModal';
import ToolSpecEditModal from './ToolSpecEditModal';

function ToolSpecTable() {
    const { app_id } = useParams();
    const [tools, setTools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedTool, setSelectedTool] = useState(null);

    const fetchTools = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/genai_apps/${app_id}`)
            .then(response => {
                setTools(response.data.tools);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching tools:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No Tools found', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, [app_id]);

    useEffect(() => {
        alert(app_id);
        fetchTools();
    }, [fetchTools]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleAddTool = (tool) => {
        setTools(prevTools => [...prevTools, tool]);
        fetchTools();
    };

    const handleUpdateTool = (updatedTool) => {
        setTools(prevTools => prevTools.map(t => t.toolSpec.name === updatedTool.toolSpec.name ? updatedTool : t));
        fetchTools();
    };

    const handleDeleteTool = (toolName) => {
        setTools(prevTools => prevTools.filter(t => t.toolSpec.name !== toolName));
        fetchTools();
    };

    const handleEditClick = (tool) => {
        setSelectedTool(tool);
        setIsEditModalOpen(true);
    };

    if (loading) return <Box>Loading tools...</Box>;

    return (
        <Container
            header={<Header variant="h3"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add Tool
                        </Button>
                    </SpaceBetween>
                }
            >Tool Specs</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'Name', cell: item => item.toolSpec.name },
                    { header: 'Description', cell: item => item.toolSpec.description },
                    { header: 'Input Schema', cell: item => JSON.stringify(item.toolSpec.inputSchema, null, 2) },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={tools}
            />
            <ToolSpecCreateModal
                visible={isAddModalOpen}
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchTools();
                }}
                onToolAdded={handleAddTool}
                appId={app_id}
            />
            {selectedTool && (
                <ToolSpecEditModal
                    tool={selectedTool}
                    visible={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchTools();
                    }}
                    onToolUpdated={handleUpdateTool}
                    onToolDeleted={handleDeleteTool}
                />
            )}
        </Container>
    );
}

export default ToolSpecTable;
