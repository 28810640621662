import React from 'react';

import { Tabs } from '@cloudscape-design/components';

import ForexTable from './ForexTable';

function ForexTabs() {

   return (

       <Tabs

           tabs={[

               { label: 'Currencies', id: 'most_active', content: <ForexTable title="Currencies" endpoint="/forex/most_active" /> },


           ]}

       />

   );

}

export default ForexTabs;
