import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container, Popover, Icon } from '@cloudscape-design/components';
import CommunicationModal from './modals/CommunicationModal';
import EditCommunicationModal from './modals/EditCommunicationModal';

function ClientCommunicationsTable({ clientId }) {
    const [communications, setCommunications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedCommunication, setSelectedCommunication] = useState(null);

    const fetchCommunications = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/clients/${clientId}/communications`)
            .then(response => {
                setCommunications(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching communications:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage == null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No communicaiton with the client', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else
                setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                setLoading(false);
            });
    }, [clientId]);

    useEffect(() => {
        fetchCommunications();
    }, [fetchCommunications]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {

            if (error.response.data.detail === "No Communication"){
                return null;
            }
            if (Array.isArray(error.response.data.detail)){
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load communications data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;

        } else {
            return `Failed to load communications data: ${error.message}`;
        }
    };

    const handleAddCommunication = (communication) => {
        setCommunications(prevCommunications => [...prevCommunications, communication]);
        fetchCommunications();
    };

    const handleUpdateCommunication = (updatedCommunication) => {
        setCommunications(prevCommunications => prevCommunications.map(c => c.communication_id === updatedCommunication.communication_id ? updatedCommunication : c));
        fetchCommunications();
    };

    const handleDeleteCommunication = (communicationId) => {
        setCommunications(prevCommunications => prevCommunications.filter(c => c.communication_id !== communicationId));
        fetchCommunications();
    };

    const handleEditClick = (communication) => {
        setSelectedCommunication(communication);
        setIsEditModalOpen(true);
    };

    const truncateNotes = (notes) => {
        if (notes.length <= 70) return notes;
        const truncated = notes.slice(0, 70);
        return truncated.slice(0, truncated.lastIndexOf(' ')) + '...';
    };

    if (loading) return <Box>Loading communications...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add Communication
                        </Button>
                    </SpaceBetween>
                }
            >Client Communications</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'Type', cell: item => item.communication_type },
                    { header: 'Direction', cell: item => item.direction },
                    { 
                        header: 'Notes', 
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Popover 
                                    position="top" 
                                    size="small" 
                                    triggerType="hover" 
                                    content={item.notes}
                                >
                                    <Box display="inline-flex" alignItems="center">
                                        {truncateNotes(item.notes)}
                                        <Icon name="status-info" />
                                    </Box>
                                </Popover>
                            </SpaceBetween>
                        )
                    },
                    { header: 'Date Created', cell: item => new Date(item.date_created).toLocaleDateString() },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={communications}
            />
            <CommunicationModal 
                clientId={clientId} 
                visible={isAddModalOpen} 
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchCommunications();
                }} 
                onCommunicationAdded={handleAddCommunication} 
            />
            {selectedCommunication && (
                <EditCommunicationModal 
                    clientId={clientId} 
                    communication={selectedCommunication} 
                    visible={isEditModalOpen} 
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchCommunications();
                    }} 
                    onCommunicationUpdated={handleUpdateCommunication}
                    onCommunicationDeleted={handleDeleteCommunication}
                />
            )}
        </Container>
    );
}

export default ClientCommunicationsTable;
