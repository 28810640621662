import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ chartData, chartId }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartData && chartRef.current) {
      const ctx = document.getElementById(chartId).getContext('2d');
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const tableauColors = [
        '#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F', '#EDC948',
        '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'
      ];

      const labels = chartData.data[0].values.map(item => item.x);
      const datasets = chartData.data.map((series, index) => ({
        label: series.label,
        data: series.values.map(item => item.y),
        backgroundColor: tableauColors[index % tableauColors.length],
        borderColor: tableauColors[index % tableauColors.length],
        borderWidth: 1,
      }));

      chartInstanceRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: chartData.x_axis.title,
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: chartData.y_axis.title,
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: chartData.title,
            },
            legend: {
              display: true,
              position: 'top',
            },
          },
        },
      });
    }
  }, [chartData, chartId]);

  return <canvas id={chartId} ref={chartRef} style={{ width: '600px', height: '400px' }} />;
};

export default BarChart;


