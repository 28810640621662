import React, { useState, useEffect } from 'react';
import { Box, Container, Header, Flashbar, SpaceBetween, Spinner, Table, TextFilter, Pagination } from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../services/axiosConfig';

function SectorList({ title }) {
 const [loading, setLoading] = useState(true);
 const [flashMessages, setFlashMessages] = useState([]);
 const [data, setData] = useState([]);
 const navigate = useNavigate();

 useEffect(() => {
   async function fetchData() {
     try {
       const response = await axiosInstance.get("/sectors/overview");
       setData(response.data);
       setLoading(false);
     } catch (error) {
       setFlashMessages([{ header: "Error", content: `Failed to fetch data for ${title}.`, type: "error", dismissible: true }]);
       setLoading(false);
     }
   }
   fetchData();
 }, [title]);

 const { items, collectionProps, filterProps, paginationProps } = useCollection(data, {
   filtering: { empty: <Box textAlign="center">No data found.</Box>, noMatch: <Box textAlign="center">No matching data found.</Box> },
   pagination: { pageSize: 10 },
 });

 // Navigation method
 const handleSectorClick = (sectorName) => {
   const formattedSectorName = sectorName.toLowerCase().replace(/ /g, '-');
   navigate(`/analyst/sector/${formattedSectorName}`);
 };

 if (loading) return <Box textAlign="center"><Spinner /></Box>;

 return (
   <Container header={<Header variant="h3">{title}</Header>}>
     <Flashbar items={flashMessages} />
     <SpaceBetween size="l" direction="vertical">
       <TextFilter {...filterProps} />
       <Table
         {...collectionProps}
         columnDefinitions={[
           {
             header: 'Sector',
             cell: item => (
               item.Sector === 'All Sectors' ? (
                 <span>{item.Sector}</span>
               ) : (
                 <span
                   onClick={() => handleSectorClick(item.Sector)}
                   style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                 >
                   {item.Sector}
                 </span>
               )
             )
           },
           { header: 'Market Weight', cell: item => item['Market Weight'] },
           { header: 'YTD Return', cell: item => item['Unnamed: 3'] },
         ]}
         items={items}
       />
       <Pagination {...paginationProps} />
     </SpaceBetween>
   </Container>
 );
}

export default SectorList;
