import React, { useEffect, useState } from 'react';
import { Box, Spinner, SpaceBetween, Button } from '@cloudscape-design/components';
import Avatar from "@cloudscape-design/chat-components/avatar";
import DOMPurify from 'dompurify';
import { useQuery, useQueryClient } from 'react-query';
import axiosInstance from '../../services/axiosConfig';
import ChartWidget from '../chart_widget/ChartWidget';
import styled from 'styled-components';

// Fetch function to get data from the server
const fetchMessage = async ({ queryKey }) => {
  const [_, app_name, entity_type, context, prompt] = queryKey;
  const response = await axiosInstance.post('/chat/', {
    prompt: prompt,
    app_name: app_name,
    entity_type: entity_type,
    context: context,
  });
  return response.data;
};

const InsightWidget = ({ app_name, entity_type, context, prompt }) => {
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery(
    ['fetchMessage', app_name, entity_type, context, prompt],
    fetchMessage,
    { staleTime: 1000 * 60 * 5 } // Cache for 5 minutes
  );

  const renderContent = (message) => {
    const sanitizedHtml = DOMPurify.sanitize(message);
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  };

  // Optionally, you can invalidate queries elsewhere in your application logic
  // queryClient.invalidateQueries(['fetchMessage', app_name, entity_type, context, prompt]);

  const handleLike = async () => {
    if (prompt) {
      try {
        await axiosInstance.post('/chat/manage_cache/', {
          operation: 'extend',
          key: prompt // Using message_id as the cache key
        });
        // Optional: Add visual feedback
        console.log('Liked message');
      } catch (error) {
        console.error('Error submitting like:', error);
      }
    }
  };

  const handleDislike = async () => {
    if (prompt) {
      try {
        await axiosInstance.post('/chat/manage_cache/', {
          operation: 'remove',
          key: prompt
        });
        // Optional: Add visual feedback
        console.log('Disliked message');
      } catch (error) {
        console.error('Error submitting dislike:', error);
      }
    }
  };


  if (isLoading) return <Box>Loading <Spinner /></Box>;
  if (error) return <Box>Error occurred</Box>;

  return (
    <>
      <div style={{ display: 'flex', alignItems: "center" }}>
        <Avatar
          ariaLabel="Bedrock AI Agent"
          tooltipText="Bedrock AI Agent"
          color="gen-ai"
          iconName="gen-ai"
          style={{ marginRight: '8px' }}
        />&nbsp;{'Bedrock AI Agent '}
      </div>
      <Box align="center" margin={{ top: 'm' }} width="600px">
        <Box margin={{ bottom: 's' }} width="100%">
          {data && (
            <div className="dynamic-content">
              {renderContent(data.response)}
              {data.chartData && (
                <ChartWidget chartData={data.chartData} currentPage="1" />
              )}
            </div>
          )}
        </Box>
      </Box>
      <div className="reaction-buttons">
        <Box float="right" padding={{ horizontal: 's' }} display="flex" justifyContent="flex-end">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              iconName="thumbs-up"
              variant="icon"
              onClick={(e) => {
                handleLike();
              }}
              ariaLabel="Like response"
            />

            <Button
              iconName="thumbs-down"
              variant="icon"
              onClick={(e) => {
                handleDislike();
              }}
              ariaLabel="Dislike response"
            />



          </SpaceBetween>
        </Box>

      </div>




    </>
  );
};

export default InsightWidget;