import * as React from "react";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import ReactMarkdown from 'react-markdown';
import lmaThumbnail from './thumbnail.png';  // Import the thumbnail image

const markdown = `
>
## Live Meeting Assistant
The LMA sample solution captures speaker audio and metadata from your browser-based meeting app.
- It uses Amazon Transcribe for speech to text
- Knowledge Bases for Amazon Bedrock for contextual queries against your company’s documents
and knowledge sources,
- Amazon Bedrock models for customizable transcription insights and summaries.

### Solution Overview
The Live Meeting Assistant (LMA) is a solution that provides various features to enhance the meeting experience, including:
 - Live transcription with speaker attribution
 - Live translation to different languages
 - Context-aware meeting assistant that can answer questions using Knowledge Bases for Amazon Bedrock
 - On-demand meeting summaries, identification of action items, and custom insights
 - Automated post-meeting summaries and insights using Amazon Bedrock large language models
 - Meeting recording and searchable inventory of past meetings
 - Browser extension to capture audio and meeting metadata from popular meeting apps like Zoom and
 ### Prerequisites
- To use the full capabilities, you'll need an existing Knowledge Base on Amazon Bedrock populated with your company's documents and data. If you don't have a Knowledge Base, LMA can still be used but will rely more on the general knowledge of the language models.
### Key Benefits
- Improved meeting productivity
- Better information retention
- Ability to quickly catch up on meetings you may have missed
### Availability
- The solution is open-source and available on GitHub.
- It can be deployed in your own AWS account, and is designed to be easily integrated with your existing meeting workflow.



`;

function LiveMeetingAssistant() {
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleThumbnailClick = () => {
    setIsPlaying(true);
  };

  return (
    <Container>
      <SpaceBetween direction="vertical" size="s">
        <SpaceBetween direction="vertical" size="xxs">
          <Box variant="h2">
            <Link fontSize="heading-m" href="#">
              Live Meeting Assistant Demo
            </Link>
          </Box>
        </SpaceBetween>
        {isPlaying ? (
          <video width="100%" controls autoPlay>
            <source src="https://d2908q01vomqb2.cloudfront.net/artifacts/DBSBlogs/ML-16465/ML-16465-LMABlogDemoV3.mp4?_=1" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Link onClick={handleThumbnailClick}>
            <img src={lmaThumbnail} alt="Live Meeting Assistant Demo" style={{ width: '60%', height: 'auto' }} />
          </Link>
        )}
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </SpaceBetween>
    </Container>
  );
}

export default LiveMeetingAssistant;

