import './globalPolyfill';  // Adjust the path according to your file structure
import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import ClientRouter from './components/ClientRouter';
import PortfolioRouter from './components/PortfolioRouter';
import { Box, TopNavigation } from '@cloudscape-design/components';
import '@cloudscape-design/global-styles/index.css';
import { BreadcrumbProvider } from './components/breadcrumb/BreadcrumbProvider';
import HomeRouter from './components/fp/HomeRouter'; // Import the Home component
import PlanRouter from './components/fp/PlanRouter'; // Import the Home component
import ChatWindow from './components/chat/ChatWindow';
import Login from './components/login/Login';
import AdminRouter from './components/admin/AdminRouter';
import AnalystRouter from './components/analyst/AnalystRouter';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './components/login/Logout';
import axiosInstance from './services/axiosConfig';

function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
    }, []);

    const handleLogin = (username, accessToken) => {
        const fetchUserRole = async () => {
            try {
                const response = await axiosInstance.get(`/auth_users/email/${username}`);
                const user = response.data;
                localStorage.setItem('user', JSON.stringify(user));
                setUser(user);

                // Navigate based on user role
                switch (user.user_role) {
                    case 'AppAdmin':
                        navigate('/admin');
                        break;
                    case 'WealthAdmin':
                        navigate('/clients');
                        break;
                    case 'Analyst':
                        navigate('/analyst');
                        break;
                    case 'Client':
                        navigate('/chat');
                        break;
                    default:
                        navigate('/');
                        break;
                }
            } catch (error) {
                console.error('Failed to fetch user role:', error);
            }
        };

        fetchUserRole();
    };

    const handleSignOut = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setUser(null);
        navigate('/login');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <TopNavigation
                identity={{
                    title: 'Wealth Management',
                    logo: { src: 'https://d0.awsstatic.com/logos/powered-by-aws-white.png', alt: 'Logo' }
                }}
                utilities={
                    user ? [
                        {
                            type: "button",
                            text: "Portfolio Manager Portal",
                            href: "/clients",
                        },
                        {
                            type: "button",
                            text: "Client Portal",
                            href: "/chat",
                        },
                        {
                            type: "button",
                            text: "Markets",
                            href: "/analyst/chat",
                        },
                        {
                            type: "button",
                            text: "Admin Portal",
                            href: "/admin/conversations"
                            
                        },
                        {
                            type: "menu-dropdown",
                            text: user.email,
                            iconName: "user-profile",
                            items: [
                                { id: "profile",  iconName: "user-profile", text: "Profile", href: "/profile" },
                                { id: "signout", iconName: "external", text: "Sign out", href: "/logout" }
                            ]
                        }
                    ] : [
                        {
                            type: "button",
                            text: "Login",
                            href: "/login",
                        }
                    ]
                }
            />
            <BreadcrumbProvider>
                <Box padding={{ vertical: 'medium', horizontal: 'medium' }}>
                    <Routes>
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route element={<ProtectedRoute user={user} />}>
                            <Route path="*" element={<HomeRouter />} />
                            <Route path="/clients/*" element={<ClientRouter />} />
                            <Route path="/portfolios/*" element={<PortfolioRouter />} />
                            <Route path="/plan/*" element={<PlanRouter />} />
                            <Route path="/chat/:conversation_id" element={<ChatWindow />} />
                            <Route path="/chat/*" element={<ChatWindow />} />
                            <Route path="/admin/*" element={<AdminRouter />} />
                            <Route path="/analyst/*" element={<AnalystRouter />} />
                        </Route>
                    </Routes>
                </Box>
            </BreadcrumbProvider>
        </>
    );
}

export default App;


