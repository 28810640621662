import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';  // Ensure you're importing your configured axios instance
import { Box, Badge, Icon } from '@cloudscape-design/components';

function ClientBadge({ clientId }) {
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        axiosInstance.get(`/clients/${clientId}`)
            .then(response => {
                const { client_id, ...rest } = response.data;  // Ignoring client_id if not needed in the badge
                setClient(rest);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching client:', err);
                setError('Failed to load client data' + err);
                setLoading(false);
            });
    }, [clientId]); // Dependence on clientId ensures that this effect runs whenever the clientId changes

    if (loading) return <Box>Loading client details...</Box>;
    if (error) return <Box>Error: {error}</Box>;
    if (!client) return <Box>No client data available.</Box>;

    return (
        <Box>
            <Badge color="blue">
            <Badge color="grey">{client.name}</Badge>
            &nbsp;
            <Badge color="grey">Age: {client.age}</Badge>
            &nbsp;
            <Badge color="grey">Goal: {client.investment_goal}</Badge>
            </Badge>
        </Box>
    );
}

export default ClientBadge;
