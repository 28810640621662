import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select, Table, Header, Pagination } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

const accountTypes = [
    { label: "High Yield Savings", value: "High Yield Savings" },
    { label: "Money Market", value: "Money Market" },
    { label: "Investment Account", value: "Investment Account" },
    // Add more account types as needed
];

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

function SavingsAccountModal({ visible, onClose, clientId, savingsAccount }) {
    const [accountName, setAccountName] = useState(savingsAccount?.account_name || '');
    const [balance, setBalance] = useState(savingsAccount?.balance || '');
    const [interestRate, setInterestRate] = useState(savingsAccount?.interest_rate || '');
    const [accountType, setAccountType] = useState(savingsAccount?.account_type || '');
    const [loading, setLoading] = useState(false);
    const [posting, setPosting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transactionsLoading, setTransactionsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5); // Adjust the page size as needed

    useEffect(() => {
        if (savingsAccount) {
            setAccountName(savingsAccount.account_name);
            setBalance(savingsAccount.balance);
            setInterestRate(savingsAccount.interest_rate);
            setAccountType(savingsAccount.account_type);

            // Fetch savings account transactions
            fetchSavingsTransactions(savingsAccount.account_id);
        }
    }, [savingsAccount]);

    const handleSelectChange = (event) => {
        setAccountType(event.detail.selectedOption.value);
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleSubmit = async () => {
        setFlashMessages([]);
        setLoading(true);
        try {
            const updatedSavingsAccount = {
                client_id: clientId,
                account_name: accountName,
                balance: balance,
                interest_rate: interestRate,
                account_type: accountType,
                creation_date: savingsAccount.creation_date
            };
            await axiosInstance.put(`/savings_accounts/${savingsAccount.account_id}`, updatedSavingsAccount);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Savings account updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handlePostMonthlySavings = async () => {
        setPosting(true);
        try {
            const response = await axiosInstance.post(`/savings_accounts/${savingsAccount.account_id}/post_monthly_savings`);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Monthly savings posted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setPosting(false);
        }
    };

    const fetchSavingsTransactions = async (accountId) => {
        setTransactionsLoading(true);
        try {
            const response = await axiosInstance.get(`/savings_accounts/${accountId}/transactions`);
            const sortedTransactions = response.data.sort((a, b) => new Date(b.transaction_date) - new Date(a.transaction_date));
            setTransactions(sortedTransactions);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // If the transactions are not found, just return without setting any error messages
                setTransactions([]);
            } else {
                const errorMessage = extractErrorMessage(error);
                setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            }
        } finally {
            setTransactionsLoading(false);
        }
    };

    const handlePageChange = ({ detail: { currentPageIndex } }) => {
        setCurrentPage(currentPageIndex);
    };

    const paginatedTransactions = transactions.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Savings Account"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handlePostMonthlySavings} disabled={posting}>
                        {posting ? <Spinner /> : 'Post Monthly Savings'}
                    </Button>
                    <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <SpaceBetween direction="vertical" size="xs">
                <Form>
                    {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                    <FormField label="Account Name">
                        <Input value={accountName} onChange={e => setAccountName(e.detail.value)} />
                    </FormField>
                    <FormField label="Balance">
                        <Input value={balance} onChange={e => setBalance(e.detail.value)} />
                    </FormField>
                    <FormField label="Interest Rate">
                        <Input value={interestRate} onChange={e => setInterestRate(e.detail.value)} />
                    </FormField>
                    <FormField label="Account Type">
                        <Select
                            selectedOption={accountTypes.find(option => option.value === accountType)}
                            onChange={handleSelectChange}
                            options={accountTypes}
                            placeholder="Select an account type"
                        />
                    </FormField>
                </Form>

                {transactionsLoading ? (
                    <Spinner />
                ) : transactions.length > 0 && (
                    <>
                        <Table
                            columnDefinitions={[
                                { id: 'transaction_date', header: 'Date', cell: item => item.transaction_date },
                                { id: 'transaction_type', header: 'Description', cell: item => item.transaction_type },
                                { id: 'amount', header: 'Amount', cell: item => formatCurrency(item.amount) }
                            ]}
                            items={paginatedTransactions}
                            header={<Header>Transactions</Header>}
                        />
                        <Pagination
                            currentPageIndex={currentPage}
                            pagesCount={Math.ceil(transactions.length / pageSize)}
                            onChange={handlePageChange}
                        />
                    </>
                )}
            </SpaceBetween>
        </Modal>
    );
}

export default SavingsAccountModal;


