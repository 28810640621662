import React, { useState } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

function DepositModal({ visible, onClose, accountId, onDepositSuccess }) {
    const [depositAmount, setDepositAmount] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            return `Failed to make a deposit: ${error.response.data.detail}`;
        } else {
            return `Failed to make a deposit: ${error.message}`;
        }
    };

    const handleDeposit = async () => {
        setLoading(true);
        setFlashMessages([]);
        try {
            const response = await axiosInstance.post(`/savings_accounts/${accountId}/deposit/`, {
                amount: depositAmount,
                note: note
            });
            onDepositSuccess(response.data);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Deposit made successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Make a Deposit"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleDeposit} disabled={loading}>
                        {loading ? <Spinner /> : 'Submit'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Deposit Amount">
                    <Input value={depositAmount} onChange={e => setDepositAmount(e.detail.value)} />
                </FormField>
                <FormField label="Note">
                    <Input value={note} onChange={e => setNote(e.detail.value)} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default DepositModal;


