import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container } from '@cloudscape-design/components';
import MessageCreateModal from './MessageCreateModal';
import MessageEditModal from './MessageEditModal';

function MessageTable({ conversation_id }) {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);

    const fetchMessages = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/messages?conversation_id=${conversation_id}`)
            .then(response => {
                setMessages(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching messages:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No Messages found', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, [conversation_id]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleAddMessage = (message) => {
        setMessages(prevMessages => [...prevMessages, message]);
        fetchMessages();
    };

    const handleUpdateMessage = (updatedMessage) => {
        setMessages(prevMessages => prevMessages.map(m => m.message_id === updatedMessage.message_id ? updatedMessage : m));
        fetchMessages();
    };

    const handleDeleteMessage = (messageId) => {
        setMessages(prevMessages => prevMessages.filter(m => m.message_id !== messageId));
        fetchMessages();
    };

    const handleEditClick = (message) => {
        setSelectedMessage(message);
        setIsEditModalOpen(true);
    };

    if (loading) return <Box>Loading messages...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add Message
                        </Button>
                    </SpaceBetween>
                }
            >Messages</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'Role', cell: item => item.role },
                    { header: 'Content', cell: item => item.content },
                    { header: 'Created At', cell: item => new Date(item.created_at).toLocaleDateString() },
                    { header: 'Likes', cell: item => item.likes },
                    { header: 'Dislikes', cell: item => item.dislikes },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={messages}
            />
            <MessageCreateModal
                visible={isAddModalOpen}
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchMessages();
                }}
                conversationId={conversation_id}
            />
            {selectedMessage && (
                <MessageEditModal
                    message={selectedMessage}
                    visible={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchMessages();
                    }}
                />
            )}
        </Container>
    );
}

export default MessageTable;


