import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select, Textarea } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

function EditCommunicationModal({ clientId, communication, visible, onClose, onCommunicationUpdated, onCommunicationDeleted }) {
    const [communicationType, setCommunicationType] = useState(communication.communication_type);
    const [notes, setNotes] = useState(communication.notes);
    const [direction, setDirection] = useState(communication.direction);
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        setCommunicationType(communication.communication_type);
        setNotes(communication.notes);
        setDirection(communication.direction);
    }, [communication]);

    const handleSelectChange = (event, field) => {
        const value = event.detail.selectedOption.value;
        if (field === 'communicationType') setCommunicationType(value);
        if (field === 'direction') setDirection(value);
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const updatedCommunication = {
                communication_type: communicationType,
                notes,
                direction,
                client_id: clientId
            };
            await axiosInstance.put(`/communications/${communication.communication_id}`, updatedCommunication);
            onCommunicationUpdated(updatedCommunication); // Call the onCommunicationUpdated prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Communication updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await axiosInstance.delete(`/communications/${communication.communication_id}`);
            onCommunicationDeleted(communication.communication_id); // Call the onCommunicationDeleted prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Communication deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Communication"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>
                        {loading ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Communication Type">
                    <Select
                        selectedOption={{ label: communicationType, value: communicationType }}
                        onChange={(event) => handleSelectChange(event, 'communicationType')}
                        options={[
                            { label: "Email", value: "Email" },
                            { label: "Phone Call", value: "Phone Call" },
                            { label: "Virtual Meeting", value: "Virtual Meeting" },
                            { label: "In-Person Meeting", value: "In-Person Meeting" },
                            { label: "Messaging", value: "Messaging" },
                            { label: "Bedrock Chat", value: "Bedrock Chat" },
                            { label: "Other", value: "Other" },

                        ]}
                    />
                </FormField>
                <FormField label="Direction">
                    <Select
                        selectedOption={{ label: direction, value: direction }}
                        onChange={(event) => handleSelectChange(event, 'direction')}
                        options={[
                            { label: "Incoming", value: "Incoming" },
                            { label: "Outgoing", value: "Outgoing" },
                        ]}
                    />
                </FormField>
                <FormField label="Notes">
                    <Textarea value={notes} onChange={e => setNotes(e.detail.value)} />
                </FormField>

            </Form>
        </Modal>
    );
}

export default EditCommunicationModal;
