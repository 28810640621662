import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container } from '@cloudscape-design/components';
import CreateGenAIAppModal from './CreateGenAIAppModal';
import EditGenAIAppModal from './EditGenAIAppModal';

function GenAIAppTable() {
    const [genaiApps, setGenAIApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedGenAIApp, setSelectedGenAIApp] = useState(null);

    const fetchGenAIApps = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/genai_apps`)
            .then(response => {
                setGenAIApps(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching GenAI apps:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No GenAI Apps found', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchGenAIApps();
    }, [fetchGenAIApps]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleAddGenAIApp = (genaiApp) => {
        setGenAIApps(prevGenAIApps => [...prevGenAIApps, genaiApp]);
        fetchGenAIApps();
    };

    const handleUpdateGenAIApp = (updatedGenAIApp) => {
        setGenAIApps(prevGenAIApps => prevGenAIApps.map(a => a.app_id === updatedGenAIApp.app_id ? updatedGenAIApp : a));
        fetchGenAIApps();
    };

    const handleDeleteGenAIApp = (appId) => {
        setGenAIApps(prevGenAIApps => prevGenAIApps.filter(a => a.app_id !== appId));
        fetchGenAIApps();
    };

    const handleEditClick = (genaiApp) => {
        setSelectedGenAIApp(genaiApp);
        setIsEditModalOpen(true);
    };

    if (loading) return <Box>Loading GenAI apps...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add GenAI App
                        </Button>
                    </SpaceBetween>
                }
            >GenAI Apps</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'App ID', cell: item => item.app_id },
                    { header: 'App Name', cell: item => item.app_name },
                    { header: 'Created At', cell: item => new Date(item.created_dt).toLocaleDateString() },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={genaiApps}
            />
            <CreateGenAIAppModal
                visible={isAddModalOpen}
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchGenAIApps();
                }}
                onGenAIAppAdded={handleAddGenAIApp}
            />
            {selectedGenAIApp && (
                <EditGenAIAppModal
                    genaiApp={selectedGenAIApp}
                    visible={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchGenAIApps();
                    }}
                    onGenAIAppUpdated={handleUpdateGenAIApp}
                    onGenAIAppDeleted={handleDeleteGenAIApp}
                />
            )}
        </Container>
    );
}

export default GenAIAppTable;
