import React from 'react';
import BarChart from './BarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import AreaChart from './AreaChart';

const generateRandomId = () => {
    return Math.floor(Math.random() * 100000);
};

const ChartWidget = ({ chartData, currentPage }) => {
    if (!chartData) {
        return "";
    }

    const uniqueChartId = "chart-" + currentPage + "-" + generateRandomId();

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
            {chartData.chart_type === 'bar' && <BarChart chartData={chartData} chartId={uniqueChartId} />}
            {chartData.chart_type === 'line' && <LineChart  chartData={chartData} chartId={uniqueChartId} />}
            {chartData.chart_type === 'pie' && <PieChart chartData={chartData} chartId={uniqueChartId} />}
            {chartData.chart_type === 'area' && <AreaChart  chartData={chartData} chartId={uniqueChartId} />}
        </div>
    );
};

export default ChartWidget;


