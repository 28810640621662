import React, { useState, useEffect } from 'react';
import { Box, Pagination } from '@cloudscape-design/components';
import DOMPurify from 'dompurify';
import './ChatWidgetMessage.css';
import ChartWidget from '../chart_widget/ChartWidget';
import ChatChainOfThought from './ChatChainOfThought';


const ChatWidgetMessage = ({ messages = [], chartData=[], snippets }) => {
  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 1; // Display one message pair (user + assistant) per page

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(Math.ceil(groupedMessages.length / messagesPerPage));
  }, [messages]);

  
  const groupMessages = (messages) => {
    const groupedMessages = [];
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].role === 'user') {
        const userMessage = messages[i];
        const assistantMessage = messages[i + 1] && messages[i + 1].role === 'assistant' ? messages[i + 1] : null;
        groupedMessages.push([userMessage, assistantMessage]);
        i += assistantMessage ? 1 : 0;
      }
    }
    return groupedMessages;
  };

  const groupedMessages = groupMessages(messages);
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = groupedMessages.slice(indexOfFirstMessage, indexOfLastMessage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderContent = (message) => {
    const sanitizedHtml = DOMPurify.sanitize(message.content[0].text);
    // Use dangerouslySetInnerHTML with caution
    return <div id={currentPage} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  };

  return (
    <Box align="left" margin={{ top: 'm' }} width="200px">
      {currentMessages.map((messagePair, index) => (
        <Box key={index} margin={{ bottom: 's' }} width="100%">
          <div className="user-message">
            <h4>{renderContent(messagePair[0])}</h4>
          </div>
          {messagePair[1] && (
            <div className="dynamic-content">
              {renderContent(messagePair[1])}
           
   
              {messagePair[1] && chartData && <ChartWidget chartData={chartData} currentPage={currentPage} />} 

              {messagePair[1] && snippets && <ChatChainOfThought snippets={snippets} />} 
       
              </div>
                   )}
        </Box>
      ))}
      {groupedMessages.length > 1 && (
        <Pagination
          currentPageIndex={currentPage}
          pagesCount={Math.ceil(groupedMessages.length / messagesPerPage)}
          onChange={({ detail }) => paginate(detail.currentPageIndex)}
        />
      )}





    </Box>
    
  );
};

export default ChatWidgetMessage;
