// loan_account.js

export const LoanType = {
    STUDENT_LOAN: "Student Loan",
    PERSONAL: "Personal",
    MORTGAGE: "Mortgage",
    AUTO: "Auto",
    CREDIT_CARD: "Credit Card",
    PAY_OFF_DEBT: "Pay off Debt",
    OTHER: "Other"
};

