import React, { useState, useEffect } from 'react';

import { Box, Button, ExpandableSection, RadioGroup, Container, Header, Flashbar, SpaceBetween, Spinner, Table, TextFilter, Pagination } from '@cloudscape-design/components';

import { useCollection } from '@cloudscape-design/collection-hooks';

import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';
import ChatWidget from '../../chat_widget/ChatWidget';

function CryptoTable({ title, endpoint }) {

   const [loading, setLoading] = useState(true);

   const [flashMessages, setFlashMessages] = useState([]);

   const [data, setData] = useState([]);
   const [selectedTicker, setSelectedTicker] = useState(null);
   const navigate = useNavigate();

   useEffect(() => {

       async function fetchData() {

           try {

               const response = await axiosInstance.get(endpoint);

               setData(response.data);
               console.log(response.data); // Debug the incoming data

               setLoading(false);

           } catch (error) {

               setFlashMessages([{ header: "Error", content: `Failed to fetch data for ${title}.`, type: "error", dismissible: true }]);

               setLoading(false);

           }

       }

       fetchData();

   }, [endpoint, title]);

   const { items, collectionProps, filterProps, paginationProps } = useCollection(data, {

       filtering: { empty: <Box textAlign="center">No data found.</Box>, noMatch: <Box textAlign="center">No matching data found.</Box> },

       pagination: { pageSize: 10 },

   });

   const handleViewDetails = () => {
    if (selectedTicker) {
        //navigate(`/analyst/tickers/details/${selectedTicker}`);
        navigate(`/analyst/tickers/equity/${selectedTicker}`);

    } else {
        setFlashMessages([{
            header: "Error",
            content: "Please select a ticker to view details.",
            type: "error",
            dismissible: true,
            onDismiss: () => setFlashMessages([])
        }]);
    }
};



   if (loading) return <Box textAlign="center"><Spinner /></Box>;

   return (

       <Container header={<Header variant="h3"             
            actions={
                <Button variant="primary" onClick={handleViewDetails}>View Details</Button>
            }
       >{title}</Header>}>

           <Flashbar items={flashMessages} />
           <ExpandableSection headerText='Ask Bedrock' >
               <ChatWidget app_name="AnalystPortal" entity_type="tickers"
                   context={JSON.stringify(items)}
                   entity_id={title}
               />
               </ExpandableSection>

           <SpaceBetween size="l" direction="vertical">

               <TextFilter {...filterProps} />

               <Table

                   {...collectionProps}

                   columnDefinitions={[
                    {
                        header: 'Select',
                        cell: item => (
                            <RadioGroup
                                value={selectedTicker}
                                onChange={({ detail }) => setSelectedTicker(detail.value)}
                                items={[{ label: '', value: item.symbol }]}
                            />
                        )
                    },

                       { header: 'Symbol', cell: item => item.symbol },

                       { header: 'Name', cell: item => item.name },

                       { header: 'Price', cell: item => `$${item.price}` },

                       { header: 'Change', cell: item => `$${item.change}` },

                       { header: '% Change', cell: item => item.changePercent},

                       { header: 'Volume', cell: item => item.volume },

                       { header: 'Market Cap', cell: item => item.marketCap},
                       
                       { header: 'Week Range', cell: item => item.weekRange},

                       { header: 'Circulating Supply', cell: item => item['Circulating Supply']},


                       //Circulating Supply
                       //marketCap
                       //weekRange


                   ]}

                   items={items}

               />

               <Pagination {...paginationProps} />

           </SpaceBetween>

       </Container>

   );

}

export default CryptoTable;
