import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Select, SpaceBetween, Spinner, Flashbar } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';


const workflowStatuses = [
    { label: "Registering", value: "Registering" },
    { label: "Finalize Budget", value: "Finalize Budget" },
    { label: "Financial Planning", value: "Financial Planning" },
    { label: "Wealth Management", value: "Wealth Management" }
];


function ClientWorkflowStatusModal({ visible, onClose, clientId, currentStatus, onStatusUpdated }) {
    const [workflowStatus, setWorkflowStatus] = useState(currentStatus || '');
    const [saving, setSaving] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);


    useEffect(() => {
        setWorkflowStatus(currentStatus);
    }, [currentStatus]);


    const handleWorkflowStatusChange = (e) => {
        setWorkflowStatus(e.detail.selectedOption.value);
    };


    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            return `Client Error: ${error.response.data.detail}`;
        } else {
            return `Client Error: ${error.message}`;
        }
    };


    const handleSaveWorkflowStatus = async () => {
        setSaving(true);
        try {
            await axiosInstance.put(`/clients/${clientId}/workflow_status`, { workflow_status: workflowStatus });
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Workflow status updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onStatusUpdated(workflowStatus);
            onClose();
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };


    if (!visible) return null;


    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Update Workflow Status"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="primary" onClick={handleSaveWorkflowStatus}>
                        {saving ? <Spinner variant='inverted' /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Workflow Status">
                    <Select
                        selectedOption={workflowStatuses.find(option => option.value === workflowStatus)}
                        onChange={handleWorkflowStatusChange}
                        options={workflowStatuses}
                        placeholder="Select a workflow status"
                    />
                </FormField>
            </Form>
        </Modal>
    );
}


export default ClientWorkflowStatusModal;




