import React, { useState, useEffect } from 'react';
import { Box, ExpandableSection, Container, Header, Flashbar, SpaceBetween, Spinner, Table, Pagination } from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import axiosInstance from '../../../services/axiosConfig';
import { useParams } from 'react-router-dom';
import ChatWidget from '../../chat_widget/ChatWidget';

function IndustryDetail() {
  const [loading, setLoading] = useState(true);
  const [flashMessages, setFlashMessages] = useState([]);
  const [data, setData] = useState({});
  const { industry } = useParams();
  const endpoint = `industry/${industry}`;
  const title = industry.charAt(0).toUpperCase() + industry.slice(1);

  // Fetch data
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(endpoint);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setFlashMessages([{ header: "Error", content: `Failed to fetch data for ${title}.`, type: "error", dismissible: true }]);
        setLoading(false);
      }
    }
    fetchData();
  }, [endpoint, title]);

  // Extract top performing and top growth companies
  const topPerformingCompanies = Array.isArray(data.top_performing_companies) ? data.top_performing_companies : [];
  const topGrowthCompanies = Array.isArray(data.top_growth_companies) ? data.top_growth_companies : [];

  // Initialize useCollection for each dataset
  const topPerformingCollection = useCollection(topPerformingCompanies, { pagination: { pageSize: 10 } });
  const topGrowthCollection = useCollection(topGrowthCompanies, { pagination: { pageSize: 10 } });

  const renderTable = (collection, columns) => (
    <>
      <Table
        {...collection.collectionProps}
        columnDefinitions={columns}
        items={collection.items}
      />
      <Pagination {...collection.paginationProps} />
    </>
  );

  if (loading) return <Box textAlign="center"><Spinner /></Box>;

  return (
    <Container header={<Header variant="h3">{title}</Header>}>
      <Flashbar items={flashMessages} />
      <ExpandableSection headerText="Chat with Bedrock">
        <ChatWidget app_name="AnalystPortal" entity_type="Industry" context={JSON.stringify(data)} entity_id={title} />
      </ExpandableSection>

      <SpaceBetween size="l" direction="vertical">
        {/* Top Performing Companies Table */}
        <Box header={<Header variant="h4">Top Performing Companies</Header>}>
            <h3>Top Performing Companies</h3>
          {renderTable(topPerformingCollection, [
            { header: 'Name', cell: item => item.name || 'N/A' },
            { header: 'Last Price', cell: item => `$${item[' last price']?.toFixed(2) || 'N/A'}` },
            { header: '1Y Target Price', cell: item => `$${item['target price']?.toFixed(2) || 'N/A'}` },
            { 
              header: 'YTD Return', 
              cell: item => 
                item['ytd return'] ? `${(item['ytd return'] * 100).toFixed(2)}%` : 'N/A' 
            }
          ])}
        </Box>

        {/* Top Growth Companies Table */}
        <Box header={<Header variant="h4">Top Growth Companies</Header>}>
            <h3>Top Growth Companies</h3>
          {renderTable(topGrowthCollection, [
            { header: 'Name', cell: item => item.name || 'N/A' },
            { 
              header: 'Growth Estimate', 
              cell: item => 
                item[' growth estimate'] ? `${(item[' growth estimate'] * 100).toFixed(2)}%` : 'N/A' 
            },
            { 
              header: 'YTD Return', 
              cell: item => 
                item['ytd return'] ? `${(item['ytd return'] * 100).toFixed(2)}%` : 'N/A' 
            }
          ])}
        </Box>
      </SpaceBetween>
    </Container>
  );
}

export default IndustryDetail;
