import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import ChatList from './ChatList';
import { Container, Header, Box, Grid, ContentLayout } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';
import './ChatWindow.css'; // Import the consolidated CSS file
import { Icon } from "@cloudscape-design/components";

const ChatWindow = () => {
    const [messages, setMessages] = useState([]);
    const [conversationId, setConversationId] = useState(null);
    const [searchParams, setSearchParams] = useState({ app_name: 'ClientPortal', entity_type: 'client_id' });
    const { conversation_id } = useParams();
    const [chartData, setChartData] = useState(null);
    const [snippets, setSnippets] = useState(null);
    const [message_id, setMessage_id] = useState(null);


    useEffect(() => {
        if (conversation_id) {
            fetchConversation(conversation_id);
        }
    }, [conversation_id]);

    const handleSendMessage = (text) => {
        setMessages([...messages, { role: 'user', content: [{ text }] }]);
    };

    const handleReceiveMessage = (text, conversationId, chartData, snippets, message_id) => {
        setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: [{ text }] }]);
        setConversationId(conversationId);
        setMessage_id(message_id);
        setChartData(prevChartData => ({ ...prevChartData, ...chartData }));
        setSnippets(snippets);

        console.log("chartData", chartData);
        console.log("snippets", snippets);
        //message_id
        console.log("message_id", message_id);

    };

    const fetchConversation = async (conversationId) => {
        try {
            const response = await axiosInstance.get(`/conversations/${conversationId}`);
            const { conversation, messages } = response.data;
            setMessages(messages);
            setConversationId(conversation.conversation_id);
        } catch (error) {
            console.error('Error fetching conversation:', error);
        }
    };


    return (

        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
            <div>
                <ChatList searchParams={searchParams} />
            </div>
            <div>
                <Box padding={{ vertical: 's' }} className="chata-box">
                    <div className="chat-window">
                        {messages.map((message, index) => (
                            <ChatMessage key={index} message={message} message_id={message_id}
                                snippets={snippets} chartData={chartData} />
                        ))}
                    </div>
                </Box>
                    <ChatInput
                        onReceiveMessage={handleReceiveMessage}
                        onSendMessage={handleSendMessage}
                    />
            </div>
            <div></div>
        </Grid>
    );
};

export default ChatWindow;


