import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';

function ToolSpecEditModal({ tool, visible, onClose, onToolUpdated, onToolDeleted }) {
    const [toolData, setToolData] = useState({ ...tool.toolSpec });
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        setToolData({ ...tool.toolSpec });
    }, [tool]);

    const handleInputChange = (e, field) => {
        setToolData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSaveTool = async () => {
        setSaving(true);
        try {
            const updates = {
                toolSpec: {
                    name: toolData.name,
                    description: toolData.description,
                    inputSchema: JSON.parse(toolData.inputSchema)
                }
            };
            await axiosInstance.put(`/genai_apps/${tool.app_id}/tools/${toolData.name}`, updates);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Tool updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onToolUpdated({ ...tool, ...updates });
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteTool = async () => {
        setDeleting(true);
        try {
            await axiosInstance.delete(`/genai_apps/${tool.app_id}/tools/${toolData.name}`);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Tool deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onToolDeleted(toolData.name);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Tool"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteTool}>
                        {deleting ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSaveTool}>
                        {saving ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Name">
                    <Input value={toolData.name} readOnly />
                </FormField>
                <FormField label="Description">
                    <Input value={toolData.description} onChange={e => handleInputChange(e, 'description')} />
                </FormField>
                <FormField label="Input Schema (JSON)">
                    <Input value={toolData.inputSchema} onChange={e => handleInputChange(e, 'inputSchema')} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default ToolSpecEditModal;
