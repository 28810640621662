import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container, Popover, Icon } from '@cloudscape-design/components';
import UserPromptCreateModal from './UserPromptCreateModal';
import UserPromptEditModal from './UserPromptEditModal';
import { useLocation } from 'react-router-dom';

function UserPromptTable() {
    const [userPrompts, setUserPrompts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedUserPrompt, setSelectedUserPrompt] = useState(null);
    const [genAIApps, setGenAIApps] = useState([]);
    const location = useLocation();

    useEffect(() => {
        if (location.state?.flashMessage) {
            setFlashMessages([{ type: 'success', header: 'Success', content: location.state.flashMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        }
    }, [location.state]);

    const fetchGenAIApps = async () => {
        try {
            const response = await axiosInstance.get('/genai_apps');
            setGenAIApps(response.data.reduce((acc, app) => {
                acc[app.app_id] = app.app_name;
                return acc;
            }, {}));
        } catch (error) {
            console.error('Failed to fetch GenAI apps:', error);
        }
    };

    const fetchUserPrompts = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/user_prompts`)
            .then(response => {
                setUserPrompts(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching user prompts:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No User Prompts found', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchGenAIApps();
        fetchUserPrompts();
    }, [fetchUserPrompts]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleAddUserPrompt = (userPrompt) => {
        setUserPrompts(prevUserPrompts => [...prevUserPrompts, userPrompt]);
        fetchUserPrompts();
    };

    const handleUpdateUserPrompt = (updatedUserPrompt) => {
        setUserPrompts(prevUserPrompts => prevUserPrompts.map(up => up.prompt_id === updatedUserPrompt.prompt_id ? updatedUserPrompt : up));
        fetchUserPrompts();
    };

    const handleDeleteUserPrompt = (promptId) => {
        setUserPrompts(prevUserPrompts => prevUserPrompts.filter(up => up.prompt_id !== promptId));
        fetchUserPrompts();
    };

    const handleEditClick = (userPrompt) => {
        setSelectedUserPrompt(userPrompt);
        setIsEditModalOpen(true);
    };

    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    if (loading) return <Box>Loading user prompts...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add User Prompt
                        </Button>
                    </SpaceBetween>
                }
            >User Prompts</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'App Name', cell: item => genAIApps[item.app_id] || item.app_id },
                    {
                        header: 'Prompt Text', cell: item => (
                            <Box>
                                {truncateText(item.prompt_text, 12)}
                                <Popover
                                    position="top"
                                    size="medium"
                                    triggerType="custom"
                                    content={<Box>{item.prompt_text.split(' ').slice(0, 150).join(' ')}</Box>}
                                >
                                    &nbsp;<Icon name="status-info" />
                                </Popover>
                            </Box>
                        )
                    },
                    { header: 'Icon Name', cell: item => item.icon_name },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={userPrompts}
            />
            <UserPromptCreateModal
                visible={isAddModalOpen}
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchUserPrompts();
                }}

            />
            {selectedUserPrompt && (
                <UserPromptEditModal
                    userPrompt={selectedUserPrompt}
                    visible={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchUserPrompts();
                    }}
                />
            )}
        </Container>
    );
}

export default UserPromptTable;


