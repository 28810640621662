import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

const goalTypes = [
   { label: "Retirement", value: "Retirement" },
   { label: "Investment", value: "Investment" },
   { label: "Education", value: "Education" },
   { label: "Emergency Fund", value: "Emergency Fund" },
   { label: "Major Purchase", value: "Major Purchase" },
   { label: "Vacation", value: "Vacation" },
   { label: "Pay off Debt", value: "Pay off Debt" },
   { label: "Other", value: "Other" }
];

function EditGoalModal({ visible, onClose, goal, clientId, onGoalUpdated }) {
   const [goalData, setGoalData] = useState(goal || {});
   const [saving, setSaving] = useState(false);
   const [deleting, setDeleting] = useState(false);
   const [flashMessages, setFlashMessages] = useState([]);

   useEffect(() => {
       setGoalData(goal || {});
   }, [goal]);

   const handleInputChange = (e, field) => {
       setGoalData(prev => ({ ...prev, [field]: e.detail.value }));
   };

   const handleGoalTypeChange = (e) => {
       setGoalData(prev => ({ ...prev, goal_type: e.detail.selectedOption.value }));
   };

   const extractErrorMessage = (error) => {
       if (error.response && error.response.data && error.response.data.detail) {
           if (Array.isArray(error.response.data.detail)) {
               const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
               return `Failed to load data: ${details}`;
           }
           return `Failed to load data: ${error.response.data.detail}`;
       } else {
           return `Failed to load data: ${error.message}`;
       }
   };

   const handleSaveGoal = async () => {
       setSaving(true);
       try {
           const updates = {
               client_id: clientId,
               goal: goalData.goal,
               goal_type: goalData.goal_type,
               target_amount: goalData.target_amount,
               current_savings: goalData.current_savings,
               monthly_contribution: goalData.monthly_contribution,
               start_date: goalData.start_date,
               end_date: goalData.end_date
           };
           await axiosInstance.put(`/goals/${goalData.goal_id}`, updates);
           setFlashMessages([{ type: 'success', header: 'Success', content: 'Goal updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           onClose();
           onGoalUpdated();
       } catch (error) {
           const errorMessage = extractErrorMessage(error);
           setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
       } finally {
           setSaving(false);
       }
   };

   const handleDeleteGoal = async () => {
       setDeleting(true);
       try {
           await axiosInstance.delete(`/goals/${goalData.goal_id}`);
           setFlashMessages([{ type: 'success', header: 'Success', content: 'Goal deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           onClose();
           onGoalUpdated();
       } catch (error) {
           const errorMessage = extractErrorMessage(error);
           setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
       } finally {
           setDeleting(false);
       }
   };

   if (!goal) {
       return null;
   }

   return (
       <Modal
           visible={visible}
           onDismiss={onClose}
           header="Edit Goal"
           footer={
               <SpaceBetween direction="horizontal" size="xs">
                   <Button onClick={onClose}>Cancel</Button>
                   <Button variant="danger" onClick={handleDeleteGoal} loading={deleting}>
                       {deleting ? <Spinner size="small" /> : 'Delete'}
                   </Button>
                   <Button variant="primary" onClick={handleSaveGoal}>
                       {saving ? <Spinner variant='inverted' /> : 'Save'}
                   </Button>
               </SpaceBetween>
           }
       >
           <Form>
               {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
               <FormField label="Goal">
                   <Input value={goalData.goal || ''} onChange={e => handleInputChange(e, 'goal')} />
               </FormField>
               <FormField label="Goal Type">
                   <Select
                       selectedOption={goalTypes.find(option => option.value === goalData.goal_type)}
                       onChange={handleGoalTypeChange}
                       options={goalTypes}
                       placeholder="Select a goal type"
                   />
               </FormField>
               <FormField label="Target Amount">
                   <Input value={goalData.target_amount || ''} onChange={e => handleInputChange(e, 'target_amount')} />
               </FormField>
               <FormField label="Current Savings">
                   <Input readOnly value={goalData.current_savings || ''} onChange={e => handleInputChange(e, 'current_savings')} />
               </FormField>
               <FormField label="Monthly Contribution">
                   <Input value={goalData.monthly_contribution || ''} onChange={e => handleInputChange(e, 'monthly_contribution')} />
               </FormField>
               <FormField label="Start Date">
                   <Input value={goalData.start_date || ''} onChange={e => handleInputChange(e, 'start_date')} type="date" />
               </FormField>
               <FormField label="End Date">
                   <Input value={goalData.end_date || ''} onChange={e => handleInputChange(e, 'end_date')} type="date" />
               </FormField>
           </Form>
       </Modal>
   );
}

export default EditGoalModal;


