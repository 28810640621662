import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container } from '@cloudscape-design/components';
import BeneficiaryModal from './modals/BeneficiaryModal';
import EditBeneficiaryModal from './modals/EditBeneficiaryModal';

function ClientBeneficiariesTable({ clientId }) {
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

    const fetchBeneficiaries = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/clients/${clientId}/beneficiaries`)
            .then(response => {
                setBeneficiaries(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching beneficiaries:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage == null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'Add Beneficiaries for your account', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, [clientId]);

    useEffect(() => {
        fetchBeneficiaries();
    }, [fetchBeneficiaries]);

    const extractErrorMessage = (error) => {
        if (error.response.data.detail === "No Beneficiary") {
            return null;
        }
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleAddBeneficiary = (beneficiary) => {
        setBeneficiaries(prevBeneficiaries => [...prevBeneficiaries, beneficiary]);
        fetchBeneficiaries();
    };

    const handleUpdateBeneficiary = (updatedBeneficiary) => {
        setBeneficiaries(prevBeneficiaries => prevBeneficiaries.map(b => b.beneficiary_id === updatedBeneficiary.beneficiary_id ? updatedBeneficiary : b));
        fetchBeneficiaries();
    };

    const handleDeleteBeneficiary = (beneficiaryId) => {
        setBeneficiaries(prevBeneficiaries => prevBeneficiaries.filter(b => b.beneficiary_id !== beneficiaryId));
        fetchBeneficiaries();
    };

    const handleEditClick = (beneficiary) => {
        setSelectedBeneficiary(beneficiary);
        setIsEditModalOpen(true);
    };

    if (loading) return <Box>Loading beneficiaries...</Box>;

    return (
        <SpaceBetween size="l">
            <Container
                header={<Header variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setIsAddModalOpen(true)}>
                                Add Beneficiary
                            </Button>
                        </SpaceBetween>
                    }
                >Beneficiaries</Header>}
            >
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <Table
                    columnDefinitions={[
                        { header: 'Name', cell: item => item.name },
                        { header: 'Relationship', cell: item => item.relationship },
                        { header: 'Allocation Percentage', cell: item => `${item.allocation_percentage}%` },
                        {
                            header: 'Actions',
                            cell: item => (
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            )
                        }
                    ]}
                    items={beneficiaries}
                />
            </Container>
            <BeneficiaryModal 
                clientId={clientId} 
                visible={isAddModalOpen} 
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchBeneficiaries();
                }} 
                onBeneficiaryAdded={handleAddBeneficiary} 
            />
            {selectedBeneficiary && (
                <EditBeneficiaryModal 
                    clientId={clientId} 
                    beneficiary={selectedBeneficiary} 
                    visible={isEditModalOpen} 
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchBeneficiaries();
                    }} 
                    onBeneficiaryUpdated={handleUpdateBeneficiary}
                    onBeneficiaryDeleted={handleDeleteBeneficiary}
                />
            )}
        </SpaceBetween>
    );
}

export default ClientBeneficiariesTable;
