import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

function MessageEditModal({ message, visible, onClose }) {
    const [messageData, setMessageData] = useState({ ...message });
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setMessageData({ ...message });
    }, [message]);

    const handleInputChange = (e, field) => {
        setMessageData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSaveMessage = async () => {
        setSaving(true);
        try {
            const updates = {
                conversation_id: messageData.conversation_id,
                role: messageData.role,
                content: messageData.content,
                created_at: messageData.created_at,
                likes: messageData.likes,
                dislikes: messageData.dislikes,
            };
            await axiosInstance.put(`/messages/${message.message_id}`, updates);
            navigate(`/admin/conversations/${messageData.conversation_id}`, { state: { flashMessage: 'Message updated successfully!' } });
            onClose();
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteMessage = async () => {
        setDeleting(true);
        try {
            await axiosInstance.delete(`/messages/${message.message_id}`);
            navigate(`/admin/conversations/${messageData.conversation_id}`, { state: { flashMessage: 'Message deleted successfully!' } });
            onClose();
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Message"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteMessage}>
                        {deleting ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSaveMessage}>
                        {saving ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Message ID">
                    <Input value={messageData.message_id} readOnly />
                </FormField>
                <FormField label="Conversation ID">
                    <Input value={messageData.conversation_id} onChange={e => handleInputChange(e, 'conversation_id')} />
                </FormField>
                <FormField label="Role">
                    <Input value={messageData.role} onChange={e => handleInputChange(e, 'role')} />
                </FormField>
                <FormField label="Content">
                    <Input value={messageData.content} onChange={e => handleInputChange(e, 'content')} />
                </FormField>
                <FormField label="Created At">
                    <Input value={new Date(messageData.created_at).toLocaleDateString()} readOnly />
                </FormField>
                <FormField label="Likes">
                    <Input value={messageData.likes} onChange={e => handleInputChange(e, 'likes')} />
                </FormField>
                <FormField label="Dislikes">
                    <Input value={messageData.dislikes} onChange={e => handleInputChange(e, 'dislikes')} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default MessageEditModal;


