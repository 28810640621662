// src/components/fp/PlaningRouter.js
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home'; 
import { useBreadcrumbs } from '../breadcrumb/BreadcrumbProvider';
import { BreadcrumbGroup } from '@cloudscape-design/components';

const HomeRouter = () => { 
    const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
    const location = useLocation();

    React.useEffect(() => {
        if (location.pathname.startsWith("/tickers/details")) {
            const ticker = location.pathname.split("/").pop();
            setBreadcrumbs([
                { text: "Home", href: "/" },
                { text: "Ticker Details", href: location.pathname },
                { text: ticker, href: location.pathname }
            ]);
        } else {
            setBreadcrumbs([
                { text: "Home", href: "/" },
            ]);
        }
    }, [location, setBreadcrumbs]);

    return (
        <div>
            <h2>Financial Planning</h2>
            <BreadcrumbGroup items={breadcrumbs} ariaLabel="Breadcrumbs" />
            <Routes>
                <Route path="*" element={<Home />} />
            </Routes>
        </div>
    );
};

export default HomeRouter;
