import * as React from "react";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import ReactMarkdown from 'react-markdown';
import thumbnail from './thumbnail.png';  // Import the thumbnail image
import './styles.css';

const markdown = `
>
### AI-Powered Assistants for Investment Research

**Challenges for Financial Analysts:**
Financial analysts distill insights from a variety of data sources like public filings, earnings calls, market research, and economic reports. They face challenges synthesizing massive amounts of qualitative and quantitative data, learning new tools and programming languages (e.g., SQL), and working under time pressure.

**Importance of Investment Research:**
Investment research involves gathering and analyzing information to formulate hypotheses, test them with data, and understand their impact before making investment decisions. It is crucial for mitigating risks and ensuring successful investing.

**Role of AI-Powered Assistants:**
AI-powered assistants, driven by generative AI and large language models (LLMs), automate many tasks of financial and research analysts. These assistants enhance productivity by:
- Searching relevant information in databases and online.
- Conducting qualitative and quantitative analysis on structured and unstructured data.
- Enabling analysts to work faster and more accurately.

**What Are AI-Powered Assistants?**
AI-powered assistants are advanced systems that understand goals from natural language prompts, create plans, complete tasks, and orchestrate results to achieve goals. They integrate foundation models, data sources, software applications, and user interactions. 

**Amazon Bedrock:**
Leading this AI evolution is Amazon Bedrock, a fully managed service offering high-performing foundation models from top AI companies via a single API. It supports building and scaling generative AI applications with security, privacy, and responsible AI.

**Data Modalities:**
- **Unstructured Data:** Includes annual and quarterly earnings reports converted into vectors and stored in an Amazon OpenSearch Serverless vector database.
- **Structured Data:** Tabular stock data stored in Amazon S3 and queried using Amazon Athena.
- **Other Data Modalities:** Audio files of earnings calls converted to unstructured data using Amazon Textract and Amazon Transcribe.





`;

function LiveMeetingAssistant() {
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleThumbnailClick = () => {
    setIsPlaying(true);
  };

  return (
    <Container>
      <SpaceBetween direction="vertical" size="s">
        <SpaceBetween direction="vertical" size="xxs">
          <Box variant="h2">
            <Link fontSize="heading-m" href="#">
              Wealth Managment & GenAI
            </Link>
          </Box>
        </SpaceBetween>
        {isPlaying ? (
          <video width="100%" controls autoPlay>
            <source src="https://d2908q01vomqb2.cloudfront.net/artifacts/DBSBlogs/ML-16465/ML-16465-LMABlogDemoV3.mp4?_=1" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Link onClick={handleThumbnailClick}>
            <img src={thumbnail} alt="Live Meeting Assistant Demo" className="thumbnail" />

          </Link>
        )}
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </SpaceBetween>
    </Container>
  );
}

export default LiveMeetingAssistant;


