import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const PieChart = ({ chartData, chartId }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const tableauColors = [
            '#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F', '#EDC948',
            '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'
        ];

        const labels = chartData.data.map(item => item.label);
        const data = chartData.data.map(item => item.value);

        const datasets = [{
            data: data,
            backgroundColor: tableauColors.slice(0, data.length),
            borderColor: tableauColors.slice(0, data.length),
            borderWidth: 1,
        }];

        chartInstanceRef.current = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: chartData.title,
                    },
                    legend: {
                        display: true,
                        position: 'top',
                    },
                },
            },
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [chartData]);

    return <canvas  key={chartId} id={chartId} ref={chartRef} style={{ width: '400px', height: '400px' }} />;
};

export default PieChart;


