import React, { useState } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select, Textarea } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

const productTypes = [
    { label: "Investment", value: "Investment" },
    { label: "Savings", value: "Savings" },
    // Add more product types as needed
];

const severities = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
];

function AlertModal({ clientId, visible, onClose, onAlertAdded }) {
    const [alertData, setAlertData] = useState({
        client_id: clientId,
        product_id: '',
        product_type: '',
        severity: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setAlertData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e, field) => {
        setAlertData(prev => ({ ...prev, [field]: e.detail.selectedOption.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newAlert = { 
                ...alertData,
            };
            await axiosInstance.post(`/alerts`, newAlert);
            onAlertAdded(newAlert); // Call the onAlertAdded prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Alert added successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Add Alert"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Message">
                    <Textarea value={alertData.message} onChange={e => handleInputChange(e, 'message')} />
                </FormField>
                <FormField label="Severity">
                    <Select
                        selectedOption={severities.find(option => option.value === alertData.severity)}
                        onChange={e => handleSelectChange(e, 'severity')}
                        options={severities}
                        placeholder="Select severity"
                    />
                </FormField>

                <FormField label="Product ID">
                    <Input value={alertData.product_id} onChange={e => handleInputChange(e, 'product_id')} />
                </FormField>
                <FormField label="Product Type">
                    <Select
                        selectedOption={productTypes.find(option => option.value === alertData.product_type)}
                        onChange={e => handleSelectChange(e, 'product_type')}
                        options={productTypes}
                        placeholder="Select a product type"
                    />
                </FormField>
            </Form>
        </Modal>
    );
}

export default AlertModal;
