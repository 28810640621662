import React, { useState, useEffect } from 'react';
import { Button, Form, FormField, Input, Select, SpaceBetween, Spinner, Flashbar, Container, Header, ColumnLayout } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

function ConversationCreate({ setFlashMessages }) {
    const [conversationData, setConversationData] = useState({
        app_id: '',
        conversation_name: '',
        username: '',
        entity_id: '',
        entity_type: '',
    });
    const [loading, setLoading] = useState(false);
    const [localFlashMessages, setLocalFlashMessages] = useState([]);
    const [genAIApps, setGenAIApps] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGenAIApps = async () => {
            try {
                const response = await axiosInstance.get('/genai_apps');
                setGenAIApps(response.data.map(app => ({ label: app.app_name, value: app.app_id })));
            } catch (error) {
                console.error('Failed to fetch GenAI apps:', error);
            }
        };
        fetchGenAIApps();
    }, []);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setConversationData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e) => {
        setConversationData(prev => ({ ...prev, app_id: e.detail.selectedOption.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newConversation = { ...conversationData };
            await axiosInstance.post('/conversations', newConversation);
            navigate('/admin/conversations', { state: { flashMessage: 'Conversation added successfully!' } });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setLocalFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setLocalFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/admin/conversations');
    };

    return (
        <Container header={<Header>Add Conversation</Header>}>
            {localFlashMessages.length > 0 && <Flashbar items={localFlashMessages} />}
            <Form actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={handleCancel}>Cancel</Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }>
                <SpaceBetween size='xl' variant='vertical'>
                    <ColumnLayout columns={2}>
                        <FormField label="App ID">
                            <Select
                                options={genAIApps}
                                selectedOption={genAIApps.find(option => option.value === conversationData.app_id)}
                                onChange={handleSelectChange}
                                placeholder="Select an app"
                            />
                        </FormField>
                        <FormField label="Conversation Name">
                            <Input value={conversationData.conversation_name} onChange={e => handleInputChange(e, 'conversation_name')} />
                        </FormField>
                        <FormField label="Username">
                            <Input value={conversationData.username} onChange={e => handleInputChange(e, 'username')} />
                        </FormField>
                        <FormField label="Entity ID">
                            <Input value={conversationData.entity_id} onChange={e => handleInputChange(e, 'entity_id')} />
                        </FormField>
                        <FormField label="Entity Type">
                            <Input value={conversationData.entity_type} onChange={e => handleInputChange(e, 'entity_type')} />
                        </FormField>
                    </ColumnLayout>
                </SpaceBetween>
            </Form>
        </Container>
    );
}

export default ConversationCreate;


