import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container, Modal, BarChart, Spinner, ButtonDropdown, Popover } from '@cloudscape-design/components';
import PopularGoalsList from './PopularGoalsList';
import CreateGoalModal from './goal/CreateGoalModal'; // Import the create goal modal
import LoanAccountModal from './loan/LoanAccountModal'; // Import the loan account modal
import SavingsAccountModal from './savings/SavingsAccountModal'; // Import the savings account modal
import EditGoalModal from './goal/EditGoalModal'; // Import the new EditGoalModal

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

function ClientGoalsTable({ clientId }) {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flashMessages, setFlashMessages] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // New state for create modal
  const [showPopularGoalsModal, setShowPopularGoalsModal] = useState(false);
  const [isLoanModalOpen, setIsLoanModalOpen] = useState(false);
  const [selectedLoanAccount, setSelectedLoanAccount] = useState(null);
  const [isSavingsModalOpen, setIsSavingsModalOpen] = useState(false);
  const [selectedSavingsAccount, setSelectedSavingsAccount] = useState(null);
  const [sortingConfiguration, setSortingConfiguration] = useState({ sortingColumn: { sortingField: 'goal' }, isDescending: false });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();

  const fetchGoals = useCallback(() => {
    setFlashMessages([]);
    setLoading(true);
    axiosInstance.get(`/clients/${clientId}/goals`)
      .then(response => {
        const sortedGoals = response.data.sort((a, b) => a.goal.localeCompare(b.goal)); // Sorting the goals by the 'goal' field
        setGoals(sortedGoals);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching goals:', err);
        const errorMessage = extractErrorMessage(err);
        if (errorMessage == null) {
          setGoals([]);
          setFlashMessages([{ type: 'info', header: 'Not Found', content: 'Add new goals or select popular goals', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } else
          setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        setLoading(false);
      });
  }, [clientId]);

  useEffect(() => {
    fetchGoals();
  }, [fetchGoals]);

  const extractErrorMessage = (error) => {
    if (error.response && error.response.data && error.response.data.detail) {
      if (error.response.data.detail === "Client Goals not found")
        return null;
      if (error.response.data.detail.map instanceof Function) {
        const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
        return `Failed to load data: ${details}`;
      }
      return `Failed to load data: ${error.response.data.detail}`;
    } else {
      return `Failed to load data: ${error.message}`;
    }
  };

  const handleEditGoal = (goal) => {
    setSelectedGoal({ ...goal, client_id: clientId });
    setIsEditModalOpen(true);
  };

  const handleImplementGoal = async (goal) => {
    setLoading(true); // Set saving to true
    try {
      let response;
      if (goal.goal_type === "Pay off Debt") {
        response = await axiosInstance.post(`/goals/implement_loan/${goal.goal_id}`);
        setSelectedLoanAccount(response.data);
        setIsLoanModalOpen(true);
      } else if (goal.goal_type === "Emergency Fund" || goal.goal_type === "Vacation") {
        response = await axiosInstance.post(`/goals/implement_savings/${goal.goal_id}`);
        setSelectedSavingsAccount(response.data);
        setIsSavingsModalOpen(true);
      } else if (goal.goal_type === "Investment" || goal.goal_type === "Retirement" || goal.goal_type === "Major Purchase") {
        navigate(`/clients/${clientId}/investments/insert/${goal.goal_id}`);
      }
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
    } finally {
      setLoading(false); // Set saving to false
    }
  };

  const handleLoanModalClose = () => {
    setIsLoanModalOpen(false);
    setSelectedLoanAccount(null);
    fetchGoals(); // Refresh the goals list
  };

  const handleSavingsModalClose = () => {
    setIsSavingsModalOpen(false);
    setSelectedSavingsAccount(null);
    fetchGoals(); // Refresh the goals list
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedGoal(null);
  };

  const togglePopularGoalsModal = () => {
    setShowPopularGoalsModal(!showPopularGoalsModal);
    fetchGoals();
  };

  const handleCreateGoal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
  };

  const handleShowAccountModal = async (goal) => {
    setLoading(true);
    try {
      let response;
      if (goal.account_type === "LoanAccount") {
        response = await axiosInstance.get(`/loan_accounts/${goal.account_id}`);
        setSelectedLoanAccount(response.data);
        setIsLoanModalOpen(true);
      } else if (goal.account_type === "SavingsAccount") {
        response = await axiosInstance.get(`/savings_accounts/${goal.account_id}`);
        setSelectedSavingsAccount(response.data);
        setIsSavingsModalOpen(true);
      } else if (goal.account_type === "Investment") {
        navigate(`/portfolios/investments/${goal.account_id}`);
      }
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
    } finally {
      setLoading(false);
    }
  };

  const chartData = goals.filter(goal => goal.current_savings > 0);
  const shouldShowChart = chartData.length >= 2;
  if (loading) return <Box><Spinner></Spinner>Loading Goals...</Box>;

  return (
    <Container
      header={<Header variant="h3" description="Manage financial goals and implement them using different financical products."
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={togglePopularGoalsModal}>
              Show Popular Goals
            </Button>
            <Button onClick={handleCreateGoal}>
              Create Goal
            </Button>
          </SpaceBetween>
        }
      >Financial Goals</Header>}
    >
      {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
      {shouldShowChart && (
        <Box margin={{ bottom: "l" }}>
          <BarChart
            series={[
              {
                title: 'Target Amount',
                type: 'bar',
                data: chartData.map(goal => ({ x: goal.goal, y: goal.target_amount }))
              },
              {
                title: 'Current Savings',
                type: 'bar',
                data: chartData.map(goal => ({ x: goal.goal, y: goal.current_savings }))
              },
              {
                title: 'Monthly Contribution',
                type: 'bar',
                data: chartData.map(goal => ({ x: goal.goal, y: goal.monthly_contribution }))
              }
            ]}
            xDomain={chartData.map(goal => goal.goal)}
            yDomain={[0, Math.max(...chartData.map(goal => Math.max(goal.target_amount, goal.current_savings, goal.monthly_contribution)))]}
            yTitle="Amount ($)"
            ariaLabel="Financial goals chart"
            height={150}
            fixheight={false}
            size="small"
            hideFilter={true}
            hideLegend={true}
            suppressAnimation={true}
            statusType="finished"
            emphasizeBaselineAxis={false}
            variant="container"

            
          />
        </Box>
      )}
      <Table
        columnDefinitions={[
          { header: 'Goal', cell: item => item.goal, sortingField: 'goal' },
          { header: 'Target', cell: item => formatCurrency(item.target_amount) },
          { header: 'Current $', cell: item => formatCurrency(item.current_savings) },
          {
            header: 'Actions',
            cell: item => (
              <SpaceBetween direction="horizontal" size="xs">
                  <Button iconName="edit" ariaLabel="Edit this Goal" variant="normal" onClick={() => handleEditGoal(item)} />
                {item.account_id ? (
                    <Button iconName="zoom-in" ariaLabel={item.account_type === "Investment" ? "Investment" : item.account_type === "SavingsAccount" ? "Savings Account" : "Loan Account"}
                     variant="normal" onClick={() => handleShowAccountModal(item)} />
                ) : (
                    <Button iconName="add-plus" ariaLabel="Implement" variant="normal" onClick={() => handleImplementGoal(item)} />
                )}
              </SpaceBetween>
            )
          }
        ]}
        items={goals}
        sortingColumn={sortingConfiguration.sortingColumn}
        sortingDescending={sortingConfiguration.isDescending}
        onSortingChange={({ detail: { sortingColumn, isDescending } }) =>
          setSortingConfiguration({ sortingColumn, isDescending })
        }
        variant="container"
          stickyHeader={true}
          resizableColumns={false}
          wrapLines={false}
      />

      <EditGoalModal
        visible={isEditModalOpen}
        onClose={handleEditModalClose}
        goal={selectedGoal}
        clientId={clientId}
        onGoalUpdated={fetchGoals}
      />

      <CreateGoalModal
        clientId={clientId}
        visible={isCreateModalOpen}
        onClose={handleCreateModalClose}
        onGoalCreated={fetchGoals}
      />

      {showPopularGoalsModal && (
        <Modal
          onDismiss={togglePopularGoalsModal}
          visible={showPopularGoalsModal}
          closeAriaLabel="Close modal"
          header="Popular Goals"
          footer={
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={togglePopularGoalsModal}>Close</Button>
            </SpaceBetween>
          }
        >
          <PopularGoalsList ageGroup="30s" clientId={clientId} />
        </Modal>
      )}

      {isLoanModalOpen && (
        <LoanAccountModal
          visible={isLoanModalOpen}
          onClose={handleLoanModalClose}
          clientId={clientId}
          loanAccount={selectedLoanAccount}
        />
      )}

      {isSavingsModalOpen && (
        <SavingsAccountModal
          visible={isSavingsModalOpen}
          onClose={handleSavingsModalClose}
          clientId={clientId}
          savingsAccount={selectedSavingsAccount}
        />
      )}
    </Container>
  );
}

export default ClientGoalsTable;


