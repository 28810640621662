import React from 'react';
import ChatWidget from '../../chat_widget/ChatWidget'; // Adjust the path as necessary
import {  Header,  Container } from '@cloudscape-design/components';

import './AnalystChat.css'; // Import the CSS for the page if any

const AnalystChat = () => {
  
  
  return (

    <Container
    header={<Header variant="h3"
    >MarketChat </Header>}
    >
      <ChatWidget app_name="AnalystPortal" entity_type="Markets"
          context="Yes"
          entity_id="Markets"
      />
    </Container>
  );
};

export default AnalystChat;
