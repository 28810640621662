import React, { useState, useEffect } from 'react';
import { Button, Table, SpaceBetween, Spinner, Flashbar, Container, Header, ColumnLayout, Box } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';

function ConversationEdit({ setFlashMessages }) {
    const { conversation_id } = useParams();
    const [conversationData, setConversationData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [localFlashMessages, setLocalFlashMessages] = useState([]);
    const navigate = useNavigate();

    const ValueWithLabel = ({ label, children }) => (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );


    useEffect(() => {
        const fetchConversation = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/conversations/${conversation_id}`);
                console.log('Raw API Response:', response.data);

                const { messages, ...conversation } = response.data;

                setConversationData(prev => ({ ...prev, ...conversation }));
                setMessages(messages);
                console.log('Updated conversationData:', conversation);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchConversation();
    }, [conversation_id]);

    // Debugging: Check if React re-renders with updated state
    useEffect(() => {
        console.log('Rendering with updated conversationData:', conversationData);
    }, [conversationData]);

    return (
        <Container header={<Header>Conversation Details</Header>}>
            {localFlashMessages.length > 0 && <Flashbar items={localFlashMessages} />}
            <h2>{conversationData?.app_name}</h2>
            <SpaceBetween size="l">
            <ColumnLayout columns={3} variant="text-grid">
                <ValueWithLabel label="App Name">
                    {loading ? <Spinner /> : conversationData?.conversation.app_name ?? 'N/A'}
                </ValueWithLabel>
                <ValueWithLabel label="Conversation Name">
                    {loading ? <Spinner /> : conversationData?.conversation.conversation_name ?? 'N/A'}
                </ValueWithLabel>
                <ValueWithLabel label="Username">
                    {loading ? <Spinner /> : conversationData?.conversation.username ?? 'N/A'}
                </ValueWithLabel>
                <ValueWithLabel label="Entity ID">
                    {loading ? <Spinner /> : conversationData?.conversation.entity_id ?? 'N/A'}
                </ValueWithLabel>
                <ValueWithLabel label="Entity Type">
                    {loading ? <Spinner /> : conversationData?.conversation.entity_type ?? 'N/A'}
                </ValueWithLabel>
                <ValueWithLabel label="Last Updated">
                    {loading
                        ? <Spinner />
                        : conversationData?.conversation.updated_dt
                            ? new Date(conversationData.conversation.updated_dt).toLocaleString()
                            : 'N/A'
                    }
                </ValueWithLabel>
            </ColumnLayout>

            <Box margin={{ top: 'l' }}>
                <Header variant="h2">Conversation Messages</Header>
            <Table
                columnDefinitions={[
                    { header: 'Role', cell: item => item.role },
                    {
                        header: 'Prompt',
                        cell: item => (
                            <div style={{
                                maxWidth: '700px',
                                whiteSpace: 'wrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                {item.content.map(c => c.text).join(", ")}
                            </div>
                        ),
                        sortingField: 'conversation_name'
                    },
                    { header: 'Likes', cell: item => item.likes ?? 0 },
                    { header: 'Dislikes', cell: item => item.dislikes ?? 0 },
                    {
                        header: 'Updated',
                        cell: item => item.updated_dt ? new Date(item.updated_dt).toLocaleString() : 'N/A',
                        sortingField: 'updated_dt'
                    }
                ]}
                items={messages ?? []}
                loadingText="Loading messages..."
                empty={<p>No messages available</p>}
            />
            </Box>
             </SpaceBetween>


        </Container>
    );

}

export default ConversationEdit;


