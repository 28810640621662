import React, { useState } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../../../services/axiosConfig';

const userRoles = [
   { label: "AppAdmin", value: "AppAdmin" },
   { label: "WealthAdmin", value: "WealthAdmin" },
   { label: "Analyst", value: "Analyst" },
   { label: "Client", value: "Client" }
   // Add more roles as needed
];

function CreateAuthUserModal({ visible, onClose, onAuthUserAdded }) {
   const [authUserData, setAuthUserData] = useState({
       user_id: '',
       user_password: '',
       client_id: '',
       email: '',
       user_role: ''
   });
   const [loading, setLoading] = useState(false);
   const [flashMessages, setFlashMessages] = useState([]);

   const extractErrorMessage = (error) => {
       if (error.response && error.response.data && error.response.data.detail) {
           if (Array.isArray(error.response.data.detail)) {
               const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
               return `Failed to load data: ${details}`;
           }
           return `Failed to load data: ${error.response.data.detail}`;
       } else {
           return `Failed to load data: ${error.message}`;
       }
   };

   const handleInputChange = (e, field) => {
       setAuthUserData(prev => ({ ...prev, [field]: e.detail.value }));
   };

   const handleSelectChange = (e, field) => {
       setAuthUserData(prev => ({ ...prev, [field]: e.detail.selectedOption.value }));
   };

   const handleSubmit = async () => {
       setLoading(true);
       try {
           const newAuthUser = { ...authUserData };
           await axiosInstance.post(`/auth_users`, newAuthUser);
           onAuthUserAdded(newAuthUser); // Call the onAuthUserAdded prop to update the parent component
           setFlashMessages([{ type: 'success', header: 'Success', content: 'Auth User added successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           onClose(); // Close the modal
       } catch (error) {
           const errorMessage = extractErrorMessage(error);
           setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
       } finally {
           setLoading(false);
       }
   };

   return (
       <Modal
           visible={visible}
           onDismiss={onClose}
           header="Add Auth User"
           footer={
               <SpaceBetween direction="horizontal" size="xs">
                   <Button variant="link" onClick={onClose}>Cancel</Button>
                   <Button onClick={handleSubmit}>
                       {loading ? <Spinner /> : 'Insert'}
                   </Button>
               </SpaceBetween>
           }
       >
           <Form>
               {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
               <FormField label="User ID">
                   <Input value={authUserData.user_id} onChange={e => handleInputChange(e, 'user_id')} />
               </FormField>
               <FormField label="Password">
                   <Input value={authUserData.user_password} onChange={e => handleInputChange(e, 'user_password')} />
               </FormField>
               <FormField label="Client ID">
                   <Input value={authUserData.client_id} onChange={e => handleInputChange(e, 'client_id')} />
               </FormField>
               <FormField label="Email">
                   <Input value={authUserData.email} onChange={e => handleInputChange(e, 'email')} />
               </FormField>
               <FormField label="User Role">
                   <Select
                       selectedOption={userRoles.find(option => option.value === authUserData.user_role)}
                       onChange={e => handleSelectChange(e, 'user_role')}
                       options={userRoles}
                       placeholder="Select user role"
                   />
               </FormField>
           </Form>
       </Modal>
   );
}

export default CreateAuthUserModal;


