import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import { Button, FormField, Form, Input, Container, Header, SpaceBetween, Select, ColumnLayout, Flashbar, Box, Spinner } from '@cloudscape-design/components';
import Chatbot from './Chatbot';
import { addDays } from 'date-fns';

function ClientInsert() {
   const [clientData, setClientData] = useState({
       name: '',
       gender: '',
       email: '',
       date_of_birth: '',
       marital_status: 'Single',
       risk_tolerance: 'Moderate',
       registration_date: addDays(new Date(), -365).toISOString().split('T')[0], // Default to one year from today
       investment_horizon: 'Short-Term'
   });
   const [loading, setLoading] = useState(false);
   const [flashMessages, setFlashMessages] = useState([]);
   const navigate = useNavigate(); // Initialize useNavigate

   const handleSubmit = async (event) => {
       console.log('Handle submit triggered');
       event.preventDefault();
       setLoading(true);

       try {
           const response = await axiosInstance.post('/clients/', clientData);
           console.log('Client added:', response.data);
           setFlashMessages([{ type: 'success', header: 'Success', content: 'Client added successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           setLoading(false);

           // Navigate to the edit page with the client ID
           navigate(`/clients/edit/${response.data.client_id}`, { state: { flashMessage: "Client added successfully!, Enter budget to start financial planning." } });
       } catch (error) {
           const errorMessage = formatErrorMessage(error.response?.data?.detail || 'Failed to add client');
           console.error('Error adding client:', error);
           setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           setLoading(false);
       }
   };

   const handleInputChange = ({ detail: { value } }, name) => {
       setClientData(prevData => ({
           ...prevData,
           [name]: value
       }));
   };

   const handleSelectChange = (event, name) => {
       setClientData(prevData => ({
           ...prevData,
           [name]: event.detail.selectedOption.value
       }));
   };

   const formatErrorMessage = (error) => {
       if (Array.isArray(error)) {
           return error.map(err => `${err.loc[1]} ${err.msg}`).join(', ');
       } else if (typeof error === 'object' && error !== null) {
           return JSON.stringify(error);
       }
       return error;
   };

   if (loading) return <Box textAlign="center"><Spinner />Loading...</Box>;

   return (
       <form onSubmit={handleSubmit}>
           <Form
               actions={
                   <SpaceBetween direction="horizontal" size="xs">
                       <Button formAction="none" href="/clients" variant="link">
                           Cancel
                       </Button>
                       <Button variant="primary">Submit</Button>
                   </SpaceBetween>
               }
           >
               <Container header={<Header variant="h1">Add New Client</Header>}>
                   {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                   <SpaceBetween size="l" direction="vertical">
                       <ColumnLayout columns={3}>
                           <FormField label="Name" stretch={true}>
                               <Input
                                   value={clientData.name}
                                   onChange={(event) => handleInputChange(event, 'name')}
                                   name="name"
                               />
                           </FormField>
                           <FormField label="Gender" stretch={true}>
                               <Select
                                   selectedOption={{ label: clientData.gender, value: clientData.gender }}
                                   onChange={(event) => handleSelectChange(event, 'gender')}
                                   options={[
                                       { label: "Male", value: "Male" },
                                       { label: "Female", value: "Female" },
                                       { label: "Non-Binary", value: "Non-Binary" },
                                       { label: "Prefer not to say", value: "Prefer not to say" },
                                       { label: "Other", value: "Other" },
                                   ]}
                               />
                           </FormField>
                           <FormField label="Email" stretch={true}>
                               <Input
                                   value={clientData.email}
                                   onChange={(event) => handleInputChange(event, 'email')}
                                   name="email"
                                   type="email"
                               />
                           </FormField>
                           <FormField label="Date of Birth" stretch={true}>
                               <Input
                                   value={clientData.date_of_birth}
                                   onChange={(event) => handleInputChange(event, 'date_of_birth')}
                                   name="date_of_birth"
                                   type="date"
                               />
                           </FormField>
                           <FormField label="Marital Status" stretch={true}>
                               <Select
                                   selectedOption={{ label: clientData.marital_status, value: clientData.marital_status }}
                                   onChange={(event) => handleSelectChange(event, 'marital_status')}
                                   options={[
                                       { label: "Married", value: "Married" },
                                       { label: "Single", value: "Single" },
                                   ]}
                               />
                           </FormField>
                           <FormField label="Risk Tolerance" stretch={true}>
                               <Select
                                   selectedOption={{ label: clientData.risk_tolerance, value: clientData.risk_tolerance }}
                                   onChange={(event) => handleSelectChange(event, 'risk_tolerance')}
                                   options={[
                                       { label: "Low", value: "Low" },
                                       { label: "Moderate", value: "Moderate" },
                                       { label: "High", value: "High" },
                                   ]}
                               />
                           </FormField>
                           <FormField label="Investment Horizon" stretch={true}>
                               <Select
                                   selectedOption={{ label: clientData.investment_horizon, value: clientData.investment_horizon }}
                                   onChange={(event) => handleSelectChange(event, 'investment_horizon')}
                                   options={[
                                       { label: "Short-Term", value: "Short-Term" },
                                       { label: "Medium-Term", value: "Medium-Term" },
                                       { label: "Long-Term", value: "Long-Term" },
                                   ]}
                               />
                           </FormField>
                           <FormField label="Registration Date" stretch={true}>
                               <Input
                                   value={clientData.registration_date}
                                   onChange={(event) => handleInputChange(event, 'registration_date')}
                                   name="registration_date"
                                   type="date"
                               />
                           </FormField>
                       </ColumnLayout>
                   </SpaceBetween>
                   <Chatbot
                       contextData=""
                       title="Client Management"
                       entityKey=""
                   />
               </Container>
           </Form>
       </form>
   );
}

export default ClientInsert;


