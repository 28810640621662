import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SideNavigation, Icon } from '@cloudscape-design/components';

function AdminLeftMenu({ isOpen, toggleDrawer }) {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        toggleDrawer(false);
    };

    const items = [
        { type: 'link', text: 'Conversations', href: '/admin/conversations', onClick: () => handleNavigation('/admin/conversations') },
        { type: 'link', text: 'User Management',  href: '/admin/users', onClick: () => handleNavigation('/admin/users') }

       


    ];

    return (
        <SideNavigation
            items={items}
            activeHref={window.location.pathname}
            onFollow={event => {
                event.preventDefault(); // Prevent link following if you handle routing programmatically
                handleNavigation(event.detail.href);
            }}
            onToggle={() => toggleDrawer(false)}
            open={isOpen}
        />
    );
}

export default AdminLeftMenu;
