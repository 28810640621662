import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select, Table, Alert, Header, Pagination, ColumnLayout } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';
import { LoanType } from './LoanAccount';

const loanTypes = [
    { label: "Student Loan", value: LoanType.STUDENT_LOAN },
    { label: "Personal", value: LoanType.PERSONAL },
    { label: "Mortgage", value: LoanType.MORTGAGE },
    { label: "Auto", value: LoanType.AUTO },
    { label: "Credit Card", value: LoanType.CREDIT_CARD },
    { label: LoanType.PAY_OFF_DEBT, value: LoanType.PAY_OFF_DEBT },
    { label: "Other", value: LoanType.OTHER }
];

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

function LoanAccountModal({ visible, onClose, clientId, loanAccount }) {
    const [loanAmount, setLoanAmount] = useState(loanAccount?.loan_amount || '');
    const [balance, setBalance] = useState(loanAccount?.balance || '');
    const [interestRate, setInterestRate] = useState(loanAccount?.interest_rate || '');
    const [loanPeriodYears, setLoanPeriodYears] = useState(loanAccount?.loan_period_years || '');
    const [loanType, setLoanType] = useState(loanAccount?.loan_type || '');
    const [loading, setLoading] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transactionsLoading, setTransactionsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5); // Adjust the page size as needed

    useEffect(() => {
        if (loanAccount) {
            setLoanAmount(loanAccount.loan_amount);
            setBalance(loanAccount.balance);
            setInterestRate(loanAccount.interest_rate);
            setLoanPeriodYears(loanAccount.loan_period_years);
            setLoanType(loanAccount.loan_type);

            // Fetch loan transactions
            fetchLoanTransactions(loanAccount.account_id);
        }
    }, [loanAccount]);

    const handleSelectChange = (event) => {
        setLoanType(event.detail.selectedOption.value);
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleSubmit = async () => {
        setFlashMessages([]);
        setLoading(true);
        try {
            const updatedLoanAccount = {
                client_id: clientId,
                loan_amount: loanAmount,
                balance: balance,
                interest_rate: interestRate,
                loan_period_years: loanPeriodYears,
                loan_type: loanType,
                creation_date: loanAccount.creation_date
            };
            await axiosInstance.put(`/loan_accounts/${loanAccount.account_id}`, updatedLoanAccount);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Loan account updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            //onClose();
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handlePostPayments = async () => {
        setFlashMessages([]);
        setPaymentLoading(true);
        try {
            const response = await axiosInstance.post(`/loan_accounts/${loanAccount.account_id}/post_monthly_payments`);
            const updatedLoanAccount = response.data;
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Monthly payments posted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setPaymentLoading(false);
        }
    };

    const fetchLoanTransactions = async (accountId) => {
        setTransactionsLoading(true);
        try {
            const response = await axiosInstance.get(`/loan_accounts/${accountId}/transactions`);
            const sortedTransactions = response.data.sort((a, b) => new Date(b.transaction_date) - new Date(a.transaction_date));
            setTransactions(sortedTransactions);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // If the transactions are not found, just return without setting any error messages
                setTransactions([]);
            } else {
                const errorMessage = extractErrorMessage(error);
                setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            }
        } finally {
            setTransactionsLoading(false);
        }
    };

    const handlePageChange = ({ detail: { currentPageIndex } }) => {
        setCurrentPage(currentPageIndex);
    };

    const paginatedTransactions = transactions.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Loan Account"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Save'}
                    </Button>
                    <Button onClick={handlePostPayments} variant="primary">
                        {paymentLoading ? <Spinner /> : 'Post Payments'}
                    </Button>
                </SpaceBetween>
            }
        >
            <SpaceBetween direction="vertical" size="xs">
                <Form>
                    <Alert type="info">
                        You can edit details of the loan and post payments.
                    </Alert>
                    {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                    <ColumnLayout columns={3}>
                        <FormField label="Loan Amount">
                            <Input value={loanAmount} onChange={e => setLoanAmount(e.detail.value)} />
                        </FormField>
                        <FormField label="Balance">
                            <Input value={balance} onChange={e => setBalance(e.detail.value)} />
                        </FormField>
                        <FormField label="Interest Rate">
                            <Input value={interestRate} onChange={e => setInterestRate(e.detail.value)} />
                        </FormField>
                        <FormField label="Loan Period (Years)">
                            <Input value={loanPeriodYears} onChange={e => setLoanPeriodYears(e.detail.value)} />
                        </FormField>
                        <FormField label="Loan Type">
                            <Select
                                selectedOption={loanTypes.find(option => option.value === loanType)}
                                onChange={handleSelectChange}
                                options={loanTypes}
                                placeholder="Select a loan type"
                            />
                        </FormField>
                    </ColumnLayout>
                </Form>

                {transactionsLoading ? (
                    <Spinner />
                ) : transactions.length > 0 && (
                    <>
                        <Table
                            columnDefinitions={[
                                { id: 'transaction_date', header: 'Date', cell: item => item.transaction_date },
                                { id: 'transaction_type', header: 'Description', cell: item => item.transaction_type },
                                { id: 'amount', header: 'Amount', cell: item => formatCurrency(item.amount) }
                            ]}
                            items={paginatedTransactions}
                            header={<Header>Transactions</Header>}
                        />
                        <Pagination
                            currentPageIndex={currentPage}
                            pagesCount={Math.ceil(transactions.length / pageSize)}
                            onChange={handlePageChange}
                        />
                    </>
                )}
            </SpaceBetween>
        </Modal>
    );
}

export default LoanAccountModal;


