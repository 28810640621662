import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import {
    Button, Select, FormField, Textarea, Input, Container, Header, SpaceBetween, 
    Form, DatePicker, Flashbar, Box, Spinner
} from '@cloudscape-design/components';
import AsyncSelect from 'react-select/async';
import { useBreadcrumbs } from './breadcrumb/BreadcrumbProvider';


function InvestmentInsert() {
    const { portfolioId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const { setBreadcrumbs } = useBreadcrumbs();
    const [submitting, setSubmitting] = useState(false); // New state to track form submission
    const [investmentData, setInvestmentData] = useState({
        investment_id: '',
        portfolio_id: portfolioId || '',
        investment_type: '',
        description: '',
        investment_value: '',
        optimization_technique: '',
        start_date: '',
        end_date: '',
        tickers: [] // Ensure tickers is always an array
    });

    // Function to generate a random 6-digit number
    const generateRandomId = () => {
        return Math.floor(1000 + Math.random() * 9000).toString();
    };

    useEffect(() => {
        // Generate the initial investment_id when the component mounts
        if (portfolioId) {
            setInvestmentData(prevData => ({
                ...prevData,
                investment_id: `${portfolioId}_${generateRandomId()}`
            }));
        }
        setBreadcrumbs([
            { text: "Portfolio", href: `/portfolios/${portfolioId}` },
            { text: `New Investment` }
        ]);

    }, [portfolioId, setBreadcrumbs]);

    const handleInputChange = ({ detail }, field) => {
        const value = detail.value;
        setInvestmentData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleSelectChange = ({ detail }, field) => {
        setInvestmentData(prevData => ({
            ...prevData,
            [field]: detail.selectedOption.value
        }));
    };

    const handleDateChange = (field, value) => {
        setInvestmentData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleTickersChange = (selectedOptions) => {
        setInvestmentData(prevData => ({
            ...prevData,
            tickers: selectedOptions.map(option => option.value)
        }));
    };

    const loadTickerOptions = async (inputValue, callback) => {
        if (inputValue.length < 3) {
            return callback([]);
        }
        try {
            const response = await axiosInstance.get(`/tickers/search?query=${inputValue}`);
            const options = response.data.map(ticker => ({ label: ticker, value: ticker }));
            callback(options);
        } catch (error) {
            console.error('Error fetching tickers:', error);
            callback([]);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setSubmitting(true); // Disable the submit button

        try {
            const response = await axiosInstance.post('/investments/', investmentData);
            setFlashMessages([{
                header: "Success",
                content: "Investment added successfully!",
                type: "success",
                dismissible: true,
                onDismiss: () => setFlashMessages([])
            }]);
            navigate(`/portfolios/${portfolioId}`);
        } catch (error) {
            console.error('Error adding investment:', error);
            const errorMessage = error.response?.data?.detail || error.message || 'Failed to add investment.';
            setFlashMessages([{
                header: "Error",
                content: `Failed to add investment: ${errorMessage}`,
                type: "error",
                dismissible: true,
                onDismiss: () => setFlashMessages([])
            }]);
        }  finally {
            setLoading(false);
            setSubmitting(false); // Re-enable the submit button
        }

    };
    if (loading) return <Box textAlign="center"><Spinner /></Box>;


    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link">
                            Cancel
                        </Button>
                        <Button variant="primary" disabled={submitting}>Submit</Button>
                    </SpaceBetween>
                }
            >
                <Container header={<Header variant="h1">Add New Investment</Header>}>
                    <Flashbar items={flashMessages} />
                    <SpaceBetween size="l" direction="vertical">
                        <FormField label="Investment ID" stretch={true}>
                            <Input
                                value={investmentData.investment_id}
                                onChange={(event) => handleInputChange(event, 'investment_id')}
                            />
                        </FormField>

                        <FormField label="Investment Type" stretch={true}>
                            <Select
                                selectedOption={{ label: investmentData.investment_type, value: investmentData.investment_type }}
                                onChange={(event) => handleSelectChange(event, 'investment_type')}
                                options={[
                                    { label: "Stocks", value: "Stocks" },
                                    { label: "Bonds", value: "Bonds" },
                                    { label: "Real Estate", value: "Real Estate" },
                                    { label: "Cash and equivalents", value: "Cash and equivalents" },
                                ]}
                            />
                        </FormField>

                        <FormField label="Investment Value" stretch={true}>
                            <Input
                                value={investmentData.investment_value}
                                onChange={(event) => handleInputChange(event, 'investment_value')}
                            />
                        </FormField>

                        {(investmentData.investment_type === "Stocks" || investmentData.investment_type === "Bonds") && (
                            <FormField label="Tickers" stretch={true}>
                                <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    loadOptions={loadTickerOptions}
                                    onChange={handleTickersChange}
                                    defaultOptions
                                    placeholder="Search tickers"
                                />
                            </FormField>
                        )}

                        <FormField label="Optimization Technique" stretch={true}>
                            <Select
                                selectedOption={{ label: investmentData.optimization_technique, value: investmentData.optimization_technique }}
                                onChange={(event) => handleSelectChange(event, 'optimization_technique')}
                                options={[
                                    { label: "Maximum Sharpe Ratio", value: "Maximum Sharpe Ratio" },
                                    { label: "Minimum Volatility", value: "Minimum Volatility" },
                                    { label: "Efficient Risk", value: "Efficient Risk" },
                                    { label: "Efficient Return", value: "Efficient Return" },
                                ]}
                            />
                        </FormField>

                        <FormField label="Start Date" stretch={true}>
                            <DatePicker
                                onChange={({ detail }) => handleDateChange('start_date', detail.value)}
                                value={investmentData.start_date}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose start date" + (selectedDate ? `, selected date is ${selectedDate}` : "")
                                }
                                placeholder="YYYY/MM/DD"
                            />
                        </FormField>

                        <FormField label="End Date" stretch={true}>
                            <DatePicker
                                onChange={({ detail }) => handleDateChange('end_date', detail.value)}
                                value={investmentData.end_date}
                                openCalendarAriaLabel={selectedDate =>
                                    "Choose end date" + (selectedDate ? `, selected date is ${selectedDate}` : "")
                                }
                                placeholder="YYYY/MM/DD"
                            />
                        </FormField>

                        <FormField label="Description" stretch={true}>
                            <Textarea
                                value={investmentData.description}
                                onChange={(event) => handleInputChange(event, 'description')}
                            />
                        </FormField>

                        <Input
                            type="hidden"
                            value={investmentData.portfolio_id}
                        />

                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    );
}

export default InvestmentInsert;
