import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

function UserPromptEditModal({ userPrompt, visible, onClose }) {
    const [userPromptData, setUserPromptData] = useState({ ...userPrompt });
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const [genAIApps, setGenAIApps] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGenAIApps = async () => {
            try {
                const response = await axiosInstance.get('/genai_apps');
                setGenAIApps(response.data.map(app => ({ label: app.app_name, value: app.app_id })));
            } catch (error) {
                console.error('Failed to fetch GenAI apps:', error);
            }
        };
        fetchGenAIApps();
    }, []);

    useEffect(() => {
        setUserPromptData({ ...userPrompt });
    }, [userPrompt]);

    const handleInputChange = (e, field) => {
        setUserPromptData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e) => {
        setUserPromptData(prev => ({ ...prev, app_id: e.detail.selectedOption.value }));
    };

    const handleSaveUserPrompt = async () => {
        setSaving(true);
        try {
            const updates = {
                app_id: userPromptData.app_id,
                prompt_text: userPromptData.prompt_text,
                icon_name: userPromptData.icon_name,
            };
            await axiosInstance.put(`/user_prompts/${userPrompt.prompt_id}`, updates);
            navigate('/admin/user_prompts', { state: { flashMessage: 'User Prompt updated successfully!' } });
            onClose();
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteUserPrompt = async () => {
        setDeleting(true);
        try {
            await axiosInstance.delete(`/user_prompts/${userPrompt.prompt_id}`);
            navigate('/admin/user_prompts', { state: { flashMessage: 'User Prompt deleted successfully!' } });
            onClose();

        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit User Prompt"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteUserPrompt}>
                        {deleting ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSaveUserPrompt} disabled={!userPromptData.app_id || !userPromptData.prompt_text.trim() || !userPromptData.icon_name.trim()}>
                        {saving ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="App">
                    <Select
                        options={genAIApps}
                        selectedOption={genAIApps.find(option => option.value === userPromptData.app_id)}
                        onChange={handleSelectChange}
                        placeholder="Select an app"
                    />
                </FormField>
                <FormField label="Prompt Text">
                    <Input value={userPromptData.prompt_text} onChange={e => handleInputChange(e, 'prompt_text')} />
                </FormField>
                <FormField label="Icon Name">
                    <Input value={userPromptData.icon_name} onChange={e => handleInputChange(e, 'icon_name')} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default UserPromptEditModal;


