import React, { useState, useEffect } from 'react';
import { Box, ExpandableSection, Container, Header, Flashbar, SpaceBetween, Spinner, Table, Pagination } from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';
import ChatWidget from '../../chat_widget/ChatWidget';
import { useParams } from 'react-router-dom';


function SectorDetail() {
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [data, setData] = useState([]);
    const { sector } = useParams();
    const navigate = useNavigate();
    const endpoint = `sectors/${sector}`;
    const title = sector.charAt(0).toUpperCase() + sector.slice(1);

 
    // Fetch data
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get(endpoint);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                setFlashMessages([{ header: "Error", content: `Failed to fetch data for ${title}.`, type: "error", dismissible: true }]);
                setLoading(false);
            }
        }
        fetchData();
    }, [endpoint, title]);

    // Ensure each data property is an array
    const industries = Array.isArray(data.industries) ? data.industries : [];
    const largeEquities = Array.isArray(data.large_equities) ? data.large_equities : [];
    const etfOpportunities = Array.isArray(data.etf_opportunities) ? data.etf_opportunities : [];
    const fundOpportunities = Array.isArray(data.fund_opportunities) ? data.fund_opportunities : [];

    // Initialize useCollection for each dataset
    const industriesCollection = useCollection(industries, { pagination: { pageSize: 10 } });
    const largeEquitiesCollection = useCollection(largeEquities, { pagination: { pageSize: 10 } });
    const etfOpportunitiesCollection = useCollection(etfOpportunities, { pagination: { pageSize: 10 } });
    const fundOpportunitiesCollection = useCollection(fundOpportunities, { pagination: { pageSize: 10 } });

    const renderTable = (collection, columns) => (
        <>
            <Table
                {...collection.collectionProps}
                columnDefinitions={columns}
                items={collection.items}
            />
            <Pagination {...collection.paginationProps} />
        </>
    );
     // Navigation method
    const handleIndustryClick = (industryName) => {
        const formattedIndustryName = industryName.toLowerCase().replace(/ /g, '-');
        navigate(`/analyst/industry/${formattedIndustryName}`);
    };
    

    if (loading) return <Box textAlign="center"><Spinner /></Box>;

    return (
        <Container header={<Header variant="h3">{title}</Header>}>
            <Flashbar items={flashMessages} />
            <ExpandableSection headerText='Bedrock' >
               <ChatWidget app_name="AnalystPortal" entity_type="Sector"
                   context={JSON.stringify(data)}
                   entity_id={title}
               />
               </ExpandableSection>

            <SpaceBetween size="l" direction="vertical">
                {/* Industries Table */}
                <Box header={<Header variant="h1">Industries</Header>}>
                    {renderTable(industriesCollection, [
                        {
                        header: 'Industry',
                        cell: item => (
                            item.Industry === 'All Industries' ? (
                            <span>{item.Industry}</span>
                            ) : (
                            <span
                                onClick={() => handleIndustryClick(item.Industry)}
                                style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                            >
                                {item.Industry}
                            </span>
                            )
                        )
                        },

                        { header: 'Industry', cell: item => item.Industry || 'N/A' },
                        { header: 'Market Weight', cell: item => item['Market Weight'] || 'N/A' },
                        { header: 'YTD Return', cell: item => item['Unnamed: 3'] },
                    ])}
                </Box>

                {/* Large Equities Table */}
                {console.log('{ console.log(largeEquitiesCollection)}')}
                { console.log(largeEquities)}
                <Box header={<Header variant="h4">Large Equities</Header>}>
                    <h3>Large Equities</h3>

                    {renderTable(largeEquitiesCollection, [
                         { header: 'Name', cell: item => item.Name || 'N/A' },
                         { header: 'Last Price', cell: item => `$${item['Last Price'] || 'N/A'}` },
                         { header: 'Day Change %', cell: item => item['Day Change %'] || 'N/A' },
                         { header: 'YTD Return', cell: item => item['YTD Return'] || 'N/A' },
                         { header: 'Market Cap', cell: item => item['Market Cap'] || 'N/A' },
                         { header: 'Market Weight', cell: item => item['Market Weight'] || 'N/A' },
                         { header: '1Y Target Est.', cell: item => item['1Y Target Est.'] || 'N/A' },
                         { header: 'Avg. Analyst Rating', cell: item => item['Avg. Analyst Rating'] || 'N/A' },
                    ])}
                </Box>

                {/* ETF Opportunities Table */}
                {console.log('ETF Opportunities Table')}
                { console.log(etfOpportunities)}

                <Box header={<Header variant="h4">ETF Opportunities</Header>}>
                    <h3>ETF Opportunities</h3>

                    {renderTable(etfOpportunitiesCollection, [               
                         { header: 'Name', cell: item => item.Name || 'N/A' },
                         { header: 'Last Price', cell: item => `$${item['Last Price'] || item.Price}` },
                         { header: 'YTD Return', cell: item => item['YTD Return'] || 'N/A' },
                         { header: 'Net Assets', cell: item => item['Net Assets'] || 'N/A' },
                         { header: 'Expense Ratio', cell: item => item['Expense Ratio'] || 'N/A' }
                    ])}
                </Box>

                {/* Fund Opportunities Table */}
                {console.log('Fund Opportunities Table')}
                { console.log(fundOpportunities)}
                <Box header={<Header variant="h4">Fund Opportunities</Header>}>
                    <h3>Fund Opportunities</h3>

                    {renderTable(fundOpportunitiesCollection, [
                            { header: 'Name', cell: item => item.Name || 'N/A' },
                            { header: 'Last Price', cell: item => `$${item['Last Price'] || item.Price}` },
                            { header: 'YTD Return', cell: item => item['YTD Return'] || 'N/A' },
                            { header: 'Net Assets', cell: item => item['Net Assets'] || 'N/A' },
                            { header: 'Expense Ratio', cell: item => item['Expense Ratio'] || 'N/A' }
                        ])}
                </Box>
            </SpaceBetween>
        </Container>
    );
}

export default SectorDetail;
