import React, { useState, useEffect } from 'react';

import { Box, RadioGroup, Button, ExpandableSection, Container, Header, Flashbar, SpaceBetween, Spinner, Table, TextFilter, Pagination } from '@cloudscape-design/components';

import { useCollection } from '@cloudscape-design/collection-hooks';

import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';
import ChatWidget from '../../chat_widget/ChatWidget';


function ETFsTable({ title, endpoint }) {

   const [loading, setLoading] = useState(true);

   const [flashMessages, setFlashMessages] = useState([]);

   const [selectedTicker, setSelectedTicker] = useState(null);

   const [data, setData] = useState([]);
   const navigate = useNavigate();

   useEffect(() => {

       async function fetchData() {

           try {

               const response = await axiosInstance.get(endpoint);
               console.log(response.data);
               setData(response.data);

               setLoading(false);

           } catch (error) {

               setFlashMessages([{ header: "Error", content: `Failed to fetch data for ${title}.`, type: "error", dismissible: true }]);

               setLoading(false);

           }

       }

       fetchData();

   }, [endpoint, title]);

   const { items, collectionProps, filterProps, paginationProps } = useCollection(data, {

       filtering: { empty: <Box textAlign="center">No data found.</Box>, noMatch: <Box textAlign="center">No matching data found.</Box> },

       pagination: { pageSize: 10 },

   });
   const handleViewDetails = () => {
    if (selectedTicker) {
        navigate(`/analyst/tickers/details/${selectedTicker}`);
    } else {
        setFlashMessages([{
            header: "Error",
            content: "Please select a ticker to view details.",
            type: "error",
            dismissible: true,
            onDismiss: () => setFlashMessages([])
        }]);
        }
    };

   const truncateText = (text, maxLength = 20) => {
    if (text === null || text === undefined) {
        return 'N/A';
    }
    
    if (typeof text !== 'string') {
        return 'Invalid input';
    }

    if (text.length <= maxLength) {
        return text;
    }

    return text.slice(0, maxLength) + '…';
};

   if (loading) return <Box textAlign="center"><Spinner /></Box>;

   return (

    <Container header={<Header variant="h3" description={`Here are today's ${title}`}
    actions={
        <Button variant="primary" onClick={handleViewDetails}>View Details</Button>
    }>{title}</Header>}>

           <Flashbar items={flashMessages} />

           <Box margin={{ top: 'l' }}>
            <ExpandableSection headerText='Bedrock' >
               <ChatWidget app_name="AnalystPortal" entity_type="Options"
                   context={JSON.stringify(items)}
                   entity_id={selectedTicker}
               />
               </ExpandableSection>
           </Box>

           <SpaceBetween size="l" direction="vertical">
            

               <TextFilter {...filterProps} />

               <Table

                   {...collectionProps}

                   columnDefinitions={[
                        {header: 'Select', cell: item => (
                        <RadioGroup value={selectedTicker} onChange={({ detail }) => setSelectedTicker(detail.value)}
                                    items={[{ label: '', value: item.symbol }]}
                                />
                            )
                        },

//Month Return, 52 Wk Range, 200 Day Average, YTD Return
//change, changePercent, symbol, volume, weekRang
                        { header: 'Symbol', cell: item => item.symbol },
                        { header: 'Name', cell: item => truncateText(item.name) },
                        { header: 'Price', cell: item => `$${item.price}` },
                        { header: 'Volume', cell: item => item.volume },
                        { header: '3 Month Return', cell: item => item['3 Month Return'] || 'N/A' },
                        { header: '50 Day Average', cell: item => item['50 Day Average'] || 'N/A' },
                        { header: '52 Wk Range', cell: item => item['weekRange'] || 'N/A' },
                        { header: '200 Day Average', cell: item => item['200 Day Average'] || 'N/A' },
                        { header: 'YTD Return', cell: item => item['YTD Return'] || 'N/A' },
                        { header: 'Change', cell: item => item.change || 'N/A' },
                        { header: '% Change', cell: item => item.changePercent || 'N/A' },
 
                       

                   ]}

                   items={items}

               />

               <Pagination {...paginationProps} />

           </SpaceBetween>

       </Container>

   );

}

export default ETFsTable;



