import React, { useState, useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import PortfolioList from './PortfolioList';  // Component to list all portfolios
import PortfolioInsert from './PortfolioInsert';  // Component to create a new portfolio
import PortfolioDetails from './PortfolioDetails';  // Component to view detailed portfolio information
import InvestmentDetail from './InvestmentDetail';
import InvestmentInsert from './InvestmentInsert';
import { useBreadcrumbs } from './breadcrumb/BreadcrumbProvider';
import { BreadcrumbGroup } from '@cloudscape-design/components';
import ChatWidget from './chat_bot/ChatWidget';
import LeftMenu from './menu/LeftMenu';
import { AppLayout, HelpPanel } from '@cloudscape-design/components';

function PortfolioRouter() {
    const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [toolsOpen, setToolsOpen] = useState(false);  // Add this state

    // Modified useLayoutEffect
    useLayoutEffect(() => {
        let rafId;
        const resizeObserver = new ResizeObserver((entries) => {
            // Use requestAnimationFrame to throttle updates
            rafId = window.requestAnimationFrame(() => {
                if (!entries.length) return;
                // Handle resize if needed
            });
        });

        const targetElement = document.body;
        resizeObserver.observe(targetElement);

        return () => {
            resizeObserver.disconnect();
            if (rafId) {
                window.cancelAnimationFrame(rafId);
            }
        };
    }, []);

    return (
        <AppLayout
            navigation={<LeftMenu isOpen={false} toggleDrawer={() => {}} />}
            tools={
                <HelpPanel 
                    header={<h2>Ask Bedrock</h2>}
                    visible={toolsOpen}
                    onDismiss={() => setToolsOpen(false)}
                >
                    <ChatWidget 
                        app_name="PortfolioMangerPortal" 
                        entity_type="ClientList" 
                        Panel="Tools"
                    />
                </HelpPanel>
            }
            toolsOpen={toolsOpen}
            onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            contentType="default"
            content={
                <div style={{ 
                    minHeight: '100vh', 
                    position: 'relative',
                    overflow: 'hidden'  // Add this
                }}>
                    <h2>Portfolio Management</h2>
                    <BreadcrumbGroup items={breadcrumbs} ariaLabel="Breadcrumbs" />
                    <Routes>
                        <Route path="/client/:clientId" element={<PortfolioList />} />
                        <Route path="new/:clientId" element={<PortfolioInsert />} />
                        <Route path=":portfolioId" element={<PortfolioDetails />} />
                        <Route path="/investments/:investmentId" element={<InvestmentDetail />} />
                        <Route path=":portfolioId/investments/new" element={<InvestmentInsert />} />
                    </Routes>
                </div>
            }
            disableContentPaddings={false}
            ariaLabels={{
                navigation: 'Navigation drawer',
                navigationToggle: 'Open navigation drawer',
                navigationClose: 'Close navigation drawer',
                notifications: 'Notifications',
                tools: 'Help panel',
                toolsToggle: 'Open help panel',
                toolsClose: 'Close help panel'
            }}
        />
    );
}

// Add an error boundary component
class ResizeErrorBoundary extends React.Component {
    componentDidCatch(error, errorInfo) {
        if (error.message?.includes('ResizeObserver')) {
            // Suppress ResizeObserver errors
            return;
        }
        throw error;
    }

    render() {
        return this.props.children;
    }
}

// Wrap the export with the error boundary
export default function PortfolioRouterWithErrorBoundary() {
    return (
        <ResizeErrorBoundary>
            <PortfolioRouter />
        </ResizeErrorBoundary>
    );
}
