import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosConfig';
import { Box, Flashbar, Container, Header, SideNavigation } from '@cloudscape-design/components';
import { format, isToday, isWithinInterval, subDays } from 'date-fns';

function ChatList({ searchParams }) {
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        const fetchConversations = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/conversations', { params: searchParams });
                const sortedConversations = response.data.sort((a, b) => new Date(b.updated_dt) - new Date(a.updated_dt));

                setConversations(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching conversations:', err);
                setFlashMessages([{ type: 'error', header: 'Error', content: err.message, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                setLoading(false);
            }
        };

        if (searchParams.app_name && searchParams.entity_type) {
            fetchConversations();
        }
    }, [searchParams]);

    const truncateName = (name) => {
        const words = name.split(' ');
        if (words.length <= 7) {
            return name;
        }
        return `${words.slice(0, 5).join(' ')} ... ${words.slice(-2).join(' ')}`;
    };

    const today = new Date();
    const previous7Days = subDays(today, 7);

    const todayConversations = conversations.filter(conversation =>
        isToday(new Date(conversation.updated_dt))
    );

    const previous7DaysConversations = conversations.filter(conversation =>
        isWithinInterval(new Date(conversation.updated_dt), { start: previous7Days, end: today }) && !isToday(new Date(conversation.updated_dt))
    );

    const olderConversations = conversations.filter(conversation =>
        new Date(conversation.updated_dt) < previous7Days
    );

    const createNavItems = (conversations) => conversations.map((conversation) => ({
        type: 'link',
        text: truncateName(conversation.conversation_name),
        href: `/chat/${conversation.conversation_id}`,
    }));

    const navItems = [
        {
            type: 'link-group',
            text: 'Today',
            items: createNavItems(todayConversations),
        },
        {
            type: 'link-group',
            text: 'Previous 7 Days',
            items: createNavItems(previous7DaysConversations),
        },
        {
            type: 'link-group',
            text: 'Older',
            items: createNavItems(olderConversations),
        },
    ];

    return (
        <Box>
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            {loading && <Box>Loading conversations...</Box>}
            {!loading && (
                <SideNavigation
                    items={navItems}
                />
            )}
            {!loading && conversations.length === 0 && <Box>No conversations found</Box>}
        </Box>
    );
}

export default ChatList;


