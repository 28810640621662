import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container } from '@cloudscape-design/components';
import CreateAuthUserModal from './modals/CreateAuthUserModal';
import EditAuthUserModal from './modals/EditAuthUserModal';

function AuthUserTable() {
    const [authUsers, setAuthUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedAuthUser, setSelectedAuthUser] = useState(null);

    const fetchAuthUsers = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/auth_users`)
            .then(response => {
                setAuthUsers(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching auth users:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No Auth Users found', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchAuthUsers();
    }, [fetchAuthUsers]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleAddAuthUser = (authUser) => {
        setAuthUsers(prevAuthUsers => [...prevAuthUsers, authUser]);
        fetchAuthUsers();
    };

    const handleUpdateAuthUser = (updatedAuthUser) => {
        setAuthUsers(prevAuthUsers => prevAuthUsers.map(a => a.user_id === updatedAuthUser.user_id ? updatedAuthUser : a));
        fetchAuthUsers();
    };

    const handleDeleteAuthUser = (userId) => {
        setAuthUsers(prevAuthUsers => prevAuthUsers.filter(a => a.user_id !== userId));
        fetchAuthUsers();
    };

    const handleEditClick = (authUser) => {
        setSelectedAuthUser(authUser);
        setIsEditModalOpen(true);
    };

    if (loading) return <Box>Loading auth users...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add Auth User
                        </Button>
                    </SpaceBetween>
                }
            >Auth Users</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'User ID', cell: item => item.user_id },
                    { header: 'Email', cell: item => item.email },
                    { header: 'User Role', cell: item => item.user_role },
                    { header: 'Date Created', cell: item => new Date(item.date_created).toLocaleDateString() },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={authUsers}
            />
            <CreateAuthUserModal
                visible={isAddModalOpen}
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchAuthUsers();
                }}
                onAuthUserAdded={handleAddAuthUser}
            />
            {selectedAuthUser && (
                <EditAuthUserModal
                    authUser={selectedAuthUser}
                    visible={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchAuthUsers();
                    }}
                    onAuthUserUpdated={handleUpdateAuthUser}
                    onAuthUserDeleted={handleDeleteAuthUser}
                />
            )}
        </Container>
    );
}

export default AuthUserTable;


