import React, { useState, useEffect } from 'react';

import { Box, Container, ExpandableSection,Button, RadioGroup, Header, Flashbar, SpaceBetween, Spinner, Table, TextFilter, Pagination } from '@cloudscape-design/components';

import { useCollection } from '@cloudscape-design/collection-hooks';

import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';
import ChatWidget from '../../chat_widget/ChatWidget';


function ForexTable({ title, endpoint }) {

   const [loading, setLoading] = useState(true);

   const [flashMessages, setFlashMessages] = useState([]);

   const [selectedTicker, setSelectedTicker] = useState(null);

   const [data, setData] = useState([]);
   const navigate = useNavigate();

   useEffect(() => {

       async function fetchData() {

           try {

               const response = await axiosInstance.get(endpoint);

               setData(response.data);

               setLoading(false);

           } catch (error) {

               setFlashMessages([{ header: "Error", content: `Failed to fetch data for ${title}.`, type: "error", dismissible: true }]);

               setLoading(false);

           }

       }

       fetchData();

   }, [endpoint, title]);

   const { items, collectionProps, filterProps, paginationProps } = useCollection(data, {

       filtering: { empty: <Box textAlign="center">No data found.</Box>, noMatch: <Box textAlign="center">No matching data found.</Box> },

       pagination: { pageSize: 10 },

   });

   const handleViewDetails = () => {
    if (selectedTicker) {
        //navigate(`/analyst/tickers/details/${selectedTicker}`);
        navigate(`/analyst/tickers/equity/${selectedTicker}`);

    } else {
        setFlashMessages([{
            header: "Error",
            content: "Please select a ticker to view details.",
            type: "error",
            dismissible: true,
            onDismiss: () => setFlashMessages([])
        }]);
        }
    };

   const truncateText = (text, maxLength = 20) => {
    if (text === null || text === undefined) {
        return 'N/A';
    }
    
    if (typeof text !== 'string') {
        return 'Invalid input';
    }

    if (text.length <= maxLength) {
        return text;
    }

    return text.slice(0, maxLength) + '…';
};
   if (loading) return <Box textAlign="center"><Spinner /></Box>;

   return (

    <Container header={<Header variant="h3" description=''
    actions={
        <Button variant="primary" onClick={handleViewDetails}>View Details</Button>
    }>{title}</Header>}>


           <Flashbar items={flashMessages} />
           <Box margin={{ top: 'l' }}>
            <ExpandableSection headerText='Ask Bedrock' >
               <ChatWidget app_name="AnalystPortal" entity_type="Currency"
                   context={JSON.stringify(items)}
                   entity_id={title}
               />
               </ExpandableSection>
           </Box>


           <SpaceBetween size="l" direction="vertical">

               <TextFilter {...filterProps} />

               <Table

                   {...collectionProps}

                   columnDefinitions={[
                    {header: 'Select', cell: item => (
                    <RadioGroup value={selectedTicker} onChange={({ detail }) => setSelectedTicker(detail.value)}
                                items={[{ label: '', value: item.symbol }]}
                            />
                        )
                    },


                   { header: 'Symbol', cell: item => item.symbol },

                   { header: 'Name', cell: item => truncateText(item.name) },

                   { header: 'Price', cell: item => `$${item.price}` },

                   { header: 'Change', cell: item => `${item.change}%` },



                   ]}

                   items={items}

               />

               <Pagination {...paginationProps} />

           </SpaceBetween>

       </Container>

   );

}

export default ForexTable;
