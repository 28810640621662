import React, { useState } from 'react';
import { Button, Form, FormField, Input, SpaceBetween, Flashbar, Container, Header } from '@cloudscape-design/components';
import axiosLoginInstance from '../../services/axiosLoginConfig';
import { useNavigate } from 'react-router-dom';

function Login({ onLogin }) {
    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const navigate = useNavigate();

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Login failed: ${details}`;
            }
            return `Login failed: ${error.response.data.detail}`;
        } else {
            return `Login failed: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setLoginData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axiosLoginInstance.post(
                '/auth_users/login',
                new URLSearchParams({
                    username: loginData.username,
                    password: loginData.password
                })
            );
            const { access_token } = response.data;
            // Save token in local storage
            localStorage.setItem('token', access_token);

            // Call onLogin with the access token and username
            onLogin(loginData.username, access_token);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handleSignOut = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <Container header={<Header variant="h1">Login</Header>}>
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Email">
                    <Input value={loginData.username} onChange={e => handleInputChange(e, 'username')} />
                </FormField>
                <FormField label="Password">
                    <Input type="password" value={loginData.password} onChange={e => handleInputChange(e, 'password')} />
                </FormField>
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </Button>
                    <Button onClick={handleSignOut} variant="link">
                        Sign Out
                    </Button>
                </SpaceBetween>
            </Form>
        </Container>
    );
}

export default Login;


