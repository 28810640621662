import React from 'react';
import { Tabs } from '@cloudscape-design/components';
import TickerTable from './TickerTable';
import TickerExchangesTable from './TickerExchangesTable';

function TickerTabs() {
    return (
        <Tabs
            tabs={[
                { label: 'Dow', id: 'tickers_dow', content: 
                            <TickerExchangesTable title="Dow" listName="dow" endpoint="/tickers/tickers_dow" updateEndpoint="/tickers/update_dow_tickers" /> },
                { label: 'S&P 500', id: 'tickers_sp500', content: 
                            <TickerExchangesTable title="S&P 500" endpoint="/tickers/tickers_sp500" updateEndpoint="/tickers/update_sp500_tickers"  /> },
                { label: 'Day Gainers', id: 'day_gainers', content: 
                            <TickerTable title="Day Gainers" endpoint="/tickers/day_gainers" /> },
                { label: 'Day Losers', id: 'day_losers', content: 
                            <TickerTable title="Day Losers" endpoint="/tickers/day_losers" /> },
                { label: 'Day Most Active', id: 'day_most_active', content: 
                            <TickerTable title="Day Most Active" endpoint="/tickers/day_most_active" /> },
                { label: 'Top Crypto', id: 'top_crypto', content: 
                            <TickerTable title="Top Crypto" endpoint="/tickers/top_crypto" /> },
            ]}
        />
    );
}

export default TickerTabs;


