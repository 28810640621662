import axios from 'axios';

//const baseURL_Value = process.env.REACT_APP_API_BASE_URL || "REACT_APP_API_BASE_URL_PLACEHOLDER"
const baseURL_Value =  "REACT_APP_API_BASE_URL_PLACEHOLDER"
//const baseURL_Value = "http://localhost:8000/api"

const axiosLoginInstance = axios.create({
    baseURL: baseURL_Value,  // Placeholder will be replaced by the entrypoint script
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export default axiosLoginInstance;