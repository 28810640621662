import React from 'react';
import { Tabs } from '@cloudscape-design/components';
import OptionsTable from './OptionsTable';
import TickerExchangesTable from '../tickers/TickerExchangesTable';


function OptionsTabs() {
    return (
        <Tabs
            tabs={[
                { label: 'Most Active', id: 'most_active', content: 
                    <OptionsTable title="'Most Active Options" endpoint="/options/most_active" updateEndpoint="/options/most_active"  /> },

                { label: 'Top Gainers', id: 'top_gainers', content: 
                    <OptionsTable title="Top Gainers Options" endpoint="/options/top_gainers" updateEndpoint="/options/top_gainers"  /> },
                
                { label: 'Top Losers', id: 'top_losers', content: 
                            <OptionsTable title="Top Losers Options" endpoint="/options/top_losers" updateEndpoint="/options/top_losers"  /> },

                { label: 'Highest Implied Volatility', id: 'high_impl_vol', content: 
                            <OptionsTable title="Highest Implied Volatility Options" endpoint="/options/high_volatility" updateEndpoint="/options/high_volatility"  /> },

                { label: 'Highest Open Interest', id: 'high_open_int', content: 
                            <OptionsTable title="Highest Open Interest" endpoint="/options/open_interest" updateEndpoint="/options/open_interest"  /> },
                            
                
            ]}
        />
    );
}

export default OptionsTabs;


