import React from 'react';
import { Tabs } from '@cloudscape-design/components';
import IndicesTable from './IndicesTable';
import TickerExchangesTable from '../tickers/TickerExchangesTable';


function IndicesTabs() {
    return (
        <Tabs
            tabs={[
                { label: 'World Indices', id: 'world_indices', content: 
                <IndicesTable title="World Indices" endpoint="/markets/indices" updateEndpoint="/markets/indices"  /> },

                { label: 'Dow Tickers', id: 'tickers_dow', content: 
                            <TickerExchangesTable title="Dow" listName="dow" endpoint="/tickers/tickers_dow" updateEndpoint="/tickers/update_dow_tickers" /> },
                { label: 'S&P 500 Tickers', id: 'tickers_sp500', content: 
                            <TickerExchangesTable title="S&P 500" listName="sp500" endpoint="/tickers/tickers_sp500" updateEndpoint="/tickers/update_sp500_tickers"  /> },

                            
                
            ]}
        />
    );
}

export default IndicesTabs;


