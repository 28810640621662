import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosConfig';
import {
    Box, Container, Header, Table, Spinner, Flashbar
} from '@cloudscape-design/components';
import Chatbot from '../Chatbot';

function TickerDetails() {
    const { ticker } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/tickers/details/${ticker}`);
                setData(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch ticker details:', err);
                setError('Failed to load ticker details');
                setLoading(false);
            }
        };

        fetchData();
    }, [ticker]);

    if (loading) return <Box textAlign="center"><Spinner /> Loading...</Box>;
    if (error) return <Box>Error: {error}</Box>;

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp * 1000);
        return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(date);
    };

    const formatCurrency = (value) => {
        if (value === null || value === undefined || isNaN(value)) return '';
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const formatRange = (range) => {
        if (!range) return '';
        const [low, high] = range.split(' - ');
        return `${formatCurrency(low)} - ${formatCurrency(high)}`;
    };

    const formatValue = (item, value) => {
        if (item === 'Ex-Dividend Date' || item === 'Earnings Date') {
            return formatDate(value);
        }
        if (
            item === 'Previous Close' || item === 'Open' || item === 'Bid' || 
            item === 'Ask' || item === "1y Target Est" || item.includes('Dividend') || 
            item.includes('Market Cap') || item.includes('Volume')
        ) {
            return formatCurrency(value);
        }
        if (item === "Day's Range" || item === '52 Week Range') {
            return formatRange(value);
        }
        return value === null ? '' : String(value);
    };

    const quoteTableData = Object.entries(data?.quote_table || {}).map(([item, value]) => ({
        item,
        value: formatValue(item, value)
    }));

    const quoteTableDataJSON = JSON.stringify(quoteTableData);

    return (
        <Container header={<Header variant="h1">Ticker Details: {ticker}</Header>}>
            <Flashbar items={[]} />
            <Box>
                <h3>Quote Table</h3>
                <Table
                    columnDefinitions={[
                        { header: 'Item', cell: item => item.item },
                        { header: 'Value', cell: item => item.value },
                    ]}
                    items={quoteTableData}
                    empty={<Box>No data found.</Box>}
                />
            </Box>
            <Chatbot
                contextData={quoteTableDataJSON}
                title="Ticker Details"
                entityKey={ticker}
            />
        </Container>
    );
}

export default TickerDetails;
