import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select, Textarea } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

const productTypes = [
    { label: "Investment", value: "Investment" },
    { label: "Savings", value: "Savings" },
    // Add more product types as needed
];

const severities = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
];

function EditAlertModal({ clientId, alert, visible, onClose, onAlertUpdated, onAlertDeleted }) {
    const [alertData, setAlertData] = useState({ ...alert });
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        setAlertData({ ...alert });
    }, [alert]);

    const handleInputChange = (e, field) => {
        setAlertData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e, field) => {
        setAlertData(prev => ({ ...prev, [field]: e.detail.selectedOption.value }));
    };

    const handleSaveAlert = async () => {
        setSaving(true);
        try {
            const updates = {
                product_id: alertData.product_id,
                product_type: alertData.product_type,
                severity: alertData.severity,
                message: alertData.message,
            };
            await axiosInstance.put(`/alerts/${alert.alert_id}`, updates);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Alert updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onAlertUpdated({ ...alert, ...updates });
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteAlert = async () => {
        setDeleting(true);
        try {
            await axiosInstance.delete(`/alerts/${alert.alert_id}`);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Alert deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onAlertDeleted(alert.alert_id);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Alert"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteAlert}>
                        {deleting ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSaveAlert}>
                        {saving ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <Input type="hidden" value={alertData.client_id} readOnly />
                <FormField label="Message">
                    <Textarea value={alertData.message} onChange={e => handleInputChange(e, 'message')} />
                </FormField>
                <FormField label="Severity">
                    <Select
                        selectedOption={severities.find(option => option.value === alertData.severity)}
                        onChange={e => handleSelectChange(e, 'severity')}
                        options={severities}
                        placeholder="Select severity"
                    />
                </FormField>
                <FormField label="Product ID">
                    <Input value={alertData.product_id} onChange={e => handleInputChange(e, 'product_id')} />
                </FormField>
                <FormField label="Product Type">
                    <Select
                        selectedOption={productTypes.find(option => option.value === alertData.product_type)}
                        onChange={e => handleSelectChange(e, 'product_type')}
                        options={productTypes}
                        placeholder="Select a product type"
                    />
                </FormField>
            </Form>
        </Modal>
    );
}

export default EditAlertModal;
