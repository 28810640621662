import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

const LineChart = ({ chartData, chartId }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (chartData && chartRef.current) {
      const ctx = document.getElementById(chartId).getContext('2d');
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const tableauColors = [
        '#4E79A7', '#F28E2B', '#E15759', '#76B7B2', '#59A14F', '#EDC948',
        '#B07AA1', '#FF9DA7', '#9C755F', '#BAB0AC'
      ];

      const datasets = chartData.data.map((series, index) => ({
        label: series.label,
        data: series.values.map(item => ({ x: new Date(item.x), y: item.y })),
        borderColor: tableauColors[index % tableauColors.length],
        backgroundColor: tableauColors[index % tableauColors.length],
        borderWidth: 1,
        fill: false,
      }));

      chartInstanceRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: datasets,
        },
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'time',
              title: {
                display: true,
                text: chartData.x_axis.title,
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: chartData.y_axis.title,
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: chartData.title,
            },
            legend: {
              display: true,
              position: 'top',
            },
          },
        },
      });
    }
  }, [chartData, chartId]);

  return <div><canvas key={chartId} id={chartId} ref={chartRef} /></div>;
};

export default LineChart;
