import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';
import { Box, Container, Header, Spinner, Flashbar, Button, SpaceBetween, ExpandableSection, Link } from '@cloudscape-design/components';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const formatPercentage = (value) => {
    return `${value}%`;
};

const formatYears = (value) => {
    return `${value} years`;
};

function PopularGoalsList({ ageGroup, clientId }) {
    const [goals, setGoals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [inserting, setInserting] = useState(false); // New state for inserting

    useEffect(() => {
        fetchGoals();
    }, [ageGroup]);

    const fetchGoals = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/popular/goals/${ageGroup}`);
            setGoals(response.data);
            setLoading(false);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            setLoading(false);
        }
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            return `Failed to load goals data: ${error.response.data.detail}`;
        } else {
            return `Failed to load goals data: ${error.message}`;
        }
    };

    const handleInsertGoal = async (goal) => {
        setInserting(true);
        const startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        const endDate = new Date(startDate);
        endDate.setFullYear(startDate.getFullYear() + parseFloat(goal.term_years));

        const newGoal = {
            goal: goal.goal,
            goal_type: goal.goal_type,
            target_amount: goal.target_amount,
            term: goal.term_years,
            client_id: clientId  // Use the passed client ID
        };

        try {
            await axiosInstance.post('/goals/', newGoal);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Goal created successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            //onInsert();  // Trigger the refresh in the parent component
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setInserting(false);
        }
    };

    return (
        <Container header>
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            {loading ? (
                <Box textAlign="center"><Spinner />Loading goals...</Box>
            ) : (
                goals.map(goal => (
                    <ExpandableSection
                        key={goal.goal}
                        variant="stacked"
                        headerText={
                            <span style={{ fontSize: '0.875rem' }}>{goal.goal}</span>}
                        headerActions={<Button onClick={() => handleInsertGoal(goal)}>
                            {inserting ? <Spinner /> : 'Insert'}
                        </Button>}
                    >
                        <SpaceBetween direction="vertical" size="m">
                            <Box>
                                <strong>Target Amount:</strong> {formatCurrency(goal.target_amount)}
                            </Box>
                            <Box>
                                <strong>Term:</strong> {formatYears(goal.term_years)}
                            </Box>
                            <Box>
                                <strong>Annual Rate:</strong> {formatPercentage(goal.annual_rate)}
                            </Box>
                            <Box>
                                <strong>Monthly Payment:</strong> {formatCurrency(goal.monthly_payment)}
                            </Box>
                        </SpaceBetween>
                    </ExpandableSection>
                ))
            )}
        </Container>
    );
}

export default PopularGoalsList;
