import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SideNavigation, Icon } from '@cloudscape-design/components';

function AnalystLeftMenu({ isOpen, toggleDrawer }) {
    const navigate = useNavigate();
    const [activeHref, setActiveHref] = React.useState(
        "#/page1"
      );
    const handleNavigation = (path) => {
        navigate(path);
        toggleDrawer(false);
    };

    const items = [
        { type: 'link', text: 'MarketChat', href: '/analyst/chat'  },
        { type: 'link', text: 'World Indices', href: '/analyst/world_indices'  },
        { type: 'link', text: 'Futures', href: '/analyst/futures'  },
        { type: 'link', text: 'Bonds', href: '/analyst/bonds'  },
        { type: 'link', text: 'Currencies', href: '/analyst/forex'  },
        { type: 'link', text: 'Options', href: '/analyst/options'  },
        { type: 'link', text: 'Sectors', href: '/analyst/sectors'  },
        { type: 'link', text: 'Stocks', href: '/analyst/tickers'  },
        { type: 'link', text: 'Crypto', href: '/analyst/crypto'  },
        { type: 'link', text: 'ETFs', href: '/analyst/etfs'  },
        { type: 'link', text: 'Mutual Funds', href: '/analyst/funds'  },




    ];

    return (
        <SideNavigation
            items={items}
            activeHref={window.location.pathname}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    setActiveHref(event.detail.href);
                    handleNavigation(event.detail.href);

                  }
            }}
            onToggle={() => toggleDrawer(false)}
            open={isOpen}
        />
    );
}

export default AnalystLeftMenu;
