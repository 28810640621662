import React, { useState, useEffect } from 'react';
import {
   Box, Container, Header, Flashbar, SpaceBetween, Spinner, Table, TextFilter, Pagination, Button, RadioGroup
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

function TickerExchangesTable({ title, endpoint, updateEndpoint, listName }) {
   const [loading, setLoading] = useState(true);
   const [flashMessages, setFlashMessages] = useState([]);
   const [data, setData] = useState([]);
   const [selectedTicker, setSelectedTicker] = useState(null);
   const navigate = useNavigate();

   useEffect(() => {
       async function fetchData() {
           try {
               const response = await axiosInstance.get(endpoint);
               const responseData = response.data;

               // Validate and process the response data
               const processedData = Array.isArray(responseData) ? responseData : [];

               const minCompositeScore = Math.min(...processedData.map(item => parseFloat(item.compositeScore)));
               const maxCompositeScore = Math.max(...processedData.map(item => parseFloat(item.compositeScore)));

               const normalizedData = processedData.map(item => ({
                   ...item,
                   normalizedCompositeScore: 1 + ((parseFloat(item.compositeScore) - minCompositeScore) * (9 - 1)) / (maxCompositeScore - minCompositeScore)
               }));

               setData(normalizedData);
               setLoading(false);
           } catch (error) {
               setFlashMessages([{
                   header: "Error",
                   content: `Failed to fetch data for ${title}.`,
                   type: "error",
                   dismissible: true,
                   onDismiss: () => setFlashMessages([])
               }]);
               setLoading(false);
           }
       }

       fetchData();
   }, [endpoint, title ]);

   const handleUpdate = async () => {
       setLoading(true);
       try {
           await axiosInstance.post(updateEndpoint);
           setFlashMessages([{
               header: "Success",
               content: `Successfully updated ${title}.`,
               type: "success",
               dismissible: true,
               onDismiss: () => setFlashMessages([])
           }]);
           const response = await axiosInstance.get(endpoint);
           const responseData = response.data;
           const processedData = Array.isArray(responseData) ? responseData : [];

           const minCompositeScore = Math.min(...processedData.map(item => parseFloat(item.compositeScore)));
           const maxCompositeScore = Math.max(...processedData.map(item => parseFloat(item.compositeScore)));

           const normalizedData = processedData.map(item => ({
               ...item,
               normalizedCompositeScore: 1 + ((parseFloat(item.compositeScore) - minCompositeScore) * (9 - 1)) / (maxCompositeScore - minCompositeScore)
           }));

           setData(normalizedData);
           setLoading(false);
       } catch (error) {
           setFlashMessages([{
               header: "Error",
               content: `Failed to update data for ${title}.`,
               type: "error",
               dismissible: true,
               onDismiss: () => setFlashMessages([])
           }]);
           setLoading(false);
       }
   };

   const { items, actions, collectionProps, filterProps, paginationProps } = useCollection(data, {
       filtering: {
           empty: <Box textAlign="center">No data found.</Box>,
           noMatch: <Box textAlign="center">No matching data found.</Box>
       },
       pagination: { pageSize: 10 },
       sorting: {
           sortableFields: ['symbol', 'marketCap', 'trailingPE', 'forwardPE', 'revenueGrowth', 'grossMargins', 'operatingMargins', 'returnOnAssets', 'returnOnEquity', 'beta', 'debtToEquity', 'weight', 'normalizedCompositeScore'],
           defaultState: {
               sortingColumn: { sortingField: 'normalizedCompositeScore' },
               isDescending: true
           },
           compare: (a, b, sortingColumn) => {
               const field = sortingColumn.sortingField;
               const numericFields = ['marketCap', 'trailingPE', 'forwardPE', 'revenueGrowth', 'grossMargins', 'operatingMargins', 'returnOnAssets', 'returnOnEquity', 'beta', 'debtToEquity', 'normalizedCompositeScore'];

               if (numericFields.includes(field)) {
                   const aValue = parseFloat(a[field]);
                   const bValue = parseFloat(b[field]);
                   if (isNaN(aValue) && isNaN(bValue)) return 0;
                   if (isNaN(aValue)) return -1;
                   if (isNaN(bValue)) return 1;
                   return aValue - bValue;
               }
               return a[field].localeCompare(b[field]);
           }
       }
   });

   const formatValue = (value, type) => {
       if (value === null || value === undefined) return 'N/A';
       
       const numberValue = parseFloat(value);
       if (isNaN(numberValue)) return 'N/A';
       
       switch (type) {
           case 'currency':
               if (numberValue >= 1e12) {
                   return `$${(numberValue / 1e12).toFixed(2)}T`;
               } else if (numberValue >= 1e9) {
                   return `$${(numberValue / 1e9).toFixed(2)}B`;
               } else if (numberValue >= 1e6) {
                   return `$${(numberValue / 1e6).toFixed(2)}M`;
               } else {
                   return `$${numberValue.toFixed(2)}`;
               }
           case 'percentage':
               return `${(numberValue * 100).toFixed(2)}%`;
           case 'decimal':
               return numberValue.toFixed(2);
           default:
               return numberValue.toString();
       }
   };

   const handleViewDetails = () => {
       if (selectedTicker) {
           navigate(`/analyst/tickers/details/${selectedTicker}`);
       } else {
           setFlashMessages([{
               header: "Error",
               content: "Please select a ticker to view details.",
               type: "error",
               dismissible: true,
               onDismiss: () => setFlashMessages([])
           }]);
       }
   };

   const handleCalcWeights = async () => {
    if (!listName) {
      setFlashMessages([{
        header: "Error",
        content: "Please enter a list name.",
        type: "error",
        dismissible: true,
        onDismiss: () => setFlashMessages([])
      }]);
      return;
    }
    setLoading(true);
    try {
      await axiosInstance.post(`/tickers/update_list_weights/${listName}`);
      setFlashMessages([{
        header: "Success",
        content: `Successfully calculated weights for ${listName}.`,
        type: "success",
        dismissible: true,
        onDismiss: () => setFlashMessages([])
      }]);
      const response = await axiosInstance.get(endpoint);
      const responseData = response.data;
      const processedData = Array.isArray(responseData) ? responseData : [];
  
      const minCompositeScore = Math.min(...processedData.map(item => parseFloat(item.compositeScore)));
      const maxCompositeScore = Math.max(...processedData.map(item => parseFloat(item.compositeScore)));
  
      const normalizedData = processedData.map(item => ({
        ...item,
        normalizedCompositeScore: 1 + ((parseFloat(item.compositeScore) - minCompositeScore) * (9 - 1)) / (maxCompositeScore - minCompositeScore)
      }));
  
      setData(normalizedData);
      setLoading(false);
    } catch (error) {
      setFlashMessages([{
        header: "Error",
        content: `Failed to calculate weights for ${listName}.`,
        type: "error",
        dismissible: true,
        onDismiss: () => setFlashMessages([])
      }]);
      setLoading(false);
    }
  };
  

   if (loading) return <Box textAlign="center"><Spinner /></Box>;

   return (
       <Container header={
           <Header variant="h3" description={`${title} list of stocks`}
               actions={
                   <SpaceBetween direction="horizontal" size="xs">
                       <Button onClick={handleCalcWeights} variant="secondary">CalcWeights</Button>
                       <Button onClick={handleUpdate} variant="secondary">Update</Button>
                       <Button variant="primary" onClick={handleViewDetails}>Details</Button>
                   </SpaceBetween>
               }>
               {title}
           </Header>
       }>
           <Flashbar items={flashMessages} />
           <SpaceBetween size="l" direction="vertical">
               <Box padding={{ bottom: 's' }}>
                   <TextFilter {...filterProps} />
               </Box>
               <Table
                   {...collectionProps}
                   columnDefinitions={[
                       {
                           header: 'Select',
                           cell: item => (
                               <RadioGroup
                                   value={selectedTicker}
                                   onChange={({ detail }) => setSelectedTicker(detail.value)}
                                   items={[{ label: '', value: item.symbol }]}
                               />
                           )
                       },
                       { header: 'Symbol', cell: item => item.symbol, sortingField: 'symbol' },
                       { header: 'Name', cell: item => item.shortName, sortingField: 'shortNname' },
                       { header: 'Sector', cell: item => item.sector, sortingField: 'sector' },
                       { header: 'Market Cap', cell: item => formatValue(item.marketCap, 'currency'), sortingField: 'marketCap' },
                       { header: 'Trailing PE', cell: item => formatValue(item.trailingPE, 'decimal'), sortingField: 'trailingPE' },
                       { header: 'Forward PE', cell: item => formatValue(item.forwardPE, 'decimal'), sortingField: 'forwardPE' },
                       { header: 'Revenue Growth', cell: item => formatValue(item.revenueGrowth, 'percentage'), sortingField: 'revenueGrowth' },
                       { header: 'Gross Margins', cell: item => formatValue(item.grossMargins, 'percentage'), sortingField: 'grossMargins' },
                       { header: 'Operating Margins', cell: item => formatValue(item.operatingMargins, 'percentage'), sortingField: 'operatingMargins' },
                       { header: 'Return on Assets', cell: item => formatValue(item.returnOnAssets, 'percentage'), sortingField: 'returnOnAssets' },
                       { header: 'Return on Equity', cell: item => formatValue(item.returnOnEquity, 'percentage'), sortingField: 'returnOnEquity' },
                       { header: 'Beta', cell: item => formatValue(item.beta, 'decimal'), sortingField: 'beta' },
                       { header: 'Debt to Equity', cell: item => formatValue(item.debtToEquity, 'decimal'), sortingField: 'debtToEquity' },
                       { header: 'Recommendation', cell: item => item.recommendationKey, sortingField: 'recommendationKey' },
                       { header: 'Weight', cell: item => item.weight, sortingField: 'weight' },
                       { header: 'Composite Score', cell: item => formatValue(item.compositeScore, 'currency'), sortingField: 'normalizedCompositeScore' },
                   ]}
                   items={items}
                   sorting={{
                       sortingColumn: { sortingField: 'normalizedCompositeScore' },
                       isDescending: true,
                       onSortingChange: ({ sortingColumn, isDescending }) => {
                           actions.setSorting(sortingColumn, isDescending);
                       }
                   }}
               />
               <Pagination {...paginationProps} />
           </SpaceBetween>
       </Container>
   );
}

export default TickerExchangesTable;


