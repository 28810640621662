import React, { useState } from 'react';
import { Box, Table, Header, ColumnLayout, SpaceBetween, Link, Icon, Button } from '@cloudscape-design/components';

const formatCurrency = (value) => {
    if (value >= 1e9) {
        return `$${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
        return `$${(value / 1e6).toFixed(1)}M`;
    } else {
        return `$${value}`;
    }
};

const formatTotalPay = (value) => {
    if (value == null) {
        return '';
    }
    return formatCurrency(value);
};

const formatOverview = (text) => {
    if (!text) {
        return [];
    }

    const sentences = text.split('. ');
    const paragraphs = [];
    for (let i = 0; i < sentences.length; i += 4) {
        paragraphs.push(sentences.slice(i, i + 4).join('. ') + '.');
    }
    if (paragraphs.length > 5) {
        const lastTwo = paragraphs.splice(-4);
        paragraphs.push(lastTwo.join(' '));
    }
    return paragraphs;
};

const formatDate = (epoch) => {
    return new Date(epoch * 1000).toLocaleDateString();
};

const StatsValuation = ({ data }) => {
    const [showFullOverview, setShowFullOverview] = useState(false);
    const overviewParagraphs = formatOverview(data.longBusinessSummary);

    const toggleOverview = () => {
        setShowFullOverview(!showFullOverview);
    };

    return (
        <Box>
            <SpaceBetween size="l" direction="vertical">

            <Header variant="h4">
                {data.shortName}&nbsp; 
                <Link href={data.website} externalIconAriaLabel="Open website">
                    <Icon name="external" />
                </Link>
            </Header>
            <Box margin={{ bottom: 's' }}>
                {overviewParagraphs[0]}
                <Button variant="icon" iconName="status-info" onClick={toggleOverview} ariaLabel="Show full overview">
                    <Icon name="subtle-search" />
                </Button>
            </Box>
            {showFullOverview && overviewParagraphs.slice(1).map((paragraph, index) => (
                <Box key={index} margin={{ bottom: 's' }}>
                    {paragraph}
                </Box>
            ))}


            <Header variant="h4" margin={{ top: 'l' }}>Company Details</Header>
            <ColumnLayout columns={3} variant="text-grid">
               <div>
                       <div>{data.address1}, {data.city}</div>
                       <div>{data.state} {data.zip}, {data.country} </div>
                       <div>Phone: {data.phone}</div>
                       <div>Sector: {data.sector}</div>
                       <div>Industry: {data.industry}</div>
                       <div>Employees: {data.fullTimeEmployees}</div>
               </div>


               <div>
                   <Header variant="h4">Market Info</Header>
                   <div>Market Cap: {formatCurrency(data.marketCap)}</div>
                   <div>Beta: {data.beta}</div>
                   <div>PE Ratio: {data.trailingPE}</div>
               </div>
               <div>
                   <Header variant="h4">Market Price</Header>
                   <div>Previous Close: {data.previousClose}</div>
                   <div>Open: {data.open}</div>
                   <div>Day Low: {data.dayLow}</div>
                   <div>Day High: {data.dayHigh}</div>
               </div>
               <div>
                   <Header variant="h4">Regular Market Price</Header>
                   <div>Regular Market Previous Close: {data.regularMarketPreviousClose}</div>
                   <div>Regular Market Open: {data.regularMarketOpen}</div>
                   <div>Regular Market Day Low: {data.regularMarketDayLow}</div>
                   <div>Regular Market Day High: {data.regularMarketDayHigh}</div>
               </div>


               <div>
                   <Header variant="h4">Dividend Info</Header>
                   <div>Dividend Rate: {data.dividendRate}</div>
                   <div>Dividend Yield: {data.dividendYield}</div>
                   <div>Ex-Dividend Date: {formatDate(data.exDividendDate)}</div>
                   <div>Governance Epoch Date: {formatDate(data.governanceEpochDate)}</div>
                   <div>Compensation As Of Epoch Date: {formatDate(data.compensationAsOfEpochDate)}</div>


               </div>
               <div>
                   <Header variant="h4">Risk Scores</Header>
                   <div>Audit Risk: {data.auditRisk}</div>
                   <div>Board Risk: {data.boardRisk}</div>
                   <div>Compensation Risk: {data.compensationRisk}</div>
                   <div>Shareholder Rights Risk: {data.shareHolderRightsRisk}</div>
                   <div>Overall Risk: {data.overallRisk}</div>
               </div>
               <div>
                   <Header variant="h4" margin={{ top: 'l' }}>Cash Details</Header>
                   <div>Total Cash: {formatCurrency(data.totalCash)}</div>
                   <div>Total Cash Per Share: {data.totalCashPerShare}</div>
                   <div>Total Debt: {formatCurrency(data.totalDebt)}</div>
                   <div>Quick Ratio: {data.quickRatio}</div>
                   <div>Current Ratio: {data.currentRatio}</div>
               </div>
               <div>
                   <Header variant="h4" margin={{ top: 'l' }}>Revenue</Header>
                   <div>Total Revenue: {formatCurrency(data.totalRevenue)}</div>
                   <div>Revenue Per Share: {data.revenuePerShare}</div>
                   <div>Financial Currency: {data.financialCurrency}</div>
                   <div>Return on Assets: {data.returnOnAssets}</div>
                   <div>Return on Equity: {data.returnOnEquity}</div>
               </div>


               <div>
                   <Header variant="h4" margin={{ top: 'l' }}>Growth & Returns</Header>
                   <div>Revenue Growth: {data.revenueGrowth}</div>
                   <div>Earnings Growth: {data.earningsGrowth}</div>
                   <div>Operating Margins: {data.operatingMargins}</div>
                   <div>Trailing PEG Ratio: {data.trailingPegRatio}</div>
                   <div>Debt to Equity: {data.debtToEquity}</div>
               </div>
           </ColumnLayout>

            <Table
                columnDefinitions={[
                    { id: 'name', header: 'Name', cell: e => e.name },
                    { id: 'title', header: 'Title', cell: e => e.title },
                    { id: 'totalPay', header: 'Total Pay', cell: e => formatTotalPay(e.totalPay) },
                ]}
                items={data.companyOfficers}
                header={<Header variant="h4">Company Officers</Header>}
                trackBy="name"
            />
            </SpaceBetween>
        </Box>
    );
};

export default StatsValuation;
