import React, { useState } from 'react';
import { Box, Table, Header, ColumnLayout, SpaceBetween, Link, Icon, Button } from '@cloudscape-design/components';

const formatCurrency = (value) => {
    if (value >= 1e9) {
        return `$${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
        return `$${(value / 1e6).toFixed(1)}M`;
    } else {
        return `$${value}`;
    }
};

const formatTotalPay = (value) => {
    if (value == null) {
        return '';
    }
    return formatCurrency(value);
};

const formatOverview = (text) => {
    if (!text) {
        return [];
    }

    const sentences = text.split('. ');
    const paragraphs = [];
    for (let i = 0; i < sentences.length; i += 4) {
        paragraphs.push(sentences.slice(i, i + 4).join('. ') + '.');
    }
    if (paragraphs.length > 5) {
        const lastTwo = paragraphs.splice(-4);
        paragraphs.push(lastTwo.join(' '));
    }
    return paragraphs;
};

const formatDate = (epoch) => {
    return new Date(epoch * 1000).toLocaleDateString();
};

const EquityStats = ({ data }) => {
    const [showFullOverview, setShowFullOverview] = useState(false);
    const overviewParagraphs = formatOverview(data.longBusinessSummary);

    const toggleOverview = () => {
        setShowFullOverview(!showFullOverview);
    };

    return (
        <Box>
            <SpaceBetween size="l" direction="vertical">

            <Header variant="h4">
                {data.shortName}&nbsp; 
                <Link href={data.website} externalIconAriaLabel="Open website">
                    <Icon name="external" />
                </Link>
            </Header>
            <Box margin={{ bottom: 's' }}>
                {overviewParagraphs[0]}
                <Button variant="icon" iconName="status-info" onClick={toggleOverview} ariaLabel="Show full overview">
                    <Icon name="subtle-search" />
                </Button>
            </Box>
            {showFullOverview && overviewParagraphs.slice(1).map((paragraph, index) => (
                <Box key={index} margin={{ bottom: 's' }}>
                    {paragraph}
                </Box>
            ))}


            <Header variant="h4" margin={{ top: 'l' }}>Company Details</Header>
            <ColumnLayout columns={2} variant="text-grid">


               <div>
                   <Header variant="h4">Market Price</Header>
                   <div>Previous Close: ${data.previousClose}</div>
                   <div>Open: ${data.open}</div>
                   <div>Day Low: ${data.dayLow}</div>
                   <div>Day High: ${data.dayHigh}</div>
               </div>
               <div>
                   <Header variant="h4">Regular Market Price</Header>
                   <div>Regular Market Previous Close: ${data.regularMarketPreviousClose}</div>
                   <div>Regular Market Open: ${data.regularMarketOpen}</div>
                   <div>Regular Market Day Low: ${data.regularMarketDayLow}</div>
                   <div>Regular Market Day High: ${data.regularMarketDayHigh}</div>
               </div>
               

               
           </ColumnLayout>

            </SpaceBetween>
        </Box>
    );
};

export default EquityStats;
