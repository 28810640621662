import * as React from "react";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import ReactMarkdown from 'react-markdown';
import thumbnail from './thumbnail.png';  // Import the thumbnail image
import './styles.css';

const markdown = `
>
### Financial Planning: Comprehensive Strategy for Managing Finances
Financial planning involves evaluating an individual’s or organization's current financial situation, setting objectives, and developing a plan to achieve these objectives through various financial instruments and resources. Key steps include assessment of income, expenses, assets, liabilities, and cash flow; setting short-term and long-term goals; identifying options; creating a detailed plan; implementing the plan; and ongoing monitoring and review to adapt to changes in circumstances or financial markets, ensuring long-term stability and growth.

### Proposal Generation: Easy-to-Understand Investment Proposals
Create and deliver dynamic, compelling, and easy-to-understand investment proposals to clients. This process involves building investment recommendations through an intuitive, step-by-step workflow that simplifies the creation of well-diversified portfolios. The goal is to impress clients and prospects by covering all necessary aspects with a user-friendly proposal system that works efficiently. The focus is on presenting clear, actionable investment strategies that align with clients' financial goals and risk tolerance, ensuring clarity and confidence in the investment process.

### Client Profile & Recommendations: Tailored Investment Strategies
Develop personalized investment strategies based on detailed client profiles, considering factors such as age, investment goals, risk tolerance, and investment horizon. For example, a client in their mid-thirties might prefer a balanced approach with a mix of stocks, bonds, real estate, and cash. Regular performance reviews, advisory alerts, and communications logs help keep the client informed and engaged. Recommendations might include rebalancing the portfolio, optimizing taxes, planning for family and future needs, managing risks, and making periodic adjustments.

### GenAI Integrations: AI Solutions Across Client Acquisition, Onboarding, Lifecycle Management, and Portfolio Management
Integrate GenAI solutions to enhance various aspects of wealth management. For client acquisition, AI can systematically capture and analyze data to create a holistic view of clients and prospects. During onboarding, tools like IDP and facial recognition streamline processes. In client lifecycle management, AI-driven assistants provide meeting prep, document search, and sentiment analysis. Portfolio management benefits from GenAI through conversational chatbots, investment proposal generation, and personalized insights, ensuring a seamless, efficient, and data-driven approach across the entire client journey.




`;

function LiveMeetingAssistant() {
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleThumbnailClick = () => {
    setIsPlaying(true);
  };

  return (
    <Container>
      <SpaceBetween direction="vertical" size="s">
        <SpaceBetween direction="vertical" size="xxs">
          <Box variant="h2">
            <Link fontSize="heading-m" href="#">
              Wealth Managment & GenAI
            </Link>
          </Box>
        </SpaceBetween>
        {isPlaying ? (
          <video width="100%" controls autoPlay>
            <source src="https://d2908q01vomqb2.cloudfront.net/artifacts/DBSBlogs/ML-16465/ML-16465-LMABlogDemoV3.mp4?_=1" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Link onClick={handleThumbnailClick}>
            <img src={thumbnail} alt="Live Meeting Assistant Demo" className="thumbnail" />

          </Link>
        )}
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </SpaceBetween>
    </Container>
  );
}

export default LiveMeetingAssistant;


