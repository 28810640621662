import React, { useState, useEffect } from 'react';
import { Box, Pagination, Icon, SpaceBetween, Button } from '@cloudscape-design/components';
import DOMPurify from 'dompurify';
import './ChatWidgetMessage.css';
import ChartWidget from '../chart_widget/ChartWidget';
import ChatChainOfThought from './ChatChainOfThought';
import axiosInstance from '../../services/axiosConfig';


const ChatWidgetMessage = ({ messages = [], chartData=[], snippets }) => {
  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 1; // Display one message pair (user + assistant) per page

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(Math.ceil(groupedMessages.length / messagesPerPage));
  }, [messages]);

  
  const groupMessages = (messages) => {
    const groupedMessages = [];
    for (let i = 0; i < messages.length; i++) {
      if (messages[i].role === 'user') {
        const userMessage = messages[i];
        const assistantMessage = messages[i + 1] && messages[i + 1].role === 'assistant' ? messages[i + 1] : null;
        groupedMessages.push([userMessage, assistantMessage]);
        i += assistantMessage ? 1 : 0;
      }
    }
    return groupedMessages;
  };

  const groupedMessages = groupMessages(messages);
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = groupedMessages.slice(indexOfFirstMessage, indexOfLastMessage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderContent = (message) => {
    const sanitizedHtml = DOMPurify.sanitize(message.content[0].text);
    // Use dangerouslySetInnerHTML with caution
    return <div id={currentPage} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  };

  const [likeStatus, setLikeStatus] = useState(false);
  const [dislikeStatus, setDislikeStatus] = useState(false);

  const handleLike = async (userMessage) => {
    if (userMessage) {
        try {
            const prompt = userMessage.content[0].text;
            await axiosInstance.post('/chat/manage_cache/', {
                operation: 'extend',
                key: prompt // Using message_id as the cache key
            });
            setLikeStatus(true);    // Set like status to true on success
            setDislikeStatus(false); // Reset dislike status
            console.log('Liked message');
        } catch (error) {
            console.error('Error submitting like:', error);
        }
    }
};

const handleDislike = async (userMessage) => {
    if (userMessage) {
        try {
            const prompt = userMessage.content[0].text;
            await axiosInstance.post('/chat/manage_cache/', {
                operation: 'remove',
                key: prompt
            });
            setDislikeStatus(true);  // Set dislike status to true on success
            setLikeStatus(false);    // Reset like status
            console.log('Disliked message');
        } catch (error) {
            console.error('Error submitting dislike:', error);
        }
    }
};


  return (
    <Box align="center" margin={{ top: 'm' }} width="600px">
      {currentMessages.map((messagePair, index) => (
        <Box key={index} margin={{ bottom: 's' }} width="100%">
          <div className="user-message">
            <h4>{renderContent(messagePair[0])}</h4>
          </div>
          {messagePair[1] && (
            <div className="dynamic-content">
              {renderContent(messagePair[1])}
           
   
              {messagePair[1] && chartData && <ChartWidget chartData={chartData} currentPage={currentPage} />} 

              {messagePair[1] && snippets && <ChatChainOfThought snippets={snippets} />} 
              <Box padding={{ horizontal: 's' }} display="flex" justifyContent="flex-end">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => handleLike(messagePair[0])}  ariaLabel="Like response">
                                      <Icon name="thumbs-up" size="medium"   variant={likeStatus ? "success" : "normal"}/>                           
                                </Button>
                                <Button variant="link" onClick={() => handleDislike(messagePair[0])}  ariaLabel="Dislike response" >
                                  <Icon name="thumbs-down" size="medium"  variant={dislikeStatus ? "error" : "normal"}/>                           
                                </Button>

                             </SpaceBetween>
                        </Box>
              </div>
              
                   )}
        </Box>
      ))}
      {groupedMessages.length > 1 && (
        <Pagination
          currentPageIndex={currentPage}
          pagesCount={Math.ceil(groupedMessages.length / messagesPerPage)}
          onChange={({ detail }) => paginate(detail.currentPageIndex)}
        />
      )}





    </Box>
    
  );
};

export default ChatWidgetMessage;
