import React, { useState, useMemo } from 'react';
import { Box, Table, Pagination, Input, SpaceBetween } from '@cloudscape-design/components';

const FinancialTable = ({ title, data }) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [filterText, setFilterText] = useState('');

    const formatNumber = (value) => {
        if (value === null || value === undefined || isNaN(value)) return '';
        if (Math.abs(value) >= 1.0e9) {
            return `$${(value / 1.0e9).toFixed(2)}B`;
        } else if (Math.abs(value) >= 1.0e6) {
            return `$${(value / 1.0e6).toFixed(2)}M`;
        } else {
            return `$${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)}`;
        }
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp);
        return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(date);
    };

    const formatColumnDate = (dateString) => {
        const date = new Date(dateString);
        if (!isNaN(date)) {
            return new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(date);
        }
        return dateString;
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            return formatNumber(value);
        }
        if (typeof value === 'string' && !isNaN(Date.parse(value))) {
            return formatDate(Date.parse(value));
        }
        return value;
    };

    const filteredData = useMemo(() => {
        return Object.entries(data)
            .filter(([key]) => key.toLowerCase().includes(filterText.toLowerCase()))
            .map(([key, values]) => ({
                item: key,
                ...Object.fromEntries(Object.entries(values).map(([date, value]) => [date, formatValue(value)])),
            }));
    }, [data, filterText]);

    const pageSize = 10;
    const paginatedData = useMemo(() => {
        const start = (pageIndex - 1) * pageSize;
        return filteredData.slice(start, start + pageSize);
    }, [filteredData, pageIndex]);

    const columns = useMemo(() => {
        return [
            { header: 'Item', cell: item => item.item },
            ...Object.keys(filteredData[0] || {}).filter(k => k !== 'item').map(key => ({
                header: formatColumnDate(key),
                cell: item => (
                    <span dangerouslySetInnerHTML={{ __html: item[key] ? item[key].toLocaleString() : '' }} />
                ),
            }))
        ];
    }, [filteredData]);

    return (
        <Box>
            <h3>{title}</h3>
            <Box padding={{ bottom: 's' }}>
                <Input
                    value={filterText}
                    onChange={(e) => setFilterText(e.detail.value)}
                    placeholder="Filter items..."
                    style={{ width: '50%' }}
                />
            </Box>
            <Table
                columnDefinitions={columns}
                items={paginatedData}
                empty={<Box>No data found.</Box>}
            />
            <Pagination
                currentPageIndex={pageIndex}
                pagesCount={Math.ceil(filteredData.length / pageSize)}
                onChange={({ detail }) => setPageIndex(detail.currentPageIndex)}
            />
        </Box>
    );
};

export default FinancialTable;




