import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';

function EditGenAIAppModal({ genaiApp, visible, onClose, onGenAIAppUpdated, onGenAIAppDeleted }) {
    const [genAIAppData, setGenAIAppData] = useState({ ...genaiApp });
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        setGenAIAppData({ ...genaiApp });
    }, [genaiApp]);

    const handleInputChange = (e, field) => {
        setGenAIAppData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSaveGenAIApp = async () => {
        setSaving(true);
        try {
            const updates = {
                app_name: genAIAppData.app_name,
                created_dt: genAIAppData.created_dt,
            };
            await axiosInstance.put(`/genai_apps/${genaiApp.app_id}`, updates);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'GenAI App updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onGenAIAppUpdated({ ...genaiApp, ...updates });
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteGenAIApp = async () => {
        setDeleting(true);
        try {
            await axiosInstance.delete(`/genai_apps/${genaiApp.app_id}`);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'GenAI App deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onGenAIAppDeleted(genaiApp.app_id);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit GenAI App"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteGenAIApp}>
                        {deleting ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSaveGenAIApp}>
                        {saving ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="App ID">
                    <Input value={genAIAppData.app_id} readOnly />
                </FormField>
                <FormField label="App Name">
                    <Input value={genAIAppData.app_name} onChange={e => handleInputChange(e, 'app_name')} />
                </FormField>
                <FormField label="Created At">
                    <Input value={new Date(genAIAppData.created_dt).toLocaleDateString()} readOnly />
                </FormField>
            </Form>
        </Modal>
    );
}

export default EditGenAIAppModal;
