import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../services/axiosConfig';
import { Box, Table, Icon, Header, Button, SpaceBetween, Flashbar, Container, Popover } from '@cloudscape-design/components';
import { useNavigate, useLocation } from 'react-router-dom';

function SystemPromptTable() {
    const [systemPrompts, setSystemPrompts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchSystemPrompts = useCallback(() => {
        setLoading(true);
        axiosInstance.get('/system_prompts')
            .then(response => {
                setSystemPrompts(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching system prompts:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No System Prompts found', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchSystemPrompts();
        if (location.state && location.state.flashMessage) {
            setFlashMessages([{ type: 'success', header: 'Success', content: location.state.flashMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            navigate(location.pathname, { replace: true });
        }
    }, [fetchSystemPrompts, location, navigate]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const truncatePrompt = (prompt) => {
        const words = prompt.split(' ');
        return words.length > 14 ? words.slice(0, 14).join(' ') + '...' : prompt;
    };

    const getPopoverContent = (prompt) => {
        const words = prompt.split(' ');
        return words.length > 140 ? words.slice(0, 140).join(' ') + '...' : prompt;
    };

    const handleEditClick = (systemPrompt) => {
        navigate(`/admin/prompts/${systemPrompt.prompt_id}`);
    };

    const handleAddClick = () => {
        navigate('/admin/prompts/new');
    };

    if (loading) return <Box>Loading system prompts...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={handleAddClick}>
                            Add System Prompt
                        </Button>
                    </SpaceBetween>
                }
            >System Prompts</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'App Name', cell: item => item.app_name },
                    { 
                        header: 'Prompt', 
                        cell: item => (
                            <>
                                {truncatePrompt(item.prompt)}
                                {item.prompt.split(' ').length > 40 && (
                                    <Popover
                                        dismissButton={false}
                                        size="small"
                                        triggerType="text"
                                        content={getPopoverContent(item.prompt)}
                                    >
                                           &nbsp;<Icon name="status-info" />
                                    </Popover>
                                )}
                            </>
                        ) 
                    },
                    { header: 'Prompt Type', cell: item => item.prompt_type },
                    { header: 'Created At', cell: item => new Date(item.created_at).toLocaleDateString() },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={systemPrompts}
            />
        </Container>
    );
}

export default SystemPromptTable;


