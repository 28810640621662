import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../../../services/axiosConfig';

const userRoles = [
    { label: "AppAdmin", value: "AppAdmin" },
    { label: "WealthAdmin", value: "WealthAdmin" },
    { label: "Analyst", value: "Analyst" },
    { label: "Client", value: "Client" }
    // Add more roles as needed
];

function EditAuthUserModal({ authUser, visible, onClose, onAuthUserUpdated, onAuthUserDeleted }) {
    const [authUserData, setAuthUserData] = useState({ ...authUser });
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        setAuthUserData({ ...authUser });
    }, [authUser]);

    const handleInputChange = (e, field) => {
        setAuthUserData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e, field) => {
        setAuthUserData(prev => ({ ...prev, [field]: e.detail.selectedOption.value }));
    };

    const handleSaveAuthUser = async () => {
        setSaving(true);
        try {
            const updates = {
                user_password: authUserData.user_password,
                client_id: authUserData.client_id,
                email: authUserData.email,
                user_role: authUserData.user_role,
                date_created: authUserData.date_created,
            };
            await axiosInstance.put(`/auth_users/${authUser.user_id}`, updates);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Auth User updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onAuthUserUpdated({ ...authUser, ...updates });
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteAuthUser = async () => {
        setDeleting(true);
        try {
            await axiosInstance.delete(`/auth_users/${authUser.user_id}`);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Auth User deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onAuthUserDeleted(authUser.user_id);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Auth User"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteAuthUser}>
                        {deleting ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSaveAuthUser}>
                        {saving ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="User ID">
                    <Input value={authUserData.user_id} readOnly />
                </FormField>
                <FormField label="Password">
                    <Input value={authUserData.user_password} onChange={e => handleInputChange(e, 'user_password')} />
                </FormField>
                <FormField label="Client ID">
                    <Input value={authUserData.client_id} onChange={e => handleInputChange(e, 'client_id')} />
                </FormField>
                <FormField label="Email">
                    <Input value={authUserData.email} onChange={e => handleInputChange(e, 'email')} />
                </FormField>
                <FormField label="User Role">
                    <Select
                        selectedOption={userRoles.find(option => option.value === authUserData.user_role)}
                        onChange={e => handleSelectChange(e, 'user_role')}
                        options={userRoles}
                        placeholder="Select user role"
                    />
                </FormField>
                <FormField label="Date Created">
                    <Input value={authUserData.date_created} onChange={e => handleInputChange(e, 'date_created')} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default EditAuthUserModal;


