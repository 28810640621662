import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '@cloudscape-design/components';

function PortfolioInsert() {
    const navigate = useNavigate();
    const { clientId } = useParams(); // Get the client_id from the URL parameters
    const [portfolioData, setPortfolioData] = useState({
        portfolio_id:'',
        client_id: '',
        total_aum: '',
        performance_ytd: '',
        performance_last_year: ''
    });

    // Update the client_id in the state when the URL parameter changes
    useEffect(() => {
        setPortfolioData(prevData => ({
            ...prevData,
            client_id: clientId || ''
        }));
    }, [clientId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPortfolioData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axiosInstance.post('/portfolios/', portfolioData);
            alert('Portfolio created successfully!');
            navigate('/portfolios');
        } catch (error) {
            console.error('Error creating portfolio:', error);
            alert('Failed to create portfolio. Check console for details.');
        }
    };

    return (
        <div>
        <Container>
        <form onSubmit={handleSubmit}>
            <h1>Create Portfolio</h1>
            {/* Form fields for each portfolio attribute */}
            <label>
                Portfolio ID:
                <input name="portfolio_id" value={portfolioData.portfolio_id} onChange={handleChange} />
            </label>


            <label>
                Client ID:
                <input name="client_id" value={portfolioData.client_id} onChange={handleChange} />
            </label>
            <label>
                Total AUM:
                <input name="total_aum" value={portfolioData.total_aum} onChange={handleChange} />
            </label>
            <label>
                Performance YTD:
                <input name="performance_ytd" value={portfolioData.performance_ytd} onChange={handleChange} />
            </label>
            <label>
                Performance Last Year:
                <input name="performance_last_year" value={portfolioData.performance_last_year} onChange={handleChange} />
            </label>
            <button type="submit">Create Portfolio</button>
        </form>
        </Container>
        </div>
    );
}

export default PortfolioInsert;
