import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

function EditBeneficiaryModal({ clientId, beneficiary, visible, onClose, onBeneficiaryUpdated, onBeneficiaryDeleted }) {
    const [name, setName] = useState(beneficiary.name);
    const [relationship, setRelationship] = useState(beneficiary.relationship);
    const [dateOfBirth, setDateOfBirth] = useState(beneficiary.date_of_birth);
    const [gender, setGender] = useState(beneficiary.gender);
    const [allocationPercentage, setAllocationPercentage] = useState(beneficiary.allocation_percentage);
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        setName(beneficiary.name);
        setRelationship(beneficiary.relationship);
        setDateOfBirth(beneficiary.date_of_birth);
        setGender(beneficiary.gender);
        setAllocationPercentage(beneficiary.allocation_percentage);
    }, [beneficiary]);

    const handleSelectChange = (event, field) => {
        const value = event.detail.selectedOption.value;
        if (field === 'gender') setGender(value);
        if (field === 'relationship') setRelationship(value);
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const updatedBeneficiary = {
                client_id: clientId,
                name,
                relationship,
                date_of_birth: dateOfBirth,
                gender,
                allocation_percentage: allocationPercentage
            };
            await axiosInstance.put(`/beneficiaries/${beneficiary.beneficiary_id}`, updatedBeneficiary);
            onBeneficiaryUpdated(updatedBeneficiary); // Call the onBeneficiaryUpdated prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Beneficiary updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await axiosInstance.delete(`/beneficiaries/${beneficiary.beneficiary_id}`);
            onBeneficiaryDeleted(beneficiary.beneficiary_id); // Call the onBeneficiaryDeleted prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Beneficiary deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Beneficiary"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>
                        {loading ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Name">
                    <Input value={name} onChange={e => setName(e.detail.value)} />
                </FormField>
                <FormField label="Relationship">
                    <Select
                        selectedOption={{ label: relationship, value: relationship }}
                        onChange={(event) => handleSelectChange(event, 'relationship')}
                        options={[
                            { label: "Son", value: "Son" },
                            { label: "Daughter", value: "Daughter" },
                            { label: "Spouse", value: "Spouse" },
                        ]}
                    />
                </FormField>
                <FormField label="Date of Birth">
                    <Input type="date" value={dateOfBirth} onChange={e => setDateOfBirth(e.detail.value)} />
                </FormField>
                <FormField label="Gender" stretch={true}>
                    <Select
                        selectedOption={{ label: gender, value: gender }}
                        onChange={(event) => handleSelectChange(event, 'gender')}
                        options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                            { label: "Non-Binary", value: "Non-Binary" },
                            { label: "Prefer not to say", value: "Prefer not to say" },
                            { label: "Other", value: "Other" },
                        ]}
                    />
                </FormField>
                <FormField label="Allocation Percentage">
                    <Input type="number" value={allocationPercentage} onChange={e => setAllocationPercentage(e.detail.value)} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default EditBeneficiaryModal;
