import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/axiosConfig';
import { Box, Table, Spinner, Flashbar, Pagination } from '@cloudscape-design/components';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function SavingsAccountTrxsList({ accountId }) {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flashMessages, setFlashMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Number of rows per page

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axiosInstance.get(`/savings_accounts/${accountId}/transactions`);
        const sortedTransactions = response.data.sort((a, b) => new Date(b.transaction_date) - new Date(a.transaction_date));
        setTransactions(sortedTransactions);
        setLoading(false);
      } catch (error) {
        const errorMessage = extractErrorMessage(error);
        setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [accountId]);

  const extractErrorMessage = (error) => {
    if (error.response && error.response.data && error.response.data.detail) {
      if (Array.isArray(error.response.data.detail)) {
        const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
        return `Failed to load transactions: ${details}`;
      }
      return `Failed to load transactions: ${error.response.data.detail}`;
    } else {
      return `Failed to load transactions: ${error.message}`;
    }
  };

  const handlePageChange = ({ detail: { currentPageIndex } }) => {
    setCurrentPage(currentPageIndex);
  };

  const paginatedTransactions = transactions.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  if (loading) return <Box textAlign="center"><Spinner />Loading transactions...</Box>;

  return (
    <div>
      {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
      {transactions.length === 0 ? (
        <Box>No transactions found for this account.</Box>
      ) : (
        <>
          <Table
            columnDefinitions={[
              { header: 'Transaction Date', cell: item => formatDate(item.transaction_date) },
              { header: 'Transaction Type', cell: item => item.transaction_type },
              { header: 'Amount', cell: item => formatCurrency(item.amount) },
              { header: 'Note', cell: item => item.note || 'N/A' },
              { header: 'Transfer To', cell: item => item.transfer_to || 'N/A' },
            ]}
            items={paginatedTransactions}
          />
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={Math.ceil(transactions.length / pageSize)}
            onChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
}

export default SavingsAccountTrxsList;


