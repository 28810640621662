import React from 'react';
import { Tabs } from '@cloudscape-design/components';
import FuturesTable from './FuturesTable';

function FuturesTabs() {
    return (
        <Tabs
            tabs={[
                { label: 'Futures', id: 'futures', content: 
                            <FuturesTable title="Commodities" endpoint="/tickers/futures" /> },
                
            ]}
        />
    );
}

export default FuturesTabs;


