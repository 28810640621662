import React from 'react';

import { Tabs } from '@cloudscape-design/components';

import ETFTable from './ETFTable';

function ETFTabs() {

   return (

       <Tabs

           tabs={[

               { label: 'Most Active', id: 'most_active', content: <ETFTable title="Most Active ETFs" endpoint="/etfs/most_active" /> },

               { label: 'Top Gainers', id: 'top_gainers', content: <ETFTable title="Top Gainers ETFs" endpoint="/etfs/top_gainers" /> },

               { label: 'Top Losers', id: 'top_losers', content: <ETFTable title="Top Losers ETFs" endpoint="/etfs/top_losers" /> },

               { label: 'Trending', id: 'trending', content: <ETFTable title="Trending ETFs" endpoint="/etfs/trending" /> },

               { label: 'Best Historical Performance', id: 'best_historical', content: <ETFTable title="Best Historical Performance ETFs" endpoint="/etfs/best-historical" /> },

               { label: 'Top ETFs', id: 'top_etfs', content: <ETFTable title="Top ETFs" endpoint="/etfs/top_etfs" /> },

           ]}

       />

   );

}

export default ETFTabs;