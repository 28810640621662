import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import {
  Box, ExpandableSection, Icon, Button, Table, TextFilter, Pagination, SpaceBetween, Container, ButtonDropdown, Header, BarChart, Tabs
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import ChatWidget from './chat_widget/ChatWidget';
import InsightWidget from './widgets/InsightWidget';


const getDataForChart = (clients) => {
  const riskToleranceCount = {};
  const investmentHorizonCount = {};
  const statusCount = {};

  clients.forEach(client => {
    riskToleranceCount[client.risk_tolerance] = (riskToleranceCount[client.risk_tolerance] || 0) + 1;
    investmentHorizonCount[client.investment_horizon] = (investmentHorizonCount[client.investment_horizon] || 0) + 1;
    statusCount[client.workflow_status] = (statusCount[client.workflow_status] || 0) + 1;
  });

  return {
    riskToleranceCount,
    investmentHorizonCount,
    statusCount
  };
};

function ClientsList() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [chartData, setChartData] = useState({ riskToleranceCount: {}, investmentHorizonCount: {}, statusCount: {} });

  // Add this useLayoutEffect to handle resize observations more efficiently
  useLayoutEffect(() => {
    const resizeHandler = () => {
      window.requestAnimationFrame(() => {
        window.dispatchEvent(new CustomEvent('resize'));
      });
    };

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  useEffect(() => {
    axiosInstance.get('/clients/')
      .then(response => {
        const sortedClients = response.data.sort((a, b) => a.client_id.localeCompare(b.client_id));
        setClients(sortedClients);
        setLoading(false);
        setChartData(getDataForChart(sortedClients));
      })
      .catch(err => {
        console.error('Failed to fetch clients:', err);
        setError('Failed to load clients: ' + err.message);
        setLoading(false);
      });
  }, []);

  const {
    items: filteredAndSortedClients,
    collectionProps,
    filterProps,
    paginationProps,
    sortingProps
  } = useCollection(clients, {
    filtering: {
      empty: <Box textAlign="center">No clients found.</Box>,
      noMatch: <Box textAlign="center">No matching clients found.</Box>
    },
    pagination: { pageSize: 10 },
    sorting: {
      defaultSortingColumn: { sortingField: 'name' },
      defaultIsDescending: false,
      onSortChange: sortingProps => console.log(sortingProps)
    }
  });

  const formatCurrency = (number) => {
    if (number === null || number === undefined || number === "") {
      return 'N/A';
    }
    const numValue = Number(number);
    if (isNaN(numValue)) {
      return number;
    }
    return `$${numValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };




  if (loading) return "Loading clients...";
  if (error) return `Error: ${error}`;

  const xDomain = [...new Set([
    ...Object.keys(chartData.riskToleranceCount),
    ...Object.keys(chartData.investmentHorizonCount),
    ...Object.keys(chartData.statusCount)
  ])];

  const chartContainer = (
    <Box margin={{ bottom: "l" }}>
      <BarChart
        series={[
          {
            title: 'Risk Tolerance',
            type: 'bar',
            data: Object.entries(chartData.riskToleranceCount).map(([x, y]) => ({ x, y }))
          },
          {
            title: 'Investment Horizon',
            type: 'bar',
            data: Object.entries(chartData.investmentHorizonCount).map(([x, y]) => ({ x, y }))
          },
          {
            title: 'Status',
            type: 'bar',
            data: Object.entries(chartData.statusCount).map(([x, y]) => ({ x, y }))
          }
        ]}
        xDomain={xDomain}
        yDomain={[0, Math.max(
          ...Object.values(chartData.riskToleranceCount),
          ...Object.values(chartData.investmentHorizonCount),
          ...Object.values(chartData.statusCount)
        )]}
        yTitle="Number of Clients"
        ariaLabel="Client overview chart"
        height={180}
        fixheight={false}
        size="small"
        hideFilter={true}
      />
    </Box>
  )
  const tableContainer = (
    <Box style={{ height: '600px', overflow: 'auto' }}>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={false}
        variant="container"
        columnDefinitions={[
          {
            id: 'name',
            header: 'Name',
            cell: item => item.client_name,
            sortingField: 'name'
          },
          {
            id: 'performance_ytd',
            header: 'YTD %',
            cell: item => `${item.performance_ytd}%`,
            sortingField: 'performance_ytd'
          },

          {
            id: 'total_aum',
            header: 'AUM',
            cell: item => formatCurrency(item.total_aum),
            sortingField: 'AUM'
          },


          {
            id: 'workflow_status',
            header: 'Status',
            cell: item => item.workflow_status,
            sortingField: 'workflow_status'
          },
          {
            id: 'action',
            header: 'Action',
            cell: item => <Button href={`clients/edit/${item.client_id}`} variant="link">Portfolio</Button>
          }
        ]}
        items={filteredAndSortedClients}
        empty={<Box textAlign="center">No clients found.</Box>}
      />
    </Box>

  );

  return (
    <>
      <Container
        disableContentPaddings={false}
        header={
          <Header
            variant="h2"
            description="Here is a list of client accounts, pick a client to manage their financial goals."
            actions={
              <Link to="/clients/new" style={{ textDecoration: 'none' }}>
                <Button variant="primary">Create New Client</Button>
              </Link>
            }
          >
            Client List
          </Header>
        }
      >
          <ExpandableSection headerText='Ask'  variant="container" 
            headerInfo={<Link iconName="gen-ai" variant="info"><Icon name="gen-ai" /></Link>}>
            <ChatWidget app_name="pm_portal" entity_type="ClientList"
              context={JSON.stringify(clients)}
              entity_id="ClientList"
            />

          </ExpandableSection>

        <Tabs
          tabs={[
            {
              label: "Clients by Category",
              id: "first",
              content: chartContainer
            },
            {
              label: "Insight",
              id: "second",
              content: <InsightWidget app_name="PortfolioManagerPortal" entity_type="clients"
                context={JSON.stringify(clients)}
                prompt="Categorize clients by Performance_YTD % into top perfomers, medium performs and low performers etc and show in a pie chart. And in one sentence outline to work with low and medium perfoming clients."
              />

            },
            {
              label: "Alerts",
              id: "alerts",
              content: <InsightWidget app_name="PortfolioManagerPortal" entity_type="clients"
                context={JSON.stringify(clients)}
                prompt="List outliers that are performing low and focus on AUM and outline the uergency to fix their portfolio. Refer to client only by their name"
              />
            

              
            }


          ]}
        />

        <SpaceBetween size="l" direction="vertical">
          <TextFilter
            {...filterProps}
            placeholder="Search by name"
            size="large"
          />
        </SpaceBetween>
        <Pagination {...paginationProps} />
        {tableContainer}
        <Pagination {...paginationProps} />
      </Container>
    </>
  );
}

export default ClientsList;


