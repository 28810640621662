import React, { useState } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

function MessageCreateModal({ visible, onClose, conversationId }) {
    const [messageData, setMessageData] = useState({
        conversation_id: conversationId,
        role: '',
        content: ''
    });
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const navigate = useNavigate();

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setMessageData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newMessage = { ...messageData };
            await axiosInstance.post(`/messages`, newMessage);
            navigate(`/admin/conversations/${messageData.conversation_id}`, { state: { flashMessage: 'Message added successfully!' } });
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Add Message"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Conversation ID">
                    <Input value={messageData.conversation_id} readOnly />
                </FormField>
                <FormField label="Role">
                    <Input value={messageData.role} onChange={e => handleInputChange(e, 'role')} />
                </FormField>
                <FormField label="Content">
                    <Input value={messageData.content} onChange={e => handleInputChange(e, 'content')} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default MessageCreateModal;


