import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Textarea, Select } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

const lifeEventTypes = [
    { label: "Birth", value: "Birth" },
    { label: "Marriage", value: "Marriage" },
    { label: "Divorce", value: "Divorce" },
    { label: "New Job", value: "New Job" },
    { label: "Job Loss", value: "Job Loss" },
    { label: "Buying a Home", value: "Buying a Home" },
    { label: "Selling a Home", value: "Selling a Home" },
    { label: "Retirement", value: "Retirement" },
    { label: "Health Issue", value: "Health Issue" },
    { label: "Inheritance", value: "Inheritance" },
    { label: "Starting a Business", value: "Starting a Business" },
    { label: "Children Starting College", value: "Children Starting College" },
    { label: "Major Travel", value: "Major Travel" },
    { label: "Death of Family Member", value: "Death of Family Member" },
    { label: "Other", value: "Other" }
];

function EditLifeEventModal({ clientId, lifeEvent, visible, onClose, onLifeEventUpdated, onLifeEventDeleted }) {
    const [eventType, setEventType] = useState(lifeEvent.event_type);
    const [description, setDescription] = useState(lifeEvent.description);
    const [eventDate, setEventDate] = useState(lifeEvent.event_date);
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        setEventType(lifeEvent.event_type);
        setDescription(lifeEvent.description);
        setEventDate(lifeEvent.event_date);
    }, [lifeEvent]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const updatedLifeEvent = {
                event_type: eventType,
                description,
                event_date: eventDate
            };
            await axiosInstance.put(`/life_events/${lifeEvent.event_id}`, updatedLifeEvent);
            onLifeEventUpdated(updatedLifeEvent); // Call the onLifeEventUpdated prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Life event updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await axiosInstance.delete(`/life_events/${lifeEvent.event_id}`);
            onLifeEventDeleted(lifeEvent.event_id); // Call the onLifeEventDeleted prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Life event deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Edit Life Event"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>
                        {loading ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Event Type">
                    <Select
                        selectedOption={lifeEventTypes.find(option => option.value === eventType)}
                        onChange={e => setEventType(e.detail.selectedOption.value)}
                        options={lifeEventTypes}
                        placeholder="Select an event type"
                    />
                </FormField>
                <FormField label="Description">
                    <Textarea value={description} onChange={e => setDescription(e.detail.value)} />
                </FormField>
                <FormField label="Event Date">
                    <Input type="date" value={eventDate} onChange={e => setEventDate(e.detail.value)} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default EditLifeEventModal;
