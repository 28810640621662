// src/breadcrumb/BreadcrumbContext.js
import React, { createContext, useContext, useState } from 'react';

// Create a Context for the breadcrumbs
const BreadcrumbContext = createContext();

// Create a Provider component
export const BreadcrumbProvider = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    return (
        <BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

// Custom hook to use the BreadcrumbContext
export const useBreadcrumbs = () => {
    return useContext(BreadcrumbContext);
};
