import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import { Box, ExpandableSection, Container, Flashbar,Icon, Link, Spinner, Tabs, ColumnLayout, SpaceBetween, Header, Button } from '@cloudscape-design/components';
import ClientGoalsTable from './ClientGoalsTable';
import ClientBudgetDetail from './ClientBudgetDetail';
import ClientEdit from './client/ClientEdit';
import ClientWorkflowStatusModal from './client/ClientWorkflowStatusModal';
import { useBreadcrumbs } from './breadcrumb/BreadcrumbProvider';
import ChatWidget from './chat_widget/ChatWidget';
import InsightWidget from './widgets/InsightWidget';


function ClientPortfolio() {
    const { clientId } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const { setBreadcrumbs } = useBreadcrumbs();
    const [portfolio, setPortfolio] = useState(null);
    const [isWorkflowModalVisible, setWorkflowModalVisible] = useState(false);

    const fetchClientPortfolio = () => {
        setLoading(true);
        axiosInstance.get(`/portfolios/m_client/${clientId}`)
            .then(response => {
                setPortfolio(response.data);
                setLoading(false);
                setBreadcrumbs([
                    { text: "Clients", href: `/clients/` },
                    { text: `Client Portfolio`, href: `/clients/${clientId}` }
                ]);

                if (response.data.workflow_status === 'Registering') {
                    setFlashMessages([{ type: 'warning', header: 'Registration incomplete', content: "Provide required information to complete registration", dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
            })
            .catch(error => {
                const errorMessage = extractErrorMessage(error);
                console.error('Error fetching client:', error);
                setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                setLoading(false);
            });
    };

    useEffect(() => {
        localStorage.setItem('clientId', clientId);
        fetchClientPortfolio();

        // Check if there's a success message in the location state
        if (location.state?.flashMessage) {
            setFlashMessages([{ type: 'success', header: 'Success', content: location.state.flashMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        }
    }, [clientId, location.state]);

    const handleSuccess = (message) => {
        setFlashMessages([{ type: 'success', header: 'Success', content: message, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        fetchClientPortfolio();
    };

    const ValueWithLabel = ({ label, children }) => (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );

    const formatErrorMessage = (error) => {
        if (Array.isArray(error)) {
            return error.map(err => `${err.loc[1]} ${err.msg}`).join(', ');
        } else if (typeof error === 'object' && error !== null) {
            return JSON.stringify(error);
        }
        return error;
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Client Error: ${error.message}`;
        }
    };

    const handleWorkflowStatusButtonClick = () => {
        setWorkflowModalVisible(true);
    };

    const handleWorkflowStatusModalClose = () => {
        setWorkflowModalVisible(false);
    };

    const handleWorkflowStatusUpdated = (newStatus) => {
        setPortfolio(prev => ({ ...prev, workflow_status: newStatus }));
    };

    if (loading) return <Box textAlign="center"><Spinner />Loading...</Box>;

    let tabs;
    if (portfolio?.workflow_status === 'Registering') {
        tabs = [
            {
                label: "Client",
                id: "client",
                content: <ClientEdit clientId={clientId} onClientUpdated={() => {
                    setFlashMessages([{ type: 'success', header: 'Success', content: 'Client was updated', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                    fetchClientPortfolio();
                }} />
            }
        ];
    } else if (portfolio?.workflow_status === 'Finalize Budget') {
        tabs = [{
            label: "Income and Budget",
            id: "budget",
            content: <ClientBudgetDetail clientId={clientId} onActionSuccess={handleSuccess} />
        }];
    } else {
        tabs = [
            {
                label: "Financial Goals",
                id: "goals",
                content: <ClientGoalsTable clientId={clientId} />
            },
            {
                label: "Income and Budget",
                id: "budget",
                content: <ClientBudgetDetail clientId={clientId} onActionSuccess={handleSuccess} />
            },
            {
                label: "Client",
                id: "client",
                content: <ClientEdit clientId={clientId} onClientUpdated={() => {
                    setFlashMessages([{ type: 'success', header: 'Success', content: 'Client was updated', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                    fetchClientPortfolio();
                }} />
            },
            {
                label: "Insight",
                id: "second",
                content: <InsightWidget app_name="PortfolioManagerPortal" entity_type="clients" entity_id={clientId}
                    context={JSON.stringify(portfolio)}
                    prompt={'I am meeting with client_id: ' + clientId + ' You refer to the client with their Name ' +
                        ' Create a report to review with them outline postive in their goals and 5 year projection for each goal with a chart '}
                />

            },
            {
                label: "Alerts",
                id: "alerts",
                content: <InsightWidget app_name="PortfolioManagerPortal" entity_type="clients"
                    context={JSON.stringify(portfolio)}
                    prompt={'For client_id ' + clientId + 'Name:' + portfolio?.client_name + 'Identify deviations from client goals or budget exceeded and suggest changes. format deviations and suggested changes in a HTML Table'}
                />
            }

        ];
    }

    return (
        <Container header={
            <Header variant="h3" description="Manage Clients Financial goals using this lauch pad" actions={<Button onClick={handleWorkflowStatusButtonClick}>{portfolio?.workflow_status}</Button>}>
                {portfolio ? `Client - ${portfolio.client_name}` : 'Loading...'}
            </Header>
        }>
            <Box margin={{ top: 'l' }}>
            <ExpandableSection headerText='Ask'  variant="container"  headerInfo={<Link iconName="gen-ai" variant="info"><Icon name="gen-ai" /></Link>}>
                    <ChatWidget app_name="pm_portal" entity_type="ClientList"
                        context={JSON.stringify(portfolio)}
                        entity_id="ClientList"
                    />
                </ExpandableSection>
            </Box>

            <SpaceBetween size="xl" direction="vertical">
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                {portfolio?.workflow_status !== 'Registering' && portfolio && (
                    <ColumnLayout columns={3} variant="text-grid">
                        <ValueWithLabel label="Client Name">{portfolio?.client_name}</ValueWithLabel>
                        <ValueWithLabel label="Email">{portfolio.email}</ValueWithLabel>
                        <ValueWithLabel label="Risk tolerance">{portfolio?.risk_tolerance}</ValueWithLabel>
                        <ValueWithLabel label="Investment Horizon">{portfolio.investment_horizon}</ValueWithLabel>
                        <ValueWithLabel label="Total AUM">{formatCurrency(portfolio?.total_aum)}</ValueWithLabel>
                        <ValueWithLabel label="YTD Performance">{portfolio?.performance_ytd}%</ValueWithLabel>
                    </ColumnLayout>
                )}
                <Tabs tabs={tabs} />
                <ClientWorkflowStatusModal
                    visible={isWorkflowModalVisible}
                    onClose={handleWorkflowStatusModalClose}
                    clientId={clientId}
                    currentStatus={portfolio?.workflow_status}
                    onStatusUpdated={handleWorkflowStatusUpdated}
                />
            </SpaceBetween>
        </Container>
    );
}

export default ClientPortfolio;


