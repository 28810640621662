import React, { useState, useEffect } from 'react';
import {
    Box, Button, Container, Header, Flashbar, SpaceBetween, Spinner, Table, TextFilter, Pagination, RadioGroup, ExpandableSection
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';
import ChatWidget from '../../chat_widget/ChatWidget';


function OptionsTable({ title, endpoint }) {
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [data, setData] = useState([]);
    const [selectedTicker, setSelectedTicker] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get(endpoint);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                setFlashMessages([{
                    header: "Error",
                    content: `Failed to fetch data for ${title}.`,
                    type: "error",
                    dismissible: true,
                    onDismiss: () => setFlashMessages([])
                }]);
                setLoading(false);
            }
        }

        fetchData();
    }, [endpoint, title]);

    const { items, actions, collectionProps, filterProps, paginationProps } = useCollection(data, {
        filtering: {
            empty: <Box textAlign="center">No data found.</Box>,
            noMatch: <Box textAlign="center">No matching data found.</Box>
        },
        pagination: { pageSize: 10 },
        sorting: { sortableFields: ['symbol', 'name', 'price', 'change', 'changePercent', 'volume', 'avgVolume', 'marketCap', 'peRatio', 'weekRange'] }
    });

    const handleViewDetails = () => {
        if (selectedTicker) {
            //navigate(`/analyst/tickers/details/${selectedTicker}`);
            navigate(`/analyst/tickers/equity/${selectedTicker}`);

        } else {
            setFlashMessages([{
                header: "Error",
                content: "Please select a ticker to view details.",
                type: "error",
                dismissible: true,
                onDismiss: () => setFlashMessages([])
            }]);
        }
    };

    const formatNumber = (number) => {
        return number !== null && number !== "" && !isNaN(number) ? number.toLocaleString() : 'N/A';
    };

    const formatFloat = (number) => {
        if (number === null || number === undefined || number === "") {
            return number;
        }
        const numValue = Number(number);
        return !isNaN(numValue) ? numValue.toFixed(2) : number;
    };
  
    const formatCurrency = (number) => {
        if (number === null || number === undefined || number === "") {
            return 'N/A';
        }
        const numValue = Number(number);
        if (isNaN(numValue)) {
            return number;
        }
        return `$${numValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`;
    };
    
    const formatLargeNumber = (number) => {
        if (number === null || number === undefined || number === "") {
            return number;
        }
        const numValue = Number(number);
        if (isNaN(numValue)) {
            return String(number); // Return the original input as a string if it's not a number
        }
    
        const absValue = Math.abs(numValue);
    
        if (absValue < 1e6) {
            return formatCurrency(number); // Use formatCurrency for values less than a million
        }
    
        let result;
        if (absValue >= 1e12) {
            result = (numValue / 1e12).toFixed(2) + 'T';
        } else if (absValue >= 1e9) {
            result = (numValue / 1e9).toFixed(2) + 'B';
        } else if (absValue >= 1e6) {
            result = (numValue / 1e6).toFixed(2) + 'M';
        }
    
        // Remove trailing zeros after the decimal point
        result = result.replace(/\.?0+$/, '');
    
        // Add negative sign if the number is negative
        const sign = numValue < 0 ? '-' : '';
    
        return sign + result;
    };
    
    const truncateText = (text, maxLength = 20) => {
        if (text === null || text === undefined) {
            return 'N/A';
        }
        
        if (typeof text !== 'string') {
            return 'Invalid input';
        }
    
        if (text.length <= maxLength) {
            return text;
        }
    
        return text.slice(0, maxLength) + '…';
    };
    
    
        
    
    if (loading) return <Box textAlign="center"><Spinner /></Box>;

    return (
        <Container header={<Header variant="h3" description={`Here are today's ${title}`}
            actions={
                <Button variant="primary" onClick={handleViewDetails}>View Details</Button>
            }
        >{title}</Header>}>
            <Flashbar items={flashMessages} />
            <Box margin={{ top: 'l' }}>
            <ExpandableSection headerText='Ask Bedrock' >
               <ChatWidget app_name="AnalystPortal" entity_type="Options"
                   context={JSON.stringify(items)}
                   entity_id={title}
               />
               </ExpandableSection>
           </Box>
 
            <SpaceBetween size="l" direction="vertical">
                <Box padding={{ bottom: 's' }}>
                    <TextFilter {...filterProps} />
                </Box>
                <Table
                    {...collectionProps}
                    columnDefinitions={[
                        {
                            header: 'Select',
                            cell: item => (
                                <RadioGroup
                                    value={selectedTicker}
                                    onChange={({ detail }) => setSelectedTicker(detail.value)}
                                    items={[{ label: '', value: item.symbol }]}
                                />
                            )
                        },
                        { header: 'Symbol', cell: item => item.symbol, sortingField: 'symbol' },
                        { header: 'Name', cell: item => item.name, sortingField: 'name' },
                        { header: 'Underlying Symbol', cell: item => item['Underlying Symbol'], sortingField: 'symbol' },
                        { header: 'Strike', cell: item => item.Strike },
                        { header: 'Expiration Date', cell: item => item['Expiration Date'] },
                        { header: 'Price', cell: item => formatCurrency(item.price), sortingField: 'price' },
                        { header: 'Change', cell: item => formatCurrency(item.change), sortingField: 'change' },
                        { header: '% Change', cell: item => formatFloat(item.changePercent), sortingField: 'changePercent' },
                        { header: 'Bid', cell: item => formatCurrency(item.Bid)},
                        { header: 'Ask', cell: item => formatCurrency(item.Ask) },
                        { header: 'Volume', cell: item => formatLargeNumber(item.volume), sortingField: 'volume' },
                        { header: 'OpenInterest', cell: item => formatLargeNumber(item['Open Interest'])},


                    ]}
                    items={items}
                />
                <Pagination {...paginationProps} />
            </SpaceBetween>
        </Container>
    );
}

export default OptionsTable;


