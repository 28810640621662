import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

function UserPromptCreateModal({ visible, onClose }) {
    const [userPromptData, setUserPromptData] = useState({
        app_id: '',
        prompt_text: '',
        icon_name: ''
    });
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const [genAIApps, setGenAIApps] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGenAIApps = async () => {
            try {
                const response = await axiosInstance.get('/genai_apps');
                setGenAIApps(response.data.map(app => ({ label: app.app_name, value: app.app_id })));
            } catch (error) {
                console.error('Failed to fetch GenAI apps:', error);
            }
        };
        fetchGenAIApps();
    }, []);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setUserPromptData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e) => {
        setUserPromptData(prev => ({ ...prev, app_id: e.detail.selectedOption.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newUserPrompt = { ...userPromptData };
            await axiosInstance.post(`/user_prompts`, newUserPrompt);
            onClose();
            navigate('/admin/user_prompts', { state: { flashMessage: 'User Prompt added successfully!' } });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Add User Prompt"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit} disabled={!userPromptData.app_id || !userPromptData.prompt_text.trim() || !userPromptData.icon_name.trim()}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="App">
                    <Select
                        options={genAIApps}
                        selectedOption={genAIApps.find(option => option.value === userPromptData.app_id)}
                        onChange={handleSelectChange}
                        placeholder="Select an app"
                    />
                </FormField>
                <FormField label="Prompt Text">
                    <Input value={userPromptData.prompt_text} onChange={e => handleInputChange(e, 'prompt_text')} />
                </FormField>
                <FormField label="Icon Name">
                    <Input value={userPromptData.icon_name} onChange={e => handleInputChange(e, 'icon_name')} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default UserPromptCreateModal;


