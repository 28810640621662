import React, { forwardRef } from 'react';
import './ChatWindow.css'; // Import the consolidated CSS file

const TextareaAutoHeight = forwardRef((props, ref) => {
  return (
    <textarea
      ref={ref}
      {...props}
      className={`chat-textarea ${props.className}`}
    />
  );
});

export default TextareaAutoHeight;


