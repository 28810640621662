import React from 'react';
import { Box, ExpandableSection } from '@cloudscape-design/components';
import DOMPurify from 'dompurify';

const ChatChainOfThought = ({ snippets }) => {

    const renderContent = (message) => {
        const sanitizedHtml = DOMPurify.sanitize(message);
        // Use dangerouslySetInnerHTML with caution
        return <div  dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
      };
  return (
    <Box margin={{ top: 's', bottom: 's' }}>
        <br/><br/>
        <div className="dynamic-content">
      <ExpandableSection header="Agents chain of thought">
        <Box margin={{ top: 's' }}>
          <div className="dynamic-content">
            
            {renderContent(snippets)}
            
          </div>
        </Box>
      </ExpandableSection>
      </div>
 
    </Box>
  );
};

export default ChatChainOfThought;
