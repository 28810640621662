import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import {
  Box, Button, Table, TextFilter, Pagination, SpaceBetween, Container
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';

function PortfolioList() {
    const [portfolios, setPortfolios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { clientId } = useParams();

    useEffect(() => {
        axiosInstance.get(`/portfolios/client/${clientId}`)
            .then(response => {
                setPortfolios(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching portfolios:', error);
                setError('Failed to load portfolios' + error  + "test");
                setLoading(false);
            });
    }, [clientId]);

    const {
        items: filteredAndSortedPortfolios,
        collectionProps,
        filterProps,
        paginationProps,
        sortingProps
    } = useCollection(portfolios, {
        filtering: {
            empty: <Box textAlign="center">No portfolios found.</Box>,
            noMatch: <Box textAlign="center">No matching portfolios found.</Box>
        },
        pagination: { pageSize: 10 },
        sorting: {
            onSortChange: sortingProps => console.log(sortingProps)  // Optional: add logic here if you need to handle sorting changes
        }
    });
    
    if (loading) return "Loading portfolios...";
    if (error) return `Error: ${error}`;

    return (
        <Container>
            <SpaceBetween size="l" direction="horizontal">
                <TextFilter
                    {...filterProps}
                    placeholder="Search by client ID"
                    size="large"
                />
                <Link to={`/portfolios/new/${clientId}`} style={{ textDecoration: 'none' }}>
                    <Button variant="primary">
                        Create New Portfolio
                    </Button>
                </Link>
            </SpaceBetween>
            <Pagination {...paginationProps} />
            <Table
                {...collectionProps}
                columnDefinitions={[
                    {
                        id: 'portfolio_id',
                        header: 'Portfolio ID',
                        cell: item => item.portfolio_id,
                        sortingField: 'portfolio_id'
                    },
                    {
                        id: 'client_id',
                        header: 'Client ID',
                        cell: item => item.client_id,
                        sortingField: 'client_id'
                    },
                    {
                        id: 'total_aum',
                        header: 'Total AUM',
                        cell: item => item.total_aum,
                        sortingField: 'total_aum'
                    },
                    {
                        id: 'performance_ytd',
                        header: 'Performance YTD',
                        cell: item => `${item.performance_ytd}%`,
                        sortingField: 'performance_ytd'
                    },
                    {
                        id: 'performance_last_year',
                        header: 'Performance Last Year',
                        cell: item => `${item.performance_last_year}%`,
                        sortingField: 'performance_last_year'
                    },
                    {
                        id: 'actions',
                        header: 'Actions',
                        cell: item => (
                            <Link to={`/portfolios/${item.portfolio_id}`}>View</Link>
                        )
                    }
                ]}
                items={filteredAndSortedPortfolios}
                empty={<Box textAlign="center">No portfolios found.</Box>}
            />
            <Pagination {...paginationProps} />
        </Container>
    );
}

export default PortfolioList;
