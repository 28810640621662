import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../services/axiosConfig';
import { Box, Container, Header, Spinner, Flashbar, Tabs } from '@cloudscape-design/components';
import Summary from './Summary';
import FinancialTable from './FinancialTable';
import StatsValuation from './StatsValuation';

function StockDetails() {
    const { ticker } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/tickers/details/${ticker}`);
                setData(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch ticker details:', err);
                setError('Failed to load ticker details');
                setLoading(false);
            }
        };

        fetchData();
    }, [ticker]);

    if (loading) return <Box textAlign="center"><Spinner /> Loading...</Box>;
    if (error) return <Box>Error: {error}</Box>;

    return (
        <Container header={<Header variant="h3">Stock Details: {ticker} </Header>}>
            <Flashbar items={[]} />
            <Tabs
                tabs={[
                    { label: 'Company Info', id: 'company-info', content: <StatsValuation data={data?.stock_info || {}} /> },
                    { label: 'Balance Sheet', id: 'balance-sheet', content: <FinancialTable title="Balance Sheet" data={data?.balance_sheet || {}} /> },
                    { label: 'Cash Flow', id: 'cash-flow', content: <FinancialTable title="Cash Flow" data={data?.cashflow || {}} /> },
                    { label: 'Income Statement', id: 'income_statement', content: <FinancialTable title="Income Statement" data={data?.income_statement || {}} /> },
                    { label: 'History', id: 'date', content: <FinancialTable title="Historical Data" data={data?.data || {}} /> },
                    { label: 'Recommendations', id: 'state', content: <FinancialTable title="Recommendations" data={data?.recommendations || {}} /> },
                    { label: 'Holders', id: 'holders', content: <FinancialTable title="Holders" data={data?.holders || {}} /> },
                ]}
            />
        </Container>
    );
}

export default StockDetails;


