import React from 'react';

import { Tabs } from '@cloudscape-design/components';

import CryptoTable from './CryptoTable';

function CryptoTabs() {

   return (

       <Tabs

           tabs={[

               { label: 'All Cryptos', id: 'all_cryptos', content: <CryptoTable title="All Cryptos" endpoint="/crypto/all" /> },

               { label: 'Most Active', id: 'most_active', content: <CryptoTable title="Most Active Cryptos" endpoint="/crypto/most_active" /> },

               { label: 'Top Gainers', id: 'top_gainers', content: <CryptoTable title="Top Gainers Cryptos" endpoint="/crypto/top_gainers" /> },

               { label: 'Top Losers', id: 'top_losers', content: <CryptoTable title="Top Losers Cryptos" endpoint="/crypto/top_losers" /> },

               { label: 'Trending', id: 'trending', content: <CryptoTable title="Trending Cryptos" endpoint="/crypto/trending" /> },

           ]}

       />

   );

}

export default CryptoTabs;
