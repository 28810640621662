import React, { useEffect, useState } from 'react';
import { Grid, SpaceBetween,Box, Button } from '@cloudscape-design/components';
import Avatar from "@cloudscape-design/chat-components/avatar";
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import './ChatMessage.css';
import ChartWidget from '../chart_widget/ChartWidget';
import axiosInstance from '../../services/axiosConfig';

import { Icon } from "@cloudscape-design/components";


const ChatMessage = ({ message = [], chartData = [], snippets, message_id }) => {
    const { role, content } = message;
    const sanitizedHtml = DOMPurify.sanitize(content.map(c => c.text).join(' '));
    const [user, setUser] = useState(null);
    const [userMessage, setUserMessage] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser);
        }
        try {
            if (role === 'user' && user && message) {
                sessionStorage.setItem('userMessage', JSON.stringify(message));
                setUserMessage(message);
            } else if (role === 'assistant') {
                const storedMessage = sessionStorage.getItem('userMessage');
                if (storedMessage) {
                    const parsedMessage = JSON.parse(storedMessage);
                    setUserMessage(parsedMessage);
                }
            }
        } catch (error) {
            console.error('Error handling message:', error);
        }
    }, [message_id]);
 
    const splitContent = sanitizedHtml.split(/(<table[\s\S]*?<\/table>)/gi);

    const renderContent = () => {
        return splitContent.map((segment, index) => {
            if (segment.startsWith('<table')) {
                return (
                    <div key={index} className="dynamic-content">
                        {parse(segment)}
                    </div>
                );
            } else {
                return (
                    <div key={index} className="dynamic-content">
                        {parse(segment)}
                    </div>
                );
            }
        });
    };

    const [likeStatus, setLikeStatus] = useState(false);
    const [dislikeStatus, setDislikeStatus] = useState(false);
    
    const handleLike = async () => {
        if (userMessage) {
            try {
                const prompt = userMessage.content[0].text;
                await axiosInstance.post('/chat/manage_cache/', {
                    operation: 'extend',
                    key: prompt // Using message_id as the cache key
                });
                setLikeStatus(true);    // Set like status to true on success
                setDislikeStatus(false); // Reset dislike status
                console.log('Liked message');
            } catch (error) {
                console.error('Error submitting like:', error);
            }
        }
    };

    const handleDislike = async () => {
        if (userMessage) {
            try {
                const prompt = userMessage.content[0].text;
                await axiosInstance.post('/chat/manage_cache/', {
                    operation: 'remove',
                    key: prompt
                });
                setDislikeStatus(true);  // Set dislike status to true on success
                setLikeStatus(false);    // Reset like status
                console.log('Disliked message');
            } catch (error) {
                console.error('Error submitting dislike:', error);
            }
        }
    };


    return (
        <SpaceBetween size="xxl" direction="vertical">
            <div>
                {role === 'assistant' && (
                    <Grid
                        gridDefinition={[
                            { colspan: { default: 2, xxs: 9 } },
                            { colspan: { default: 9, xxs: 2 } }
                        ]}
                    >
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <SpaceBetween size="s" direction="horizontal">
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    <Avatar
                                        ariaLabel="Bedrock AI Agent"
                                        tooltipText="Bedrock AI Agent"
                                        color="gen-ai"
                                        iconName="gen-ai"
                                        style={{ marginRight: '8px' }}
                                    />&nbsp;{'Bedrock AI Agent '}
                                </div>
                                <div className="dynamic-content">
                                    {renderContent()}
                                    {chartData && <ChartWidget key={message_id} chartData={chartData} currentPage={message_id} />}

                                </div>
                                </SpaceBetween>
                        </div>
                        <div />

                            <Box padding={{ horizontal: 's' }} display="flex" justifyContent="flex-end">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={handleLike}  ariaLabel="Like response">
                                        <Icon name="thumbs-up" size="medium"   variant={likeStatus ? "success" : "normal"}/>                           
                                </Button>
                                <Button variant="link" onClick={handleDislike}  ariaLabel="Dislike response" >
                                    <Icon name="thumbs-down" size="medium"  variant={dislikeStatus ? "error" : "normal"}/>                           
                                </Button>
                                
                            </SpaceBetween>
                        </Box>
 
                    </Grid>
                )}
            </div>
            <div>
                {role === 'user' && user && (
                    <Grid
                        gridDefinition={[
                            { colspan: { default: 7, xxs: 5 } },
                            { colspan: { default: 5, xxs: 7 } }
                        ]}
                    >
                        <div></div>

                        <div style={{ display: 'flex' }}>
                            <Avatar
                                ariaLabel={`Avatar of ${user.user_id}`}
                                tooltipText={user.user_id}
                                textAlign='right'
                            /><div>&nbsp;{user.user_id}</div>
                            &nbsp;  &nbsp;  &nbsp;
                            <div style={{ float: "right" }} >
                                {renderContent()}
                            </div>
                        </div>

                    </Grid>
                )}
            </div>
        </SpaceBetween>
    );
};

export default ChatMessage;


