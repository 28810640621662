import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container, Popover, Icon } from '@cloudscape-design/components';
import AlertModal from './modals/AlertModal';
import EditAlertModal from './modals/EditAlertModal';

function ClientAlertsTable({ clientId }) {
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);

    const fetchAlerts = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/clients/${clientId}/alerts`)
            .then(response => {
                setAlerts(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching alerts:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No Alerts found for the client', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, [clientId]);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    const extractErrorMessage = (error) => {
        if (error.response.data.detail === "No Alerts") {
            return null;
        }
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load alerts data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load alerts data: ${error.message}`;
        }
    };

    const handleAddAlert = (alert) => {
        setAlerts(prevAlerts => [...prevAlerts, alert]);
        fetchAlerts();
    };

    const handleUpdateAlert = (updatedAlert) => {
        setAlerts(prevAlerts => prevAlerts.map(a => a.alert_id === updatedAlert.alert_id ? updatedAlert : a));
        fetchAlerts();
    };

    const handleDeleteAlert = (alertId) => {
        setAlerts(prevAlerts => prevAlerts.filter(a => a.alert_id !== alertId));
        fetchAlerts();
    };

    const handleEditClick = (alert) => {
        setSelectedAlert(alert);
        setIsEditModalOpen(true);
    };

    const truncateMessage = (message) => {
        if (message.length <= 80) return message;
        const truncated = message.slice(0, 80);
        return truncated.slice(0, truncated.lastIndexOf(' ')) + '...';
    };

    if (loading) return <Box>Loading alerts...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add Alert
                        </Button>
                    </SpaceBetween>
                }
            >Client Alerts</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { header: 'Severity', cell: item => item.severity },
                    { 
                        header: 'Message', 
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Popover 
                                    position="top" 
                                    size="small" 
                                    triggerType="hover" 
                                    content={item.message}
                                >
                                    <Box display="inline-flex" alignItems="center">
                                        {truncateMessage(item.message)}
                                        <Icon name="status-info" />
                                    </Box>
                                </Popover>
                            </SpaceBetween>
                        )
                    },
                    { header: 'Date Created', cell: item => new Date(item.date_created).toLocaleDateString() },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={alerts}
            />
            <AlertModal 
                clientId={clientId} 
                visible={isAddModalOpen} 
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchAlerts();
                }} 
                onAlertAdded={handleAddAlert} 
            />
            {selectedAlert && (
                <EditAlertModal 
                    clientId={clientId} 
                    alert={selectedAlert} 
                    visible={isEditModalOpen} 
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchAlerts();
                    }} 
                    onAlertUpdated={handleUpdateAlert}
                    onAlertDeleted={handleDeleteAlert}
                />
            )}
        </Container>
    );
}

export default ClientAlertsTable;
