import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../services/axiosConfig';
import { Box, ExpandableSection, Container, Header, Spinner, Flashbar, Tabs } from '@cloudscape-design/components';
import EquityStats from './EquityStats';
import FinancialTable from './FinancialTable';
import ChatWidget from '../../chat_widget/ChatWidget';


function EquityDetail() {
    const { ticker } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/tickers/details/${ticker}`);
                setData(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch ticker details:', err);
                setError('Failed to load ticker details');
                setLoading(false);
            }
        };

        fetchData();
    }, [ticker]);

    if (loading) return <Box textAlign="center"><Spinner /> Loading...</Box>;
    if (error) return <Box>Error: {error}</Box>;

    return (
        <Container header={<Header variant="h3">Equity Detail: {ticker} </Header>}>
            <ExpandableSection headerText='Ask Bedrock' >
               <ChatWidget app_name="AnalystPortal" entity_type="tickers"
                   context={JSON.stringify(data)}
                   entity_id={ticker}
               />
               </ExpandableSection>

            <Flashbar items={[]} />
            <Tabs
                tabs={[
                    { label: 'History', id: 'date', content: <FinancialTable title="Historical Data" data={data?.data || {}} /> },
                    { label: 'Company Info', id: 'company-info', content: <EquityStats data={data?.stock_info || {}} /> },

                ]}
            />
        </Container>
    );
}

export default EquityDetail;


