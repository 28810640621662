import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminLeftMenu from '../menu/AdminLeftMenu';
import { AppLayout } from '@cloudscape-design/components';
import AuthUserTable from './auth_user/AuthUserTable';
import ConversationTable from './conversation/ConversationTable';
import ConversationCreate from './conversation/ConversationCreate';
import ConversationEdit from './conversation/ConversationEdit';
import MessageTable from './conversation/MessageTable';
import GenAIAppTable from './genai_app/GenAIAppTable';
import SystemPromptTable from './prompt_mgmt/SystemPromptTable';
import SystemPromptCreate from './prompt_mgmt/SystemPromptCreate';
import SystemPromptEdit from './prompt_mgmt/SystemPromptEdit';
import UserPromptTable from './prompt_mgmt/UserPromptTable';
import ToolSpecTable from './genai_app/ToolSpecTable';





function AdminRouter() {
    // useMatch can be used to derive path information if needed
    //let match = useMatch('/clients/*');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <AppLayout
        navigation={<AdminLeftMenu isOpen={isMenuOpen} toggleDrawer={() => setIsMenuOpen(false)} />}
        content={
        <div>
            <h2>Admin Portal</h2>
            <Routes>
                <Route path="/users" element={<AuthUserTable />} />
                <Route path="/conversations" element={<ConversationTable />} />
                <Route path="/conversations/new" element={<ConversationCreate />} />
                <Route path="/conversations/:conversation_id" element={<ConversationEdit />} />
                <Route path="/genai_apps/tools/:app_id" element={<ToolSpecTable />} />
                <Route path="/genai_apps" element={<GenAIAppTable />} />
                <Route path="/prompts" element={<SystemPromptTable/>} />
                <Route path="/prompts/new" element={<SystemPromptCreate />} /> 
                <Route path="/prompts/:prompt_id" element={<SystemPromptEdit />} /> 
                <Route path="/user_prompts" element={<UserPromptTable />} />
                <Route path="/messages" element={<MessageTable />} />


            </Routes>
        </div>
        }
        />

    );
}

export default AdminRouter;
