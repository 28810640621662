import reportWebVitals from './reportWebVitals';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { DataProvider } from './DataContext';
import { QueryClient, QueryClientProvider } from 'react-query';

import './index.css';
import "@cloudscape-design/global-styles/index.css"


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const queryClient = new QueryClient();

// Suppress ResizeObserver loop limit exceeded errors
const suppressedErrors = /ResizeObserver loop limit exceeded/;

const originalError = console.error;
console.error = (...args) => {
    if (args[0] && suppressedErrors.test(args[0])) {
        return;
    }
    originalError.call(console, ...args);
};


/*root.render(
  <React.StrictMode>
      <CssBaseline />
      <Router>
        <App />
      </Router>
  </React.StrictMode>
);*/
root.render(
  <div>
      <CssBaseline />
      <Router>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Router>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
