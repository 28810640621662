import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../services/axiosConfig';
import { Box, Container, Header, Spinner, Flashbar, ColumnLayout, SpaceBetween, Button, ExpandableSection } from '@cloudscape-design/components';
import SavingsAccountTrxsList from './SavingsAccountTrxsList';
import DepositModal from './DepositModal'; // Import the DepositModal
import { throttle } from 'lodash';

const formatCurrency = (value) => {
   return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const formatDate = (dateString) => {
   const options = { year: 'numeric', month: 'long', day: 'numeric' };
   return new Date(dateString).toLocaleDateString(undefined, options);
};

function SavingsAccountDetail({ accountId, onActionSuccess }) {
   const [account, setAccount] = useState(null);
   const [loading, setLoading] = useState(true);
   const [flashMessages, setFlashMessages] = useState([]);
   const [refreshKey, setRefreshKey] = useState(0);
   const [isDepositModalOpen, setIsDepositModalOpen] = useState(false); // State for DepositModal visibility

   const fetchAccount = useCallback(throttle(async () => {
       setLoading(true);
       try {
           const response = await axiosInstance.get(`/savings_accounts/${accountId}`);
           setAccount(response.data);
       } catch (error) {
           const errorMessage = extractErrorMessage(error);
           setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
       } finally {
           setLoading(false);
       }
   }, 1000), [accountId]);

   useEffect(() => {
       fetchAccount();
   }, [fetchAccount]);

   const extractErrorMessage = (error) => {
       if (error.response && error.response.data && error.response.data.detail) {
           if (Array.isArray(error.response.data.detail)) {
               const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
               return `Failed to load account data: ${details}`;
           }
           return `Failed to load account data: ${error.response.data.detail}`;
       } else {
           return `Failed to load account data: ${error.message}`;
       }
   };

   const calculateSavings = async () => {
       setLoading(true);
       try {
           const response = await axiosInstance.post(`/budget/account/${accountId}/calculate_savings`);
           setAccount(response.data);
           setFlashMessages([{ type: 'success', header: 'Success', content: 'Savings calculated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           setRefreshKey(prevKey => prevKey + 1);
           onActionSuccess('Savings calculated successfully!');
       } catch (error) {
           const errorMessage = extractErrorMessage(error);
           setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
       } finally {
           setLoading(false);
       }
   };

   const handleDepositSuccess = (updatedAccount) => {
       setAccount(updatedAccount);
       setRefreshKey(prevKey => prevKey + 1);
       onActionSuccess('Deposit made successfully!');
   };

   if (loading) return <Box textAlign="center"><Spinner />Loading account...</Box>;

   return (
       <>
           <Container
               header={
                   <Header variant="h3" description="Funds in this account will fund financial goals"
                       actions={
                           <SpaceBetween direction="horizontal" size="xs">
                               <Button onClick={() => setIsDepositModalOpen(true)}>Deposit</Button> {/* New Deposit Button */}
                               <Button onClick={calculateSavings}>Calculate Savings</Button>
                           </SpaceBetween>
                       }
                   >
                       {account.account_name}
                   </Header>
               }
           >
               <SpaceBetween size="l">
                   {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                   {account && (
                       <>
                           <SpaceBetween size="l">
                               <ColumnLayout columns={3} variant="text-grid">
                                   <div>
                                       <Box variant="awsui-key-label">Balance</Box>
                                       <Box>{formatCurrency(account.balance)}</Box>
                                   </div>
                                   <div>
                                       <Box variant="awsui-key-label">Interest Rate</Box>
                                       <Box>{account.interest_rate}%</Box>
                                   </div>
                               </ColumnLayout>
                               <ExpandableSection
                                   key={"BankTransactions"}
                                   variant="stacked"
                                   headerText={"Transactions"}>
                                   <SavingsAccountTrxsList key={refreshKey} accountId={account.account_id} />
                               </ExpandableSection>
                           </SpaceBetween>
                       </>
                   )}
               </SpaceBetween>
           </Container>

           {/* DepositModal */}
           <DepositModal
               visible={isDepositModalOpen}
               onClose={() => setIsDepositModalOpen(false)}
               accountId={account.account_id}
               onDepositSuccess={handleDepositSuccess}
           />
       </>
   );
}

export default SavingsAccountDetail;

