import axios from 'axios';


// Use environment variables to get the backend API URL or fallback to localhost for development
//const baseURL_Value = process.env.REACT_APP_API_BASE_URL || "REACT_APP_API_BASE_URL_PLACEHOLDER"
const baseURL_Value =  "REACT_APP_API_BASE_URL_PLACEHOLDER"
//const baseURL_Value = "http://localhost:8000/api"

const axiosInstance = axios.create({
  baseURL: baseURL_Value,  // Placeholder will be replaced by the entrypoint script
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
