import React, { useState, useEffect } from 'react';
import { Button, Box, Form, FormField, Input, Textarea, Select, SpaceBetween, Spinner, Flashbar, Container, Header, ColumnLayout } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';

function SystemPromptEdit() {
    const { prompt_id } = useParams();
    const [systemPromptData, setSystemPromptData] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const [genAIApps, setGenAIApps] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGenAIApps = async () => {
            try {
                const response = await axiosInstance.get('/genai_apps');
                setGenAIApps(response.data.map(app => ({ label: app.app_name, value: app.app_name })));
            } catch (error) {
                console.error('Failed to fetch GenAI apps:', error);
            }
        };
        fetchGenAIApps();
    }, []);

    useEffect(() => {
        const fetchSystemPrompt = async () => {
            try {
                const response = await axiosInstance.get(`/system_prompts/${prompt_id}`);
                setSystemPromptData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch system prompt:', error);
                navigate('/admin/prompts');
            }
        };
        fetchSystemPrompt();
    }, [prompt_id, navigate]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setSystemPromptData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e) => {
        setSystemPromptData(prev => ({ ...prev, app_name: e.detail.selectedOption.value }));
    };

    const handleSaveSystemPrompt = async () => {
        setSaving(true);
        try {
            const updates = {
                app_id: systemPromptData.app_name,
                prompt: systemPromptData.prompt,
                prompt_type: systemPromptData.prompt_type,
                created_at: systemPromptData.created_at,
            };
            await axiosInstance.put(`/system_prompts/${prompt_id}`, updates);
            navigate('/admin/prompts', { state: { flashMessage: 'System Prompt updated successfully!' } });
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    const handleDeleteSystemPrompt = async () => {
        setDeleting(true);
        try {
            await axiosInstance.delete(`/system_prompts/${prompt_id}`);
            navigate('/admin/prompts', { state: { flashMessage: 'System Prompt deleted successfully!' } });
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setDeleting(false);
        }
    };

    const handleCancel = () => {
        navigate('/admin/prompts');
    };

    const isFormValid = () => {
        return systemPromptData.app_name && systemPromptData.prompt.trim() !== '' && systemPromptData.prompt_type.trim() !== '';
    };

    if (loading) return <Box>Loading system prompt...</Box>;

    return (
        <Container header={<Header>Edit System Prompt</Header>}>
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Form actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={handleCancel}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteSystemPrompt}>
                        {deleting ? <Spinner /> : 'Delete'}
                    </Button>
                    <Button variant="primary" onClick={handleSaveSystemPrompt} disabled={!isFormValid()}>
                        {saving ? <Spinner /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }>
                <SpaceBetween size='xl' variant='vertical'>
                    <ColumnLayout columns={2}>
                        <FormField label="App Name">
                            <Select
                                options={genAIApps}
                                selectedOption={genAIApps.find(option => option.value === systemPromptData.app_name)}
                                onChange={handleSelectChange}
                                placeholder="Select an app"
                            />
                        </FormField>
                        <FormField label="Prompt Type">
                            <Input value={systemPromptData.prompt_type} onChange={e => handleInputChange(e, 'prompt_type')} />
                        </FormField>
                    </ColumnLayout>
                    <FormField label="Prompt">
                        <Textarea
                            value={systemPromptData.prompt}
                            onChange={e => handleInputChange(e, 'prompt')}
                            placeholder="Enter the prompt"
                            rows="14"
                            style={{ width: '100%', height: '600px' }}
                        />
                    </FormField>
                </SpaceBetween>
            </Form>
        </Container>
    );
}

export default SystemPromptEdit;

