import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../services/axiosConfig';
import { Box, Container, Header, Button, SpaceBetween, Flashbar, ColumnLayout, Spinner, Input, Form, FormField } from '@cloudscape-design/components';
import SavingsAccountDetail from './savings/SavingsAccountDetail';

const formatCurrency = (value) => {
   return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

function ClientBudgetDetail({ clientId, onActionSuccess }) {
   const [budget, setBudget] = useState(null);
   const [loading, setLoading] = useState(true);
   const [flashMessages, setFlashMessages] = useState([]);

   const fetchBudget = useCallback(() => {
       setLoading(true);
       axiosInstance.get(`/budget/${clientId}`)
           .then(response => {
               setBudget(response.data);
               setLoading(false);
           })
           .catch(err => {
               console.error('Error fetching budget:', err);
               const errorMessage = extractErrorMessage(err);
               setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
               setLoading(false);
           });
   }, [clientId]);

   useEffect(() => {
       fetchBudget();
   }, [fetchBudget]);

   const extractErrorMessage = (error) => {
       if (error.response && error.response.data && error.response.data.detail) {
           if (error.response.data.detail.map instanceof Function){
               const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
               return `Failed to load budget data: ${details}`;
           }
           return `Failed to load budget data: ${error.response.data.detail}`;
       } else {
           return `Failed to load budget data: ${error.message}`;
       }
   };

   const handleInputChange = ({ detail: { value } }, name) => {
       setBudget(prevData => {
           const updatedBudget = { ...prevData, [name]: parseFloat(value) };
           if (updatedBudget.monthly_income && updatedBudget.monthly_expenses) {
               updatedBudget.monthly_savings = updatedBudget.monthly_income - updatedBudget.monthly_expenses;
           }
           return updatedBudget;
       });
   };

   const handleSubmit = async (event) => {
       event.preventDefault();
       setLoading(true);
       try {
           const updatedFields = {
               monthly_income: budget.monthly_income,
               monthly_expenses: budget.monthly_expenses,
               monthly_savings: budget.monthly_savings,
           };
           await axiosInstance.put(`/budget/${budget.budget_id}`, updatedFields);
           setFlashMessages([{ type: 'success', header: 'Success', content: 'Budget updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           setLoading(false);
           onActionSuccess('Budget updated successfully!');
       } catch (error) {
           const errorMessage = extractErrorMessage(error);
           console.error('Error updating budget:', error);
           setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
           setLoading(false);
       }
   };

   if (loading) return <Box textAlign="center"><Spinner />Loading budget...</Box>;

   return (
       <>
       <SpaceBetween size="l">
       <Container
           header={<Header description="Monthly Income and Monthy Expenses will drive funds for financial goals."  
                    variant="h3">Budget Details</Header>}
       >
           {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
           {budget && (
               <Form onSubmit={handleSubmit}
                    actions={
                       <SpaceBetween direction="horizontal" size="xs">
                           <Button formAction="none" href="/client" variant="link">
                               Cancel
                           </Button>
                           <Button variant="primary" onClick={handleSubmit}>Update Budget</Button>
                       </SpaceBetween>
                   }
               >
                   <ColumnLayout columns={3} variant="text-grid">
                      
                       <div>
                           <FormField label="Monthly Income">
                               <Input
                                   value={budget.monthly_income}
                                   onChange={(event) => handleInputChange(event, 'monthly_income')}
                                   type="currency"
                               />
                           </FormField>
                       </div>
                       <div>
                           <FormField label="Monthly Expenses">
                               <Input
                                   value={budget.monthly_expenses}
                                   onChange={(event) => handleInputChange(event, 'monthly_expenses')}
                                   type="currency"
                               />
                           </FormField>
                       </div>
                       <div>
                           <FormField label="Monthly Savings">
                               <Input
                                   value={budget.monthly_savings}
                                   onChange={(event) => handleInputChange(event, 'monthly_savings')}
                                   type="currency"
                              
                               />
                           </FormField>
                       </div>
                   </ColumnLayout>
               </Form>
              
           )}
       </Container>
        {budget &&
           <SavingsAccountDetail accountId={budget.savings_account_id} onActionSuccess={onActionSuccess} />
        }
       </SpaceBetween>   
       </>
   );
}

export default ClientBudgetDetail;

