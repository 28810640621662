import React, { useState, useEffect } from 'react';
import { Button, Form, FormField, Input, Textarea, Select, SpaceBetween, Spinner, Flashbar, Container, Header, ColumnLayout } from '@cloudscape-design/components';
import axiosInstance from '../../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

function SystemPromptCreate() {
    const [systemPromptData, setSystemPromptData] = useState({
        app_id: '',
        prompt: '',
        prompt_type: ''
    });
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const [genAIApps, setGenAIApps] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGenAIApps = async () => {
            try {
                const response = await axiosInstance.get('/genai_apps');
                setGenAIApps(response.data.map(app => ({ label: app.app_name, value: app.app_id })));
            } catch (error) {
                console.error('Failed to fetch GenAI apps:', error);
            }
        };
        fetchGenAIApps();
    }, []);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleInputChange = (e, field) => {
        setSystemPromptData(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e) => {
        setSystemPromptData(prev => ({ ...prev, app_id: e.detail.selectedOption.value }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newSystemPrompt = { ...systemPromptData };
            await axiosInstance.post('/system_prompts', newSystemPrompt);
            navigate('/admin/prompts', { state: { flashMessage: 'System Prompt added successfully!' } });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/admin/prompts');
    };

    const isFormValid = () => {
        return systemPromptData.app_id && systemPromptData.prompt.trim() !== '' && systemPromptData.prompt_type.trim() !== '';
    };

    return (
        <Container header={<Header>Add System Prompt</Header>}>
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Form actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={handleCancel}>Cancel</Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={!isFormValid()}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }>
                <SpaceBetween size='xl' variant='vertical'>
                    <ColumnLayout columns={2}>
                        <FormField label="App ID">
                            <Select
                                options={genAIApps}
                                selectedOption={genAIApps.find(option => option.value === systemPromptData.app_id)}
                                onChange={handleSelectChange}
                                placeholder="Select an app"
                            />
                        </FormField>
                        <FormField label="Prompt Type">
                            <Input value={systemPromptData.prompt_type} onChange={e => handleInputChange(e, 'prompt_type')} />
                        </FormField>
                    </ColumnLayout>
                    <FormField label="Prompt">
                        <Textarea
                            value={systemPromptData.prompt}
                            onChange={e => handleInputChange(e, 'prompt')}
                            placeholder="Enter the prompt"
                            rows="14"
                            style={{ width: '100%', height: '600px' }}
                        />
                    </FormField>
                </SpaceBetween>
            </Form>
        </Container>
    );
}

export default SystemPromptCreate;
