import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, Input, Button, FormField, Container, Header, SpaceBetween, Form, Flashbar, Select, Box, Spinner, ColumnLayout } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';
import ClientCommunicationsTable from '../ClientCommunicationsTable';
import ClientAlertsTable from '../ClientAlertsTable';
import ClientLifeEventsTable from '../ClientLifeEventsTable';
import ClientBeneficiariesTable from '../ClientBeneficiariesTable';


function ClientEdit({ onClientUpdated }) {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);

    useEffect(() => {
        axiosInstance.get(`/clients/${clientId}`)
            .then(response => {
                setClient(response.data);
                setLoading(false);
            })
            .catch(error => {
                const errorMessage = extractErrorMessage(error);
                console.error('Error fetching client:', error);
                setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                setLoading(false);
            });
    }, [clientId]);

    const handleInputChange = ({ detail: { value } }, name) => {
        setClient(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (event, name) => {
        setClient(prevData => ({
            ...prevData,
            [name]: event.detail.selectedOption.value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        // Create a new object excluding client_id
        const { client_id, ...clientData } = client;

        try {
            await axiosInstance.put(`/clients/${client.client_id}`, clientData);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Client updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            setLoading(false);
            if (onClientUpdated) onClientUpdated();
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            setLoading(false);
        }
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this client?')) {
            axiosInstance.delete(`/clients/${client.client_id}`)
                .then(() => {
                    setFlashMessages([{ type: 'success', header: 'Success', content: 'Client deleted successfully', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                    navigate('/clients');
                })
                .catch(error => {
                    const errorMessage = extractErrorMessage(error);
                    console.error('Error deleting client:', error);
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                });
        }
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Client Error: ${error.message}`;
        }
    };
    let tabs;

    tabs = [
        {
            label: "Beneficiaries",
            id: "beneficiaries",
            content: <ClientBeneficiariesTable clientId={clientId} />
        },
        {
            label: "Communication",
            id: "communication",
            content: <ClientCommunicationsTable clientId={clientId} />
        },
        {
            label: "Life Events",
            id: "life_events",
            content: <ClientLifeEventsTable clientId={clientId} />
        },
        {
            label: "Alerts",
            id: "alerts",
            content: <ClientAlertsTable clientId={clientId} />
        },
        
    ];


    if (loading) return <Box textAlign="center"><Spinner />Loading...</Box>;

    if (!client) return <Box textAlign="center"><Spinner />Loading...</Box>;

    return (
        <>
            
        <Container
            header={<Header variant="h1" description="Provide all the information including registration date to complete registration">
                Client Registration
            </Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}

            <form onSubmit={handleSubmit}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction="none" href="/" variant="link">
                                Cancel
                            </Button>
                            <Button variant="negative" onClick={handleDelete}>
                                Delete Client
                            </Button>
                            <Button variant="primary">Update Client</Button>
                        </SpaceBetween>
                    }
                >
                    <Input type="hidden" name='client_id' value={client.client_id} />

                    <SpaceBetween size="l" direction="vertical">
                        <ColumnLayout columns={3}>
                            <FormField label="Name" stretch={true}>
                                <Input
                                    value={client.name}
                                    onChange={(event) => handleInputChange(event, 'name')}
                                    name="name"
                                />
                            </FormField>
                            <FormField label="Gender" stretch={true}>
                                <Select
                                    selectedOption={{ label: client.gender, value: client.gender }}
                                    onChange={(event) => handleSelectChange(event, 'gender')}
                                    options={[
                                        { label: "Male", value: "Male" },
                                        { label: "Female", value: "Female" },
                                        { label: "Non-Binary", value: "Non-Binary" },
                                        { label: "Prefer not to say", value: "Prefer not to say" },
                                        { label: "Other", value: "Other" },
                                    ]}
                                />
                            </FormField>
                            <FormField label="Email" stretch={true}>
                                <Input
                                    value={client.email}
                                    onChange={(event) => handleInputChange(event, 'email')}
                                    name="email"
                                    type="email"
                                />
                            </FormField>
                            <FormField label="Date of Birth" stretch={true}>
                                <Input
                                    value={client.date_of_birth}
                                    onChange={(event) => handleInputChange(event, 'date_of_birth')}
                                    name="date_of_birth"
                                    type="date"
                                />
                            </FormField>
                            <FormField label="Marital Status" stretch={true}>
                                <Select
                                    selectedOption={{ label: client.marital_status, value: client.marital_status }}
                                    onChange={(event) => handleSelectChange(event, 'marital_status')}
                                    options={[
                                        { label: "Married", value: "Married" },
                                        { label: "Single", value: "Single" },
                                    ]}
                                />
                            </FormField>
                            <FormField label="Risk Tolerance" stretch={true}>
                                <Select
                                    selectedOption={{ label: client.risk_tolerance, value: client.risk_tolerance }}
                                    onChange={(event) => handleSelectChange(event, 'risk_tolerance')}
                                    options={[
                                        { label: "Low", value: "Low" },
                                        { label: "Moderate", value: "Moderate" },
                                        { label: "High", value: "High" },
                                    ]}
                                />
                            </FormField>
                            <FormField label="Investment Horizon" stretch={true}>
                                <Select
                                    selectedOption={{ label: client.investment_horizon, value: client.investment_horizon }}
                                    onChange={(event) => handleSelectChange(event, 'investment_horizon')}
                                    options={[
                                        { label: "Short-Term", value: "Short-Term" },
                                        { label: "Medium-Term", value: "Medium-Term" },
                                        { label: "Long-Term", value: "Long-Term" },
                                    ]}
                                />
                            </FormField>
                            <FormField label="Registration Date" stretch={true}>
                                <Input
                                    value={client.registration_date}
                                    onChange={(event) => handleInputChange(event, 'registration_date')}
                                    name="registration_date"
                                    type="date"
                                />
                            </FormField>
                        </ColumnLayout>
                    </SpaceBetween>
                </Form>
            </form>

        </Container>
        <Tabs tabs={tabs} />
        </>
    );
}

export default ClientEdit;


