import React, { useState } from 'react';
import { Modal, Form, FormField, Input, Button, SpaceBetween, Spinner, Flashbar, Select } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

const goalTypes = [
    { label: "Retirement", value: "Retirement" },
    { label: "Investment", value: "Investment" },
    { label: "Education", value: "Education" },
    { label: "Emergency Fund", value: "Emergency" },
    { label: "Major Purchase", value: "Major Purchase" },
    { label: "Vacation", value: "Vacation" },
    { label: "Pay off Debt", value: "Pay off Debt" },
    { label: "Other", value: "Other" }
];

const CreateGoalModal = ({ clientId, visible, onClose, onGoalCreated }) => {
    const [newGoal, setNewGoal] = useState({
        client_id: clientId,
        goal: '',
        goal_type: '',
        target_amount: '',
        term: ''
    });
    const [saving, setSaving] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    const handleInputChange = (e, field) => {
        setNewGoal(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleGoalTypeChange = (e) => {
        setNewGoal(prev => ({ ...prev, goal_type: e.detail.selectedOption.value }));
    };

    const handleSaveNewGoal = async () => {
        setSaving(true);
        try {
            await axiosInstance.post(`/goals`, newGoal);
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Goal created successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose();
            onGoalCreated();
        } catch (error) {
            const errorMessage = error.response?.data?.detail || error.message;
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setSaving(false);
        }
    };

    return (
        <Modal
            onDismiss={onClose}
            visible={visible}
            closeAriaLabel="Close modal"
            header="Create New Goal"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button variant="primary" onClick={handleSaveNewGoal}>
                        {saving ? <Spinner variant='inverted' /> : 'Save'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <Input type="hidden" value={newGoal.client_id} readOnly />

                <FormField label="Goal">
                    <Input value={newGoal.goal} onChange={e => handleInputChange(e, 'goal')} />
                </FormField>
                <FormField label="Goal Type">
                    <Select
                        selectedOption={goalTypes.find(option => option.value === newGoal.goal_type)}
                        onChange={handleGoalTypeChange}
                        options={goalTypes}
                        placeholder="Select a goal type"
                    />
                </FormField>
                <FormField label="Target Amount">
                    <Input value={newGoal.target_amount} onChange={e => handleInputChange(e, 'target_amount')} />
                </FormField>
                <FormField label="Term">
                    <Input value={newGoal.term} onChange={e => handleInputChange(e, 'term')} />
                </FormField>

            </Form>
        </Modal>
    );
};

export default CreateGoalModal;
