import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig';
import ClientBadge from './ClientBadge';
import {
  Box, SpaceBetween, Button, Container, Header, Table, TextFilter, Pagination, 
  ButtonDropdown, Tabs, Grid, Flashbar, Spinner
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useBreadcrumbs } from './breadcrumb/BreadcrumbProvider';


function PortfolioDetails() {
    const { portfolioId } = useParams();
    const [portfolioDetails, setPortfolioDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [flashMessages, setFlashMessages] = useState([]);
    const { setBreadcrumbs } = useBreadcrumbs();


    const numericComparator = (a, b) => {
        return Number(a) - Number(b);
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    useEffect(() => {
        console.log('Fetching portfolio details for portfolio ID:', portfolioId);
        axiosInstance.get(`/portfolios/details/${portfolioId}`)
            .then(response => {
                setPortfolioDetails(response.data);
                setLoading(false);
                setBreadcrumbs([
                    { text: "Portfolio",  href: `/portfolios/${portfolioId}` },
                    { text: `Portfolio Details (${portfolioId})`, href: `/portfolios/details/${portfolioId}` }
                ]);

            })
            .catch(err => {
                console.error('Failed to fetch portfolio details:', err);
                setError('Failed to load portfolio details' + err);
                setLoading(false);
            });
    }, [portfolioId, setBreadcrumbs]);

    const handleDelete = async (investmentId) => {
        try {
            setLoading(true);
            await axiosInstance.delete(`/investments/${investmentId}`);
            setFlashMessages(prevMessages => [
                ...prevMessages,
                {
                    header: "Success",
                    content: "Investment deleted successfully.",
                    type: "success",
                    dismissible: true,
                    onDismiss: () => setFlashMessages(prevMessages => prevMessages.filter(msg => msg.content !== "Investment deleted successfully."))
                }
            ]);
            // Optionally, you can refresh the portfolio details after deletion
            setPortfolioDetails(prevDetails => ({
                ...prevDetails,
                investments: prevDetails.investments.filter(inv => inv.investment_id !== investmentId)
            }));
        } catch (err) {
            console.error('Failed to delete investment:', err);
            const errorMessage = err.response?.data?.detail || err.message || 'Failed to delete investment.';
            setFlashMessages(prevMessages => [
                ...prevMessages,
                {
                    header: "Error",
                    content: `Failed to delete investment: ${errorMessage}`,
                    type: "error",
                    dismissible: true,
                    onDismiss: () => setFlashMessages(prevMessages => prevMessages.filter(msg => msg.content !== `Failed to delete investment: ${errorMessage}`))
                }
            ]);
        }
        setLoading(false);
    };

    const investmentCollection = useCollection(portfolioDetails?.investments || [], {
        filtering: {
            empty: <Box textAlign="center">No investments found.</Box>,
            noMatch: <Box textAlign="center">No matching investments found.</Box>
        },
        pagination: { pageSize: 5 },
        sorting: {
            sortableFields: ['investment_type', 'investment_value'],
            comparators: {
                investment_value: numericComparator
            }
        }
    });

    if (loading) return <Box textAlign="center"><Spinner /></Box>;
    if (error) return <Box>Error: {error}</Box>;

    return (
    
        <Container header={<Header variant="h3">Portfolio Detail
            <ClientBadge clientId={portfolioDetails?.portfolio.client_id} /></Header>}>
            <Flashbar items={flashMessages} />
            <Grid gridDefinition={[
                { colspan: { default: 9, xxs: 3 } },
                { colspan: { default: 3, xxs: 9 } }
            ]}>
                <div>
                    <Box>
                        <p><strong>Total AUM:</strong> {formatCurrency(portfolioDetails?.portfolio.total_aum)}</p>

                        <p><strong>Performance YTD:</strong> {portfolioDetails?.portfolio.performance_ytd}%</p>
                        <p><strong>Performance Last Year:</strong> {portfolioDetails?.portfolio.performance_last_year}%</p>
                    </Box>
                </div>
                <div>
                    <Tabs
                        tabs={[
                            {
                                label: "Alerts",
                                id: "alerts",
                                content: (
                                    <Table
                                        columnDefinitions={[
                                            { header: 'Alert Message', cell: item => item.message },
                                        ]}
                                        items={portfolioDetails?.alerts || []}
                                        empty={<Box>No alerts found.</Box>}
                                    />
                                )
                            },
                            {
                                label: "Social & Life Events",
                                id: "second",
                                content: (
                                    <Table
                                        columnDefinitions={[
                                            { header: 'Event Type', cell: item => item.event_type },
                                            { header: 'Description', cell: item => item.description },
                                            { header: 'Date', cell: item => new Date(item.event_date).toLocaleDateString() },
                                        ]}
                                        items={portfolioDetails?.life_events || []}
                                        empty={<Box>No life events found.</Box>}
                                    />
                                )
                            },
                            {
                                label: "Communications",
                                id: "third",
                                content: (
                                    <Table
                                        columnDefinitions={[
                                            { header: 'Communication Type', cell: item => item.communication_type },
                                            { header: 'Notes', cell: item => item.notes },
                                            { header: 'Date Created', cell: item => new Date(item.date_created).toLocaleDateString() },
                                        ]}
                                        items={portfolioDetails?.communications || []}
                                        empty={<Box>No communications found.</Box>}
                                    />
                                )
                            }
                        ]}
                        variant="container"
                    />
                </div>
            </Grid>
            <Box>
                <h3>Investments</h3>
                <SpaceBetween size="xl" direction="horizontal">
                    <TextFilter {...investmentCollection.filterProps} placeholder="Search investments" />
                    <Link to="investments/new" style={{ textDecoration: 'none' }}>
                        <Button variant="primary">
                            Create Investment
                        </Button>
                    </Link>
                </SpaceBetween>
                <Pagination {...investmentCollection.paginationProps} />
                <Table
                    {...investmentCollection.collectionProps}
                    columnDefinitions={[
                        { header: 'Investment ID', cell: item => item.investment_id },
                        { header: 'Type', cell: item => item.investment_type, sortingField: 'investment_type' },
                        { header: 'Optimization Technique', cell: item => item.optimization_technique },
                        { header: 'Value', cell: item => formatCurrency(item.investment_value), sortingField: 'investment_value' },
                        {
                            header: 'Action', cell: item => (
                                <ButtonDropdown
                                    items={[
                                        {
                                            id: "Detail",
                                            text: "Detail",
                                            href: "investments/" + item.investment_id,
                                        },
                                        {
                                            id: "rm",
                                            text: (
                                                <Button variant="link" onClick={() => handleDelete(item.investment_id)}>
                                                    Delete
                                                </Button>
                                            ),
                                        },
                                    ]}
                                    expandToViewport="true"
                                >
                                    Actions
                                </ButtonDropdown>
                            )
                        }
                    ]}
                    items={investmentCollection.items}
                    empty={<Box>No investments found.</Box>}
                />
                <Pagination {...investmentCollection.paginationProps} />
            </Box>
        </Container>
    );
}

export default PortfolioDetails;
