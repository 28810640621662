import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ChatWidgetMessage from './ChatWidgetMessage';
import ChatWidgetInput from './ChatWidgetInput';
import { Box } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';
import './ChatWidget.css'; // Import the consolidated CSS file

const ChatWidget = ({ app_name, entity_type, context, entity_id }) => {
    const [messages, setMessages] = useState([]);
    const [conversationId, setConversationId] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [snippets, setSnippets] = useState(null);
    const { conversation_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (conversation_id) {
            fetchConversation(conversation_id);
        } else {
            startNewConversation();
        }
    }, [context, conversation_id]);

    const handleSendMessage = (text) => {
        setMessages([...messages, { role: 'user', content: [{ text }] }]);
    };

    const handleReceiveMessage = (text, conversationId, chartData, snippets) => {
        setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: [{ text }], snippets: [{ snippets }] }]);
        setChartData(prevChartData => ({ ...prevChartData, ...chartData }   ));
        setSnippets(snippets);
        setConversationId(conversationId);
        console.log("chartData", chartData);
        console.log("snippets", snippets);
    };

    const fetchConversation = async (conversationId) => {
        try {
            const response = await axiosInstance.get(`/conversations/${conversationId}`);
            const { conversation, messages } = response.data;
            setMessages(messages);
            setConversationId(conversation.conversation_id);
        } catch (error) {
            console.error('Error fetching conversation:', error);
        }
    };

    const startNewConversation = async () => {
        try {
            const response = await axiosInstance.post('/chat/', {
                app_name,
                entity_type,
                entity_id,
                context,
                conversation_id: null
            });
            const { conversation_id, messages } = response.data;
            setMessages(messages);
            setConversationId(conversation_id);
            navigate(`/chat/${conversation_id}`);
        } catch (error) {
            console.error('Error starting new conversation:', error);
        }
    };

    return (
        <Box className="chat-container">
            <ChatWidgetInput
                onReceiveMessage={handleReceiveMessage}
                onSendMessage={handleSendMessage}
                app_name={app_name}
                entity_type={entity_type}
                context={context}
                conversationId={conversationId}
                style={{ width: '200px', margin: '0 auto' }}
            />
            <div className="chat-window">
                <ChatWidgetMessage snippets={snippets} chartData={chartData} messages={messages || []} style={{ width: '600px', margin: '0 auto' }} />
            </div>
        </Box>
    );
};

export default ChatWidget;


