import React, { useState } from 'react';
import { Modal, Button, Form, FormField, Input, SpaceBetween, Spinner, Flashbar, Select, Textarea } from '@cloudscape-design/components';
import axiosInstance from '../../services/axiosConfig';

function CommunicationModal({ clientId, visible, onClose, onCommunicationAdded }) {
    const [communicationType, setCommunicationType] = useState('');
    const [notes, setNotes] = useState('');
    const [direction, setDirection] = useState('');
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);

    const handleSelectChange = (event, field) => {
        const value = event.detail.selectedOption.value;
        if (field === 'communicationType') setCommunicationType(value);
        if (field === 'direction') setDirection(value);
    };

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const newCommunication = { 
                client_id: clientId,
                communication_type: communicationType, 
                notes, 
                direction,
            };
            await axiosInstance.post(`/communications`, newCommunication);
            onCommunicationAdded(newCommunication); // Call the onCommunicationAdded prop to update the parent component
            setFlashMessages([{ type: 'success', header: 'Success', content: 'Communication added successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
            onClose(); // Close the modal
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            onDismiss={onClose}
            header="Add Communication"
            footer={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>
                        {loading ? <Spinner /> : 'Insert'}
                    </Button>
                </SpaceBetween>
            }
        >
            <Form>
                {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
                <FormField label="Communication Type">
                    <Select
                        selectedOption={{ label: communicationType, value: communicationType }}
                        onChange={(event) => handleSelectChange(event, 'communicationType')}
                        options={[
                            { label: "Email", value: "Email" },
                            { label: "Phone Call", value: "Phone Call" },
                            { label: "Virtual Meeting", value: "Virtual Meeting" },
                            { label: "In-Person Meeting", value: "In-Person Meeting" },
                            { label: "Messaging", value: "Messaging" },
                            { label: "Bedrock Chat", value: "Bedrock Chat" },
                            { label: "Other", value: "Other" },
                        ]}
                    />
                </FormField>
                <FormField label="Direction">
                    <Select
                        selectedOption={{ label: direction, value: direction }}
                        onChange={(event) => handleSelectChange(event, 'direction')}
                        options={[
                            { label: "Incoming", value: "Incoming" },
                            { label: "Outgoing", value: "Outgoing" },
                        ]}
                    />
                </FormField>
                <FormField label="Notes">
                    <Textarea value={notes} onChange={e => setNotes(e.detail.value)} />
                </FormField>
            </Form>
        </Modal>
    );
}

export default CommunicationModal;
