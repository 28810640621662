import React from 'react';
import { Tabs } from '@cloudscape-design/components';
import BondsTable from './BondsTable';

function FuturesTabs() {
    return (
        <Tabs
            tabs={[
                { label: 'Bonds', id: 'bonds', content: 
                            <BondsTable title="Bonds" endpoint="/tickers/bonds" /> },
                
            ]}
        />
    );
}

export default FuturesTabs;


