import React, { useState, useEffect, useRef } from 'react';
import { Button, SpaceBetween, Spinner } from '@cloudscape-design/components';
import TextareaAutoHeight from './TextareaAutoHeight';
import './ChatWindow.css'; // Import the consolidated CSS file
import axiosInstance from '../../services/axiosConfig';

const ChatInput = ({ onReceiveMessage, onSendMessage }) => {
  const [text, setText] = useState('');
  const [conversationId, setConversationId] = useState(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(null);
  const chatContainerRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (text.trim()) {
      onSendMessage(text); // Display the user's message in the UI
      const response = await sendMessage(text, conversationId);
      onReceiveMessage(response.response, response.conversation_id, response.chartData, response.snippets, response.message_id);
      setConversationId(response.conversation_id);
      setText('');
    }
  };

  const sendMessage = async (prompt, conversationId) => {
    setLoading(true);
    // Send a request to the server to generate a response
    try {
      const response = await axiosInstance.post('/chat/', {
        prompt: prompt,
        conversation_id: conversationId,
        app_name: "ClientPortal",
        entity_type: "client_id",
        entity_id: clientId
      });
      return response.data;
    } catch (error) {
      console.error('Error sending message:', error);
      return { response: 'Error occurred', conversation_id: conversationId };
    } finally {
      setLoading(false);
      scrollToBottom(); // Scroll to bottom after response comes back
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.client_id) {
      setClientId(user.client_id);
    }

    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [text]);

  return (
    <div className="chat-container" ref={chatContainerRef}>
      <form onSubmit={handleSubmit} className="chat-input-form">
        <SpaceBetween direction="horizontal" size="s" className="chat-input-space">
          <div className="textarea-container">
            <TextareaAutoHeight
              ref={inputRef}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Type a message..."
              className="chat-textarea"
            />
          </div>
          <Button variant="primary" iconName="send" iconAlign="right" type="submit">
            {loading ? <Spinner variant='inverted' /> : 'Send'}
          </Button>
        </SpaceBetween>
      </form>
    </div>
  );
};

export default ChatInput;
