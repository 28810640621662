import React, { useState, useEffect, useRef } from 'react';
import { Button, SpaceBetween, Spinner } from '@cloudscape-design/components';
import TextareaAutoHeight from './TextareaAutoHeight';
import './ChatWidget.css'; // Import the consolidated CSS file
import axiosInstance from '../../services/axiosConfig';

const ChatWidgetInput = ({ onReceiveMessage, onSendMessage, app_name, entity_type, context, conversationId, style }) => {
    const [text, setText] = useState('');
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (text.trim()) {
            onSendMessage(text); // Display the user's message in the UI
            const response = await sendMessage(text, conversationId);
            onReceiveMessage(response.response, response.conversation_id, response.chartData, response.snippets);
            setText('');
        }
    };

    const sendMessage = async (prompt, conversationId) => {
        setLoading(true);
        // Send a request to the server to generate a response
        try {
            const response = await axiosInstance.post('/chat/', {
                prompt: prompt,
                conversation_id: conversationId,
                app_name: app_name,
                entity_type: entity_type,
                context: context
            });
            return response.data;
        } catch (error) {
            console.error('Error sending message:', error);
            return { response: 'Error occurred', conversation_id: conversationId };
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.client_id) {
            setClientId(user.client_id);
        }

        if (inputRef.current) {
            inputRef.current.addEventListener('keydown', handleKeyPress);
        }
        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('keydown', handleKeyPress);
            }
        };
    }, [text]);

    return (
        <form onSubmit={handleSubmit} className="chat-input-form" style={style}>
            <SpaceBetween direction="horizontal" size="s" className="chat-input-space">
                <div className="textarea-containr">
                    <TextareaAutoHeight
                        ref={inputRef}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Type a message..."
                        className="chat-textarea"
                        style={{ width: '600px' }}
                    />
                </div>
                <Button variant="primary" iconName="send" iconAlign="right" type="submit">
                    {loading ? <Spinner variant='inverted' /> : 'Send'}
                </Button>
            </SpaceBetween>
        </form>
    );
};

export default ChatWidgetInput;

