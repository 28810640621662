import React from 'react';
import ChatWidget from '../../chat_widget/ChatWidget'; // Adjust the path as necessary
import {  Header,  Container } from '@cloudscape-design/components';

import './ChatPortfolio.css'; // Import the CSS for the page if any

const ChatPortfolio = () => {
  
  
  return (

    <Container
    header={<Header variant="h3"
    >Portfolio Manager AI Agent </Header>}
    >
      <ChatWidget app_name="PortfolioManagerPortal" entity_type="clients"
          context="Yes"
          entity_id="CL-1091"
      />
    </Container>
  );
};

export default ChatPortfolio;
