
import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container, Pagination, TextFilter } from '@cloudscape-design/components';
import { useNavigate, useLocation } from 'react-router-dom';

function ConversationTable() {
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [flashMessages, setFlashMessages] = useState([]);
    const [sortedColumn, setSortedColumn] = useState("updated_dt");
    const [sortDirection, setSortDirection] = useState('descending');
    const [filterText, setFilterText] = useState('');

    const [searchParams, setSearchParams] = useState({
        app_id: '',
        entity_id: '',
        entity_type: ''
    });
    const navigate = useNavigate();
    const location = useLocation();
    // Add pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Items per page

    // Add pagination handler
    const handlePaginationChange = ({ detail }) => {
        setCurrentPage(detail.currentPageIndex);
    };

    useEffect(() => {
        if (location.state?.flashMessage) {
            setFlashMessages([{ type: 'success', header: 'Success', content: location.state.flashMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        }
    }, [location.state]);



    const fetchConversations = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/conversations/admin`)
            .then(response => {
                setConversations(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching conversations:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage === null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No Conversations found', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchConversations();
    }, [fetchConversations]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load data: ${error.message}`;
        }
    };

    const handleAddConversation = () => {
        navigate('/admin/conversations/new');
    };

    const handleEditClick = (conversation) => {
        navigate(`/admin/conversations/${conversation.conversation_id}`);
    };

    const handleInputChange = (e, field) => {
        setSearchParams(prev => ({ ...prev, [field]: e.detail.value }));
    };

    const handleSelectChange = (e, field) => {
        setSearchParams(prev => ({ ...prev, [field]: e.detail.selectedOption.value }));
    };

    // Add sort function
    const sortItems = (items) => {
        if (!sortedColumn) return items;

        return [...items].sort((a, b) => {
            const aValue = a[sortedColumn] || '';
            const bValue = b[sortedColumn] || '';

            if (sortDirection === 'ascending') {
                return aValue.toString().localeCompare(bValue.toString());
            } else {
                return bValue.toString().localeCompare(aValue.toString());
            }
        });
    };

    // Add filter function
    const filterItems = (items) => {
        if (!filterText) return items;

        return items.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
    };

    // Add sort handler
    const handleSortChange = ({ detail }) => {
        setSortedColumn(detail.sortingColumn.sortingField);
        setSortDirection(detail.sortingDescending ? 'descending' : 'ascending');
    };


    return (
        <Container
            header={<Header variant="h2">Conversations</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <SpaceBetween direction="vertical" size="l">
                {!loading && conversations.length > 0 && (

                    <Table
                        columnDefinitions={[
                            {
                                header: 'App Name',
                                cell: item => item.app_name,
                                sortingField: 'app_name',
                                sortingComparator: (a, b) => a.app_name.localeCompare(b.app_name)
                            },
                            {
                                header: 'Conversation Name',
                                cell: item => (
                                    <div style={{
                                        maxWidth: '500px',
                                        whiteSpace: 'wrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {item.conversation_name}
                                    </div>
                                ),
                                sortingField: 'conversation_name'
                            },
                            {
                                header: 'Username',
                                cell: item => item.username,
                                sortingField: 'username'
                            },
                            {
                                header: 'Updated',
                                cell: item => item.updated_dt ? new Date(item.updated_dt).toLocaleString() : 'N/A',
                                sortingField: 'updated_dt'
                            },
                            {
                                header: 'Actions',
                                cell: item => (
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button onClick={() => handleEditClick(item)}>Detail</Button>
                                    </SpaceBetween>
                                )
                            }
                        ]}
                        items={filterItems(sortItems(conversations)).slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                        )}
                        loading={loading}
                        sortingColumn={sortedColumn}
                        sortingDescending={sortDirection === 'descending'}
                        onSortingChange={handleSortChange}
                        filter={
                            <TextFilter
                                filteringText={filterText}
                                onChange={({ detail }) => setFilterText(detail.filteringText)}
                                countText={`${conversations.length} matches`}
                                filteringPlaceholder="Search conversations..."
                            />
                        }
                        pagination={
                            <Pagination
                                currentPageIndex={currentPage}
                                pagesCount={Math.ceil(filterItems(sortItems(conversations)).length / pageSize)}
                                onChange={handlePaginationChange}
                            />
                        }
                        header={
                            <Header
                                counter={`(${conversations.length})`}>
                                Conversations
                            </Header>
                        }
                    />
                )}
                {loading && <Box>Loading conversations...</Box>}
                {!loading && conversations.length === 0 && <Box>No conversations found</Box>}
            </SpaceBetween>
        </Container>
    );
}

export default ConversationTable;


