import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../services/axiosConfig';
import { Box, Table, Header, Button, SpaceBetween, Flashbar, Container, Popover, Icon, Textarea } from '@cloudscape-design/components';
import LifeEventModal from './modals/LifeEventModal';
import EditLifeEventModal from './modals/EditLifeEventModal';

function ClientLifeEventsTable({ clientId }) {
    const [lifeEvents, setLifeEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flashMessages, setFlashMessages] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedLifeEvent, setSelectedLifeEvent] = useState(null);

    const fetchLifeEvents = useCallback(() => {
        setLoading(true);
        axiosInstance.get(`/clients/${clientId}/life_events`)
            .then(response => {
                setLifeEvents(response.data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching life events:', err);
                const errorMessage = extractErrorMessage(err);
                if (errorMessage == null) {
                    setFlashMessages([{ type: 'info', header: 'Not Found', content: 'No life events found for the client', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                } else {
                    setFlashMessages([{ type: 'error', header: 'Error', content: errorMessage, dismissible: true, onDismiss: () => setFlashMessages([]) }]);
                }
                setLoading(false);
            });
    }, [clientId]);

    useEffect(() => {
        fetchLifeEvents();
    }, [fetchLifeEvents]);

    const extractErrorMessage = (error) => {
        if (error.response && error.response.data && error.response.data.detail) {
            if (error.response.data.detail === "No Life Events") {
                return null;
            }
            if (Array.isArray(error.response.data.detail)) {
                const details = error.response.data.detail.map(detail => `${detail.loc.join(' -> ')}: ${detail.msg}`).join(', ');
                return `Failed to load life events data: ${details}`;
            }
            return `Failed to load data: ${error.response.data.detail}`;
        } else {
            return `Failed to load life events data: ${error.message}`;
        }
    };

    const handleAddLifeEvent = (lifeEvent) => {
        setLifeEvents(prevLifeEvents => [...prevLifeEvents, lifeEvent]);
        setFlashMessages([{ type: 'success', header: 'Success', content: 'Life event added successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        fetchLifeEvents();
    };

    const handleUpdateLifeEvent = (updatedLifeEvent) => {
        setLifeEvents(prevLifeEvents => prevLifeEvents.map(e => e.event_id === updatedLifeEvent.event_id ? updatedLifeEvent : e));
        setFlashMessages([{ type: 'success', header: 'Success', content: 'Life event updated successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        fetchLifeEvents();
    };

    const handleDeleteLifeEvent = (eventId) => {
        setLifeEvents(prevLifeEvents => prevLifeEvents.filter(e => e.event_id !== eventId));
        setFlashMessages([{ type: 'success', header: 'Success', content: 'Life event deleted successfully!', dismissible: true, onDismiss: () => setFlashMessages([]) }]);
        fetchLifeEvents();
    };

    const handleEditClick = (lifeEvent) => {
        setSelectedLifeEvent(lifeEvent);
        setIsEditModalOpen(true);
    };

    const truncateDescription = (description) => {
        if (description.length <= 80) return description;
        const truncated = description.slice(0, 80);
        return truncated.slice(0, truncated.lastIndexOf(' ')) + '...';
    };

    if (loading) return <Box>Loading life events...</Box>;

    return (
        <Container
            header={<Header variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsAddModalOpen(true)}>
                            Add Life Event
                        </Button>
                    </SpaceBetween>
                }
            >Client Life Events</Header>}
        >
            {flashMessages.length > 0 && <Flashbar items={flashMessages} />}
            <Table
                columnDefinitions={[
                    { 
                        header: 'Description', 
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Popover 
                                    position="top" 
                                    size="small" 
                                    triggerType="hover" 
                                    content={item.description}
                                >
                                    <Box display="inline-flex" alignItems="center">
                                        {truncateDescription(item.description)}
                                        <Icon name="status-info" />
                                    </Box>
                                </Popover>
                            </SpaceBetween>
                        )
                    },
                    { header: 'Type', cell: item => item.event_type },
                    { header: 'Date', cell: item => new Date(item.event_date).toLocaleDateString() },
                    {
                        header: 'Actions',
                        cell: item => (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button onClick={() => handleEditClick(item)}>Edit</Button>
                            </SpaceBetween>
                        )
                    }
                ]}
                items={lifeEvents}
            />
            <LifeEventModal 
                clientId={clientId} 
                visible={isAddModalOpen} 
                onClose={() => {
                    setIsAddModalOpen(false);
                    fetchLifeEvents();
                }} 
                onLifeEventAdded={handleAddLifeEvent} 
            />
            {selectedLifeEvent && (
                <EditLifeEventModal 
                    clientId={clientId} 
                    lifeEvent={selectedLifeEvent} 
                    visible={isEditModalOpen} 
                    onClose={() => {
                        setIsEditModalOpen(false);
                        fetchLifeEvents();
                    }} 
                    onLifeEventUpdated={handleUpdateLifeEvent}
                    onLifeEventDeleted={handleDeleteLifeEvent}
                />
            )}
        </Container>
    );
}

export default ClientLifeEventsTable;
