import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SideNavigation, Icon } from '@cloudscape-design/components';

function LeftMenu({ isOpen, toggleDrawer }) {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        toggleDrawer(false);
    };

    const items = [
        { type: 'link', text: 'Client Portfolio', href: '/clients'},
        { type: 'link', text: 'Live Meeting Assistant', href: '/clients/lma' }
        
    ];

    return (
        <SideNavigation
            header={{ text: "Client Portfolio", href: "/clients/overview" }}
            items={items}
            activeHref={window.location.pathname}
            onFollow={event => {
                event.preventDefault(); // Prevent link following if you handle routing programmatically
                handleNavigation(event.detail.href);
            }}
            onToggle={() => toggleDrawer(false)}
            open={isOpen}
        />
    );
}

export default LeftMenu;
