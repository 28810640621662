import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Header, Button, SpaceBetween } from '@cloudscape-design/components';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear local storage and navigate to login page after a short delay
        localStorage.removeItem('user');
        localStorage.removeItem('token');

       
    }, [navigate]);

    return (
        <Container header={<Header variant="h1">Thank You</Header>}>
            <p>You have been logged out successfully. You will be redirected to the login page shortly.</p>
            <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => navigate('/login')}>Go to Login</Button>
            </SpaceBetween>
        </Container>
    );
};

export default Logout;


