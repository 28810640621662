import React from 'react';

import { Tabs } from '@cloudscape-design/components';

import FundsTable from './FundsTable';

function FundTabs() {

   return (

       <Tabs

           tabs={[

               { label: 'Top Gainers', id: 'top_gainers', content: <FundsTable title="Top Gainers Funds" endpoint="/funds/top_gainers" /> },

               { label: 'Top Losers', id: 'top_losers', content: <FundsTable title="Top Losers Funds" endpoint="/funds/top_losers" /> },

               { label: 'Top Performing', id: 'top_performing', content: <FundsTable title="Top Performing Funds" endpoint="/funds/top_performing" /> },

               { label: 'Best Historical Performance', id: 'best_historical', content: <FundsTable title="Best Historical Performance" endpoint="/funds/best_historical_performance" /> },

               { label: 'Best Mutual Fund', id: 'best_mutual_fund', content: <FundsTable title="Best Mutual Fund" endpoint="/funds/best_mutual_fund" /> },

           ]}

       />

   );

}

export default FundTabs;
