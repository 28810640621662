import React, { useState, useEffect } from 'react';
import {
   Box, Button, Container, Header, Flashbar, SpaceBetween, Spinner, Table, TextFilter, Pagination, Alert, Form, RadioGroup,
   ExpandableSection
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useBreadcrumbs } from '../breadcrumb/BreadcrumbProvider';
import axiosInstance from '../../services/axiosConfig';
import { useNavigate } from 'react-router-dom';
import ChatWidget from '../chat_widget/ChatWidget';

function Home() {
   const [loading, setLoading] = useState(true);
   const [flashMessages, setFlashMessages] = useState([]);
   const [dayGainers, setDayGainers] = useState([]);
   const [selectedTicker, setSelectedTicker] = useState(null);
   const { setBreadcrumbs } = useBreadcrumbs();
   const navigate = useNavigate();

   useEffect(() => {
       setBreadcrumbs([
           { text: "Home", href: "/" },
       ]);

       async function fetchDayGainers() {
           try {
               const response = await axiosInstance.get('/tickers/day_gainers');
               setDayGainers(response.data);
               setLoading(false);
           } catch (error) {
               setFlashMessages([{
                   header: "Error",
                   content: "Failed to fetch day gainers.",
                   type: "error",
                   dismissible: true,
                   onDismiss: () => setFlashMessages([])
               }]);
               setLoading(false);
           }
       }

       fetchDayGainers();
   }, [setBreadcrumbs]);

   const { items, actions, collectionProps, filterProps, paginationProps } = useCollection(dayGainers, {
       filtering: {
           empty: <Box textAlign="center">No gainers found.</Box>,
           noMatch: <Box textAlign="center">No matching gainers found.</Box>
       },
       pagination: { pageSize: 10 },
       sorting: { sortableFields: ['symbol', 'name', 'price', 'change', 'changePercent', 'volume', 'avgVolume', 'marketCap', 'peRatio', 'weekRange'] }
   });

   const handleViewDetails = () => {
       if (selectedTicker) {
           navigate(`/plan/details/${selectedTicker}`);
       } else {
           setFlashMessages([{
               header: "Error",
               content: "Please select a ticker to view details.",
               type: "error",
               dismissible: true,
               onDismiss: () => setFlashMessages([])
           }]);
       }
   };

   const formatNumber = (number) => {
       return number !== null && number !== "" && !isNaN(number) ? number.toLocaleString() : 'N/A';
   };

   const formatFloat = (number) => {
    if (number === null || number === undefined || number === "") {
        return 'N/A';
    }
    const numValue = Number(number);
    return !isNaN(numValue) ? numValue.toFixed(2) : 'N/A';
    };

   if (loading) return <Box textAlign="center"><Spinner /></Box>;

   return (
       <Container header={<Header variant="h3">Day's Top Gainers</Header>}>
           <Flashbar items={flashMessages} />
           <Box margin={{ top: 'l' }}>
            <ExpandableSection headerText='Bedrock' >
               <ChatWidget app_name="AnalystPortal" entity_type="tickers"
                   context={JSON.stringify(items)}
                   entity_id={selectedTicker}
               />
               </ExpandableSection>
           </Box>
           <SpaceBetween size="l" direction="vertical">
               <TextFilter {...filterProps} />
               <Table
                   {...collectionProps}
                   columnDefinitions={[
                       { header: 'Symbol', cell: item => item.symbol, sortingField: 'symbol' },
                       { header: 'Name', cell: item => item.name, sortingField: 'name' },
                       { header: 'Price', cell: item => item.price, sortingField: 'price' },
                       { header: 'Change', cell: item => formatFloat(item.change), sortingField: 'change' },
                       { header: '% Change', cell: item => formatFloat(item.changePercent) + '%', sortingField: 'changePercent' },
                       { header: 'Volume', cell: item => formatNumber(item.volume), sortingField: 'volume' },
                       { header: 'Avg Vol (3 month)', cell: item => formatNumber(item.avgVolume), sortingField: 'avgVolume' },
                       { header: 'Market Cap', cell: item => formatNumber(item.marketCap), sortingField: 'marketCap' },
                       { header: 'PE Ratio (TTM)', cell: item => formatFloat(item.peRatio), sortingField: 'peRatio' },
                       { header: '52 Week Range', cell: item => item.weekRange !== null ? item.weekRange : 'N/A', sortingField: 'weekRange' },
                   ]}
                   items={items}
               />
               <Pagination {...paginationProps} />
           </SpaceBetween>
       

       </Container>
   );
}

export default Home;


